import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FindEntityDialogComponent } from '../../components/dialog/find-entity-dialog/find-entity-dialog.component';
import { DirectoryConfig } from '../../models/directory-config.model';

@Injectable({
  providedIn: 'root'
})
export class FindEntityService {

  constructor(private dialog: MatDialog) { }

  search(title: string, field: any, value: string[], multiple?: boolean, isSubrequest?: boolean, config?: DirectoryConfig, newEntities?: any[]): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const dialogRef = this.dialog.open(
        FindEntityDialogComponent,
        {
          width: '1100px',
          maxWidth: '90vw',
          height: '700px',
          maxHeight: '90vh',
          autoFocus: false,
          panelClass: 'find-entity-dialog',
          data: {
            title: title,
            value: value,
            multiple: multiple,
            field: field,
            isSubrequest: isSubrequest,
            config: config,
            newEntities: newEntities
          }
        }
      );
      dialogRef
        .afterClosed()
        .subscribe((val: any) => {
          if (val && val.confirmation === true) {
            resolve(val);
          } else {
            reject(false);
          }
        });
    });
  }
}
