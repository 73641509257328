import { Directive, ElementRef, Renderer2, HostListener } from '@angular/core';
import { DirectorySharedDataService } from '../../services/directory-shared-data.service';
import { DirectoryConfig } from '../../models/directory-config.model';

@Directive({
  selector: '[appCustomizedHoverColor]',
})
export class CustomizedHoverColorDirective {
  private directoryConfig: DirectoryConfig;
  private widgetConfigSubscribe = this.directoryData
    .getDirectoryConfig()
    .subscribe((config: DirectoryConfig) => {
      this.directoryConfig = config;
    });
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private directoryData: DirectorySharedDataService
  ) {}

  @HostListener('mouseenter') onMouseEnter() {
    this.overrideWithCustomColor();
  }

  @HostListener('mouseleave') onMouseLeave() {
    const overlay = this.el.nativeElement.getElementsByClassName(
      'mat-button-focus-overlay'
    )[0];
    this.renderer.setStyle(overlay, 'background', 'initial');
  }

  private overrideWithCustomColor(): void {
    let customColor: string;
    if (this.directoryConfig.style.secondColor) {
      customColor = this.directoryConfig.style.secondColor;
    } else {
      customColor = 'black';
    }
    const overlay = this.el.nativeElement.getElementsByClassName(
      'mat-button-focus-overlay'
    )[0];
    this.renderer.setStyle(overlay, 'background', customColor);
  }
}
