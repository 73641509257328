<div class="select-communities-wrapper" *ngIf="listPossibilities.length > 1">
  <form [formGroup]="directoryAddRequestService.communityForm">
    <p class="field-title button1 medium">
      {{ 'directory.requests.selectLocalisation' | translate }}
      <span class="required-indicator">*</span>
    </p>
    <mat-form-field appearance="outline" class="select-communities-form-field">
      <mat-select
        *ngIf="listPossibilities"
        formControlName="community"
        required="true"
      >
        <mat-option
          *ngFor="let possibility of listPossibilities"
          [value]="possibility.id">
          {{ possibility.title[currentLang] }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>
