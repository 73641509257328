import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { DirectorySharedDataService } from 'src/app/directory/services/directory-shared-data.service';
import { DirectorySearchService } from '../../services/directory-search/directory-search.service';

import { DirectoryConfig, DirectoryField, DirectorySheet } from '../../models/directory-config.model';
import { FieldType } from '../../models/fields/field.model';
import { DirectoryEntityContactDialogComponent } from '../dialog/directory-entity-contact-dialog/directory-entity-contact-dialog.component';
import { CONSTANTS } from 'src/environments/constants';

@Component({
  selector: 'app-directory-entity',
  templateUrl: './directory-entity.component.html',
  styleUrls: ['./directory-entity.component.scss'],
})
export class DirectoryEntityComponent implements OnChanges {
  public FieldType = FieldType;
  public filteredFieldsGroups: any[] = [];
  public currentEntityData: any = "";
  public summary: any = {};
  public currentLang: string;
  public availableFields: any[];
  public mainIsEntity: boolean;

  public sheetToUse: DirectorySheet;

  public static readonly ENTITY_KEYWORD: string = "entityForm";

  @Input() entityId: string;
  @Input() forceEntity: boolean = false;
  @Input() widgetConfig: DirectoryConfig;
  @Input() range: string;
  @Input() previewMode: boolean;
  @Input() isEditable: boolean;
  @Input() isSecondaryItem: boolean = false;

  constructor(
    private directoryData: DirectorySharedDataService,
    private directorySearch: DirectorySearchService,
    public dialog: MatDialog,
    public translateService: TranslateService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    // console.debug("Widget config:");
    // console.debug(this.widgetConfig);
    // init Main or Not main to know if we can use the default name constant
    if (this.widgetConfig) {
      this.mainIsEntity = this.widgetConfig.isFocusOnAssociatedDB && this.isNotEmpty(this.widgetConfig.concernedAssociatedDB) ? false : true;
    }

    if (this.range == undefined) {
      console.warn("WARNING: DirectoryEntityComponent's property range is undefined when it should either be null or an ADB class id");
    }

    // Select the proper sheet
    this.sheetToUse = this.findSheetToUse();

    // if(changes.entityId && changes.entityId.currentValue !== null && changes.entityId.currentValue !== changes.entityId.previousValue) {
      this.currentLang = this.translateService.currentLang;
      if ((this.range && this.range !== "") && !this.forceEntity) { // Element is expected to be an ADB element

        // Getting the ADB's details so we can know whether it's an external one
        // TODO if deemed necessary by customer

        // Getting the element's details
        if (this.range && this.entityId) {
          this.directorySearch
            .getAdbElement(this.range, this.entityId)
            .subscribe((entityData: any) => {
              this.currentEntityData = entityData;
              // console.debug("Element data");
              // console.debug(this.currentEntityData);

              // old widget this.mainIsEntity && !this.sheetToUse.classId

              if (this.mainIsEntity && !this.sheetToUse.classId) {
                this.mapFieldValue('title', {id:CONSTANTS.name, code: CONSTANTS.name, type:"Textarea"}); // No sheet aviable force using the name property as defined in the constants
                this.mapFieldValue('description', null);
                this.mapFieldValue('logo', null);
                this.mapFieldValue('banner', null);
              } else {
                this.mapFieldValue('title', this.sheetToUse.title);
                this.mapFieldValue('description', this.sheetToUse.description);
                this.mapFieldValue('logo', this.sheetToUse.logo);
                this.mapFieldValue('banner', this.sheetToUse.banner);
              }

            });
        }
      } else { // Element is expected to be an entity
        this.directorySearch
          .findEntityData(this.entityId, true)
          .subscribe((entityData: any) => {
            // console.debug("Displaying entity: " + this.entityId);
            // console.debug(entityData);
            this.currentEntityData = entityData;
            // console.debug("Entity data");
            // console.debug(this.currentEntityData);

            // old widget !this.mainIsEntity && !this.sheetToUse.classId

            if (!this.mainIsEntity && !this.sheetToUse.classId ) {
              this.mapFieldValue('title', {id:CONSTANTS.mainName, code: CONSTANTS.mainName, type:"Textarea"}); // No sheet aviable force using the name property as defined in the constants
              this.mapFieldValue('description', null);
              this.mapFieldValue('logo', null);
              this.mapFieldValue('banner', null);
            } else {
              this.mapFieldValue('title', this.sheetToUse.title);
              this.mapFieldValue('description', this.sheetToUse.description);
              this.mapFieldValue('logo', this.sheetToUse.logo);
              this.mapFieldValue('banner', this.sheetToUse.banner);
            }

          });
      }

      this.filterFieldsThenGroups(this.sheetToUse.fieldsgroup, this.range);


      // console.debug("Mappings: ");
      // console.log("DirectoryEntityComponent",this.sheetToUse);
    // }
  }

  private findSheetToUse(): DirectorySheet {
   // console.debug("Finding which sheet to use",this.isSecondaryItem, this.range, this.forceEntity);
    if (this.isSecondaryItem && this.widgetConfig.layout.onlySpecificFields) { // Secondary items may have a specific sheet defined
      let sheetKeyToSearch: string;
      if ((this.range && this.range !== "") && !this.forceEntity) { // item is an adb element, key is the adb id
        sheetKeyToSearch = this.range;
      } else { // item is an entity, this uses a specific key
        sheetKeyToSearch = DirectoryEntityComponent.ENTITY_KEYWORD;
      }

      // console.debug("Looking for secondary sheet with key: " + sheetKeyToSearch);
      const existingSheet = this.findSecondarySheetInConfig(this.widgetConfig, sheetKeyToSearch);
      if (existingSheet !== false) {
        // console.debug("Found secondary sheet:");
        // console.debug(existingSheet);
        return existingSheet;
      }
    }

    return this.widgetConfig.sheet;
  }

  private findSecondarySheetInConfig(config: DirectoryConfig, adbId: string): DirectorySheet|false {
    if (config.secondarySheets) {
      const toReturn = config.secondarySheets.find(adb => {return adb.classId == adbId;});
      if (toReturn != undefined) return toReturn;
    }
    return false;
  }

  public isNotEmpty (concernedAssociatedDB: string) {
    return concernedAssociatedDB != undefined && concernedAssociatedDB != null && concernedAssociatedDB != "";
  }

  private mapFieldValue(property: string, field: DirectoryField) {
    let adb = null;
    if (this.range && !this.forceEntity) adb = this.range;

    if (field && field.id) {
      this.summary[property] = {
        field: field,
        value: field.id in this.currentEntityData ? this.currentEntityData[field.id] : null
      };
    } else {
      this.summary[property] = null;
    }
  }

  public filterFieldsThenGroups(fieldsGroups, range: string) {
    //this.showFields = false;
    this.filteredFieldsGroups = [];
    if (range == '') range = null;
    // console.debug("Fields BEFORE filtering");
    // console.debug(fieldsGroups);
    // console.debug("filterFieldsThenGroups",range,fieldsGroups);

    fieldsGroups.forEach((group) => {
      let newGroup = {order: group.order, title: group.title, fields: []};

      group.fields.forEach((field) => {
        let fieldDomain = field.domain;
        if (fieldDomain == undefined) { // Entity fields don't even have a "domain" property, having us compare undefined -v- null. This aims at fixing it in a rather unelegant way.
          fieldDomain = null;
        }

        if ((fieldDomain === range && field.id != 'core_hasListName') //Entities and ADB without 'core_hasListName'
            || (range != null && field.id == 'core_hasListName')) { //Specific to ADB with 'core_hasListName'

          if (this.filteredFieldsGroups.indexOf(newGroup) == -1) this.filteredFieldsGroups.push(newGroup);
          newGroup.fields.push(field);
          // console.debug("Pushing field");
          // console.debug(field);
        }
      });
    });
    // console.debug("Fields AFTER filtering");
    // console.debug(this.filteredFieldsGroups);
    return this.filteredFieldsGroups;
  }

  canContact() {
    if (this.widgetConfig && this.widgetConfig.requests.hasContactRequests
        && this.widgetConfig.requests.contact.code && this.currentEntityData
          && this.currentEntityData[this.widgetConfig.requests.contact.code]) {
      return true;
    }
    return false;
  }

  public openContactDialog(contact?: DirectoryField) {
    let contactCode = this.widgetConfig.requests.contact.code;
    let contactEmail;
    if (this.currentEntityData[contactCode]) {
      contactEmail = this.currentEntityData[contactCode];
    } else {
      contactEmail = this.widgetConfig.responsibleEmail;
    }
    if (contactEmail) {
      const dialogRef = this.dialog.open(DirectoryEntityContactDialogComponent, {
        width: '600px',
        maxWidth: '100vw',
        maxHeight: '90vh',
        data: {
          entity_id: this.entityId,
          entity_name: this.currentEntityData[this.sheetToUse.title.id],
          widget_name: this.widgetConfig.name,
          referer: document.referrer,
          contact_email: contactEmail
        },
        autoFocus: false,
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe((success: string) => {});
    }
  }

  public translationCompatibilityForOlderWidget(objectOrString, lang): {} {
    if (typeof objectOrString !== 'object') {
      let tempValue = objectOrString
      objectOrString = {};
      objectOrString[lang] = tempValue;
    }
    return objectOrString[lang];
  }

  // can't use simple quote than encodeURI for backgroundImage url
  public urlEnc(url: string) {
    return encodeURI(url);
  }

  // public getCurrentValue(field) {
  //   if (this.currentEntityData[field.id] instanceof Object) {
  //       return this.currentEntityData[field.id].element_id;
  //   } else {
  //     return this.currentEntityData[field.id];
  //   }
  // }

}
