import { FieldType } from '../../models/fields/field.model';
import { DirectoryAppliedFilter } from './../../models/directory-applied-filter.model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DirectorySearchService } from '../directory-search/directory-search.service';

@Injectable({
  providedIn: 'root',
})
export class DirectoryFiltersStoreService {
  private appliedFilters = new BehaviorSubject<DirectoryAppliedFilter[]>([]);
  private availableSpots = new BehaviorSubject<number>(0);

  constructor(
    private searchService: DirectorySearchService
  ) {}

  public getAppliedFilters(): Observable<DirectoryAppliedFilter[]> {
    return this.appliedFilters.asObservable();
  }
  public changeAppliedFilters(newFilters): void {
    this.appliedFilters.next(newFilters);
    this.searchService.findResults(this.filtersAsCriteria(newFilters), this.findActivatedLegends(newFilters));
  }
  public getAvailableMainSpots(): Observable<number> {
    return this.availableSpots.asObservable();
  }
  public changeAvailableMainSpots(x: number): void {
    this.availableSpots.next(x);
  }

  public updateFilter(filter: DirectoryAppliedFilter) {
    const appliedFilters = this.appliedFilters.getValue();
    const updatedFilters = appliedFilters.filter(
      (appliedFilter) =>
        appliedFilter.concernedField !== filter.concernedField
    );
    updatedFilters.push(filter);
    this.changeAppliedFilters(updatedFilters);
  }
  public removeFilter(filter: DirectoryAppliedFilter) {
    const appliedFilters = this.appliedFilters.getValue();
    const updatedFilters = appliedFilters.filter(
      (appliedFilter) => appliedFilter.concernedField !== filter.concernedField
    );
    this.changeAppliedFilters(updatedFilters);
  }

  public getListIdFromFieldId(fields: any, fieldId: string): string {
    if (fields && fieldId) {
      if (fields.find((x) => x.field_id === fieldId)) {
        return fields.find((x) => x.field_id === fieldId).associated_data_id;
      }
    }
  }

  public getListDomainIdFromFieldId(fields: any, fieldId: string): string {
    if (fields && fieldId) {
      if (fields.find((x) => x.field_id === fieldId)) {
        return fields.find((x) => x.field_id === fieldId)?.associated_data_domain_id;
      }
    }
  }

  public filtersAsCriteria(filters: DirectoryAppliedFilter[]): string {
    let criteriasArr: string[] = [];

    // build criteria per filter
    filters.forEach(filter => {
      if (filter.concernedField !== "legendList") {
        let criteria = "(";
        let filterCode = filter.concernedField;
        let filterOperator = null;
        
        if (filter.fieldCode) filterCode = filter.fieldCode;
        if (filter.operator) filterOperator = filter.operator;
        
        filter.value.forEach((value, index) => {
          if (index > 0) criteria += " OR ";
          criteria+= `${filterCode} ${filterOperator} '${value}'`
        });
        criteria += ")";
        
        // If the filter has a viaField, we must surround the subcriteria with the parent's request
        if (filter.viaFieldId) {
          criteria = "("+ filter.viaFieldId +" contains "+ criteria +")"
        }
        // expected: (x contains 'abc' OR x contains 'def')
        criteriasArr.push(criteria);
      }
    });

    // build total criterias with all filters
    let criterias = "";
    criteriasArr.forEach((criteriaPerFilter,index) => {
      if (index > 0) criterias += " AND ";
      criterias += criteriaPerFilter;
    });
    // expected: (x contains 'abc' OR x contains 'def') AND (y contains 'xxx' OR y contains 'yyy')
    return criterias;
  }

  findActivatedLegends(filters: DirectoryAppliedFilter[]): any[] {
    let appliedLegendFilters = filters.find(x => x.concernedField === "legendList");
    if(!appliedLegendFilters) return null;
    return appliedLegendFilters.value;
  }
}
