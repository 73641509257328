<div class="widget-wrapper directory-wrapper" [class.widget-thumbnails]="widgetConfig?.layout.hasThumbnail">
  <header class="header-area" *ngIf="widgetConfig?.layout.hasHeader || widgetConfig?.layout.hasFilters">
    <app-directory-header *ngIf="widgetConfig?.layout.hasHeader" class="directory-header "></app-directory-header>
    <app-directory-filters
      *ngIf="widgetConfig?.layout.hasFilters"
      class="directory-filters px-3"
      [class.single-header]="!widgetConfig?.layout.hasHeader"
      [criteriaFilters]="criteriaFilters"></app-directory-filters>
  </header>

  <main class="core-area" *ngIf="widgetConfig">
    <mat-drawer-container hasBackdrop="false">
      <mat-drawer-content>
        <ng-container *ngIf="isLayoutMixed()">
          <mat-drawer-container hasBackdrop="false" autosize>
            <mat-drawer-content >
              <!--Affichage dans le contexte carte et liste-->
              <app-directory-map
               [class.thumbnails]="widgetConfig.layout.hasThumbnail"
               (click)="openEntityInDialog()"></app-directory-map>
            </mat-drawer-content>
            <mat-drawer #sidenavList [position]="isListOnTheRight()" mode="side" [opened]="hasListDisplayed" autoFocus="false">
              <app-directory-list
                class="list-and-map"
                [class.thumbnails]="widgetConfig.layout.hasThumbnail"
                (emitForUpdateMap)="receiveUpdateMapSignal($event)"
                (click)="openEntityInDialog()"></app-directory-list>
            </mat-drawer>
          </mat-drawer-container>
        </ng-container>

        <ng-container *ngIf="isLayoutListOnly()">
          <app-directory-list
            class="list-only util-bg-background"
            [class.thumbnails]="widgetConfig.layout.hasThumbnail"
            (emitForUpdateMap)="receiveUpdateMapSignal($event)"
            (click)="openEntityInDialog()"></app-directory-list>
        </ng-container>

        <ng-container *ngIf="isLayoutMapOnly()">
          <mat-drawer-container hasBackdrop="false" autosize>
            <mat-drawer-content>
              <!--Affichage dans le contexte carte-->
              <app-directory-map
              [class.thumbnails]="widgetConfig.layout.hasThumbnail"
              (click)="openEntityInDialog()"></app-directory-map>
            </mat-drawer-content>
            <mat-drawer #sidenavList [position]="isLegendOnTheRight()" mode="side" [opened]="hasLegendDisplayed" autoFocus="false">
              <app-directory-legend (emitForUpdateMap)="receiveUpdateMapSignal($event)" >
              </app-directory-legend>
            </mat-drawer>
          </mat-drawer-container>
        </ng-container>
      </mat-drawer-content>

      <mat-drawer #sidenavSheet
          *ngIf="!widgetConfig.layout.isFullSize"
          [position]="isSheetOnTheRight()"
          mode="over"
          [opened]="isEntitySheetOpened || isLinkedElementSheetOpened"
          autoFocus="false">
        <app-directory-entity-sheet *ngIf="currentOpenedEntityId"></app-directory-entity-sheet>
        <app-directory-secondary-sheet *ngIf="openedLinkedElementId" (closeSecondSheet)="stateOfSecondarySheet($event)"></app-directory-secondary-sheet>
      </mat-drawer>
    </mat-drawer-container>
  </main>
</div>
