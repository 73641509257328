<div class="host-header">
  <p>Site Hôte</p>
  <div class="flex-spacer"></div>
  <div class="host-header-icon">
    <mat-icon>people</mat-icon>
    <mat-icon>settings</mat-icon>
    <mat-icon>help</mat-icon>
  </div>
</div>
<h3>Retrouvez l'ensemble de nos entités dans notre annuaire :</h3>

<!-- <iframe src="http://widget.craft.local:3333/fr/annuaire/2f0ae220-e74c-43d3-9555-a7ee31695120" frameborder="0" width="100%" height="600" allowfullscreen title="Widget Projets SMILE (créé par duplication)"></iframe> -->
<!-- <iframe src="http://widget.craft.local:3333/fr/annuaire/2f0ae220-e74c-43d3-9555-a7ee31695120/demande-ajout" frameborder="0" width="100%" height="600" allowfullscreen title="Widget Projets SMILE (créé par duplication)"></iframe> -->
<!-- <iframe src="http://widget.craft.local:3333/fr/annuaire/34b5fdd7-1d43-4998-99d0-ac6d5b831aa6/demande-ajout" frameborder="0" width="100%" height="1200" allowfullscreen title="Widget Projets SMILE (créé par duplication)"></iframe> -->
<!-- <iframe src="http://widget.craft.local:3333/fr/annuaire/9761efb8-17f7-495a-83e6-150f6dc96fad/demande-ajout" frameborder="0" width="100%" height="600" allowfullscreen title="Widget Projets SMILE (créé par duplication)"></iframe>
<iframe src="http://widget.craft.local:3333/en/annuaire/9761efb8-17f7-495a-83e6-150f6dc96fad/entity/1f6b5594-711d-42e1-8da8-b8f80bc25176" frameborder="0" width="100%" height="600" allowfullscreen title="Widget Projets SMILE (créé par duplication)"></iframe> -->
<!-- <iframe src="http://widget.craft.local:3333/fr/annuaire/56bf0c61-fa2e-46b6-a8a9-5c00d1de00dc/demande-ajout" frameborder="0" width="100%" height="600" allowfullscreen title="Widget ET2SMEs (v2)"></iframe> -->
<!-- <iframe src="http://widget.craft.local:3333/en/annuaire/b001f7ad-260f-4142-ba7a-db6e93e2fca6" frameborder="0" width="100%" height="1200" allowfullscreen title="Widget Projets SMILE (créé par duplication)"></iframe> -->
<!-- <iframe src="http://widget.craft.local:3333/en/annuaire/601711a9-8c50-45cb-a2d5-8b15a42667e7" frameborder="0" width="100%" height="1200" allowfullscreen title="Widget Projets SMILE (créé par duplication)"></iframe> -->


<!-- <iframe src="http://widget.craft.local:3333/fr/annuaire/1b58f646-1eda-4cee-9909-d21fd631fced" frameborder="0" width="100%" height="600" allowfullscreen title="Widget ET2SMEs (v2)"></iframe> -->
<iframe src="http://widget.craft.local:3333/fr/annuaire/9dd8295c-35a3-4c27-bee7-177f6ce159d6" frameborder="0" width="100%" height="600" allowfullscreen title="Widget ET2SMEs (v2)"></iframe>

<!-- <iframe src="http://widget.craft.local:3333/fr/annuaire/814b7a85-24c7-42f4-9d73-83b889ec3832" frameborder="0" width="100%" height="600" allowfullscreen title="Widget ET2SMEs (v2)"></iframe> -->

<!-- <iframe src="http://widget.craft.local:3333/fr/annuaire/814b7a85-24c7-42f4-9d73-83b889ec3832" frameborder="0" width="100%" height="600" allowfullscreen title="Widget ET2SMEs (v2)"></iframe> -->

<!-- <iframe src="http://widget.craft.local:3333/fr/annuaire/5f84cd91-d2bb-4da4-9a30-cf8443717259" frameborder="0" width="100%" height="600" allowfullscreen title="Widget ET2SMEs (v2)"></iframe> -->

<!-- <iframe src="http://widget.craft.local:3333/fr/annuaire/ef20541c-cda7-45d2-8179-6cd61bdceb99" frameborder="0" width="100%" height="600" allowfullscreen title="Widget ET2SMEs (v2)"></iframe> -->
<!-- <iframe src="http://widget.craft.local:3333/fr/annuaire/e4562514-d7c9-45c9-9e10-12f0c6326d38" frameborder="0" width="100%" height="600" allowfullscreen title="Widget ET2SMEs (v2)"></iframe> -->
<!-- <iframe src="http://widget.craft.local:3333/fr/annuaire/e4562514-d7c9-45c9-9e10-12f0c6326d38" frameborder="0" width="100%" height="600" allowfullscreen title="Widget ET2SMEs (v2)"></iframe> -->
<!-- <iframe src="http://widget.craft.local:3333/fr/annuaire/e2a65565-fefa-43e1-98e9-1794c62f1d3c" frameborder="0" width="100%" height="600" allowfullscreen title="Widget ET2SMEs (v2)"></iframe> -->
<!-- <iframe src="http://widget.craft.local:3333/fr/annuaire/fd6c83a7-fac8-4fa7-bbc9-c95918afdb62" frameborder="0" width="100%" height="600" allowfullscreen title="Widget ET2SMEs (v2)"></iframe>
<iframe src="http://widget.craft.local:3333/fr/annuaire/b9def5a8-223a-4f43-a8e5-370dea5da2c2" frameborder="0" width="100%" height="600" allowfullscreen title="Widget ET2SMEs (v2)"></iframe> -->



<!-- <mat-divider class="my-5"></mat-divider>
<div class="container">
  <iframe src="http://widget.craft.local:3333/annuaire/2" frameborder="0" height="700" width="100%"
    allowfullscreen title="iframe de développement"></iframe>
<mat-divider class="my-5"></mat-divider>
<div class="container">
<iframe src="http://widget.craft.local:3333/annuaire/3" frameborder="0" height="700" width="100%"
  allowfullscreen title="iframe de développement"></iframe>
</div> -->
