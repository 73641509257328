import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { DirectoryAggregations } from '../../models/directory-aggregation.model';
import { DirectorySharedDataService } from '../directory-shared-data.service';

@Injectable({
  providedIn: 'root',
})
export class DirectoryAggregationsStoreService {
  private appliedAggregations = new BehaviorSubject<DirectoryAggregations>({});

  constructor(
    private directoryShared: DirectorySharedDataService
  ) {}

  public getAppliedAggregations(): Observable<DirectoryAggregations> {
    return this.appliedAggregations.asObservable();
  }
  public changeAppliedAggregations(newAggregations: DirectoryAggregations): void {
    this.appliedAggregations.next(newAggregations);
  }

  public findFieldsForAggregations(aggregations: DirectoryAggregations): Observable<any[]> {

    return this.directoryShared.getAvailableFields().pipe(
      filter(x => {
        if(x) return true
        else return false
      }),
      map((fields: any[]) => {
        let matchingFields = []

        fields.forEach(field => {
          if(Object.keys(aggregations).includes(field.field_id)) {

            // Find aggregations available values
            aggregations[field.field_id].buckets

            matchingFields.push(
              {
                id: field.field_id,
                aggregations: aggregations[field.field_id].buckets
              }
            )
          }
        })
        return matchingFields
      }),
    );
  }

}
