import { DirectoryFiltersStoreService } from './../../services/directory-filters-store/directory-filters-store.service';
import { AfterViewChecked, AfterViewInit, ElementRef, HostListener } from '@angular/core';
import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[appHandleMainFilters]'
})
export class HandleMainFiltersDirective implements AfterViewInit {

  @Input('appHandleMainFilters') nbMainFilters: number;

  constructor(
    private el: ElementRef,
    private filtersStore: DirectoryFiltersStoreService
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setAvailableSpots();
      setTimeout(() => {
        this.setAvailableSpots();
      }, 50);
    }, 0);
  }

  setAvailableSpots() {
    if (window.innerWidth <= 600) {
      this.filtersStore.changeAvailableMainSpots(0);
    }
    else {
      let dividedWidth = (this.el.nativeElement.offsetWidth) / (204+16);
      dividedWidth = Math.trunc(dividedWidth);      
      this.filtersStore.changeAvailableMainSpots(dividedWidth);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setAvailableSpots();
  }

}
