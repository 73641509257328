export enum FieldType {
  Textarea = "Textarea",
  NonLocText = "NonLocalizedText",
  TripleState = "TripleState",
  Email = "Email",
  Numeric = "Numeric",
  Boolean = "Boolean",
  Float = "Float",
  URL = "Url",
  Image = "Image",
  Date = "Date",
  City = "City",
  RevertedFromElement = "RevertedFromElement",
  RevertedFromEntity = "RevertedFromEntity",
  UniqueChoiceList = "UniqueChoiceList",
  LinkedChoice = "LinkedChoice",
  MultipleChoiceList = "MultipleChoiceList",
  MultipleChoiceListWithComment = "MultipleChoiceListWithComment",
  OrderedMultipleChoiceList = "OrderedMultipleChoiceList",
  UniqueEntityLink = "UniqueEntityLink",
  MultipleEntityLink = "MultipleEntityLink",
  ExternalSource = 'ExternalSource',
  ExternalSourceAssociatedDb = 'ExternalSourceAssociatedDb'
}

export const FieldTypes = [
  FieldType.Textarea,
  FieldType.NonLocText,
  FieldType.TripleState,
  FieldType.Email,
  FieldType.Numeric,
  FieldType.Boolean,
  FieldType.Float,
  FieldType.URL,
  FieldType.Image,
  FieldType.Date,
  FieldType.City,
  FieldType.RevertedFromElement,
  FieldType.RevertedFromEntity,
  FieldType.UniqueChoiceList,
  FieldType.LinkedChoice,
  FieldType.MultipleChoiceList,
  FieldType.MultipleChoiceListWithComment,
  FieldType.OrderedMultipleChoiceList,
  FieldType.UniqueEntityLink,
  FieldType.MultipleEntityLink,
  FieldType.ExternalSource,
  FieldType.ExternalSourceAssociatedDb
];

export const ExternalFieldTypes = [
  FieldType.ExternalSource,
  FieldType.ExternalSourceAssociatedDb
];

export function isList(requestedType: string): boolean {
  let acceptedTypes: string[] = [
    FieldType.UniqueChoiceList,
    FieldType.LinkedChoice,
    FieldType.MultipleChoiceList,
    FieldType.MultipleChoiceListWithComment,
    FieldType.OrderedMultipleChoiceList,
    FieldType.RevertedFromElement,
  ];
  return acceptedTypes.includes(requestedType);
}

export enum FieldClass {
  Field = "Field",
  GenericField = "GenericField",
  SystemField = "SystemField",
  EntityNameField = "EntityNameField",
  EntityLogoField = "EntityLogoField",
  EntityTypeField = "EntityTypeField",
  EntitySIRETField = "EntitySIRETField",
  EntityAddress1Field = "EntityAddress1Field",
  EntityAddress2Field = "EntityAddress2Field",
  EntityLocationField = "EntityLocationField",
  EntityStatusField = "EntityStatusField",
  KeyFigureYearField = "KeyFigureYearField",
  EventDateField = "EventDateField",
  EntityLongitudeField = "EntityLongitudeField",
  EntityLatitudeField = "EntityLatitudeField",
}

export interface IField {
  id?: string;
  name?: string;
  code?: string;
  range?: string;
  tooltip?: string;
  type?: FieldType;
  confidentialityLevel?: number;
  information?: string;
  required?: boolean;
  readonly?: boolean;
  indexed?: boolean;
  fieldClass?: FieldClass;
  visibleOfFuture?: boolean;
  order?: number;
  list?: string;
}

export abstract class Field implements IField {

  constructor(
      public id?: string,
      public name?: string,
      public code?: string,
      public range?: string,
      public tooltip?: string,
      public type?: FieldType,
      public confidentialityLevel?: number,
      public information?: string,
      public required?: boolean,
      public readonly?: boolean,
      public indexed?: boolean,
      public fieldClass?: FieldClass,
      public visibleOfFuture?: boolean,
      public order?: number,
      public list?: string
  ) {}

}
