import { Component, OnInit } from '@angular/core';
import { DirectoryConfig } from '../../models/directory-config.model';
import { DirectorySharedDataService } from '../../services/directory-shared-data.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-directory-data-source',
  templateUrl: './directory-data-source.component.html',
  styleUrls: ['./directory-data-source.component.scss'],
})
export class DirectoryDataSourceComponent implements OnInit {
  public widgetConfig: DirectoryConfig;
  public currentLang: string;

  constructor(
    private directoryData: DirectorySharedDataService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang;
    this.directoryData.getDirectoryConfig().subscribe(
      (config: DirectoryConfig) => (this.widgetConfig = config)
    );
  }
}
