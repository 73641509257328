<div class="directory-filters-all-button">
  <ng-container *ngIf="!isSmallScreen;else mobileAllFilters">
    <button mat-stroked-button 
        class="mat-rounded-button directory-dropdown-button directory-filters-all-button-desktop"
        appCustomizedFontColor
        appCustomizedHoverColor 
        (click)="openAllFiltersDropdown()">
      <span>{{ 'directory.filters.more_filters' | translate }}
        <ng-container *ngIf="getNbAppliedFiltersInDropdown() > 0">
          ({{ getNbAppliedFiltersInDropdown() }})
        </ng-container>
      </span>
      <mat-icon>add_circle</mat-icon>
    </button>
  </ng-container>
  <ng-template #mobileAllFilters>
    <button mat-icon-button 
        color="accent" 
        class="directory-dropdown-button" 
        (click)="openAllFiltersDropdown()">
      <mat-icon>filter_list</mat-icon>
    </button>
  </ng-template>
</div>

<div class="directory-all-filters-dropdown-wrapper" [class.isOpen]="isOpen">
  <div class="directory-all-filters-dropdown-core">
    <div class="dropdown-wrapper">
      <div class="directory-all-filters-wrapper">
        <div class="directory-all-filters-header">
          <p class="directory-all-filters-title util-border-bottom-1 util-el-background">
            {{ 'directory.filters.more_filters' | translate }}
          </p>
        </div>
        <div class="directory-all-filters-core">
          <ng-container *ngFor="let filter of filtersList | orderBy: '-isMainFilter' | tail: nbFiltersAlreadyDisplayed">
            <app-directory-filter [isSmall]="isSmall" [filter]="filter" [criteria]="criterias[filter.id]"></app-directory-filter>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="directory-all-filters-dropdown-footer util-el-background util-border-top-1">
    <div class="flex-spacer"></div>
    <button mat-raised-button 
        color="primary" 
        (click)="closeAllFiltersDropdown()">
      {{ 'directory.filters.close' | translate }}
    </button>
  </div>
</div>