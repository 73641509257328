<div class="widget-wrapper directory-entity-wrapper">
  <header class="modification-header util-el-surface mb-4" *ngIf="directoryModificationsRequest.isEditionModeActive">
    <div class="modification-header-buttons">
      <div class="button-container">
        <button mat-button
            color="primary"
            class="mr-1"
            (click)="askGoBack()">
          {{ 'directory.requests.header.leave' | translate }}
        </button>
      </div>
      <div class="text-header pl-3 util-border-left-1 util-border-background">
        {{ 'directory.requests.header.title' | translate }}
      </div>
    </div>
    <div class="flex-spacer"></div>
    <div class="button-container">
      <button mat-button color="primary"
          [disabled]="modificationsRequests.length === 0"
          (click)="openRequestValidationDialog()">
        {{ 'directory.requests.header.your_cart' | translate }} ({{modificationsRequests.length}})
      </button>
    </div>
    <div class="button-container">
      <button mat-raised-button
          color="primary"
          class="send-button"
          [disabled]="modificationsRequests.length === 0"
          (click)="openRequestValidationDialog()">
        {{ 'directory.requests.valid' | translate }}
      </button>
    </div>
  </header>
  <div class="container" *ngIf="!directoryModificationsRequest.isEditionModeActive && widgetConfig?.requests?.hasModificationsRequests">
    <button mat-icon-button
        [routerLink]="'modifications'">
      <mat-icon>edit</mat-icon>
    </button>
  </div>
</div>

<main class="container">
  <app-directory-entity
    *ngIf="entityId && widgetConfig"
    [range]="openedElementRange"
    [entityId]="entityId"
    [isSecondaryItem]="isSecondaryItem"
    [widgetConfig]="widgetConfig"></app-directory-entity>
</main>
