import { DirectoryConfig } from 'src/app/directory/models/directory-config.model';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidatePhone, ValidateEmail } from 'src/app/shared/shared.module';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';

import { DirectoryAddRequestService } from 'src/app/directory/services/directory-add-request/directory-add-request.service';
import { DirectorySharedDataService } from 'src/app/directory/services/directory-shared-data.service';
import { DirectoryEntityContactDialogComponent } from '../directory-entity-contact-dialog/directory-entity-contact-dialog.component';
import { DirectoryEmbedRequestService } from 'src/app/directory/services/directory-embed-request/directory-embed-request.service';

@Component({
  templateUrl: './directory-add-request-validation-dialog.component.html',
  styleUrls: ['./directory-add-request-validation-dialog.component.scss']
})
export class DirectoryAddRequestValidationDialogComponent implements OnInit {
  public dialogForm: FormGroup;
  public widgetConfig: DirectoryConfig;

  constructor(
    private location: Location,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<DirectoryEntityContactDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private directoryStore: DirectorySharedDataService,
    private snackbar: MatSnackBar,
    private translate: TranslateService,
    private directoryAddRequestService: DirectoryAddRequestService,
    private directoryEmbedRequestService: DirectoryEmbedRequestService
  ) {}

  ngOnInit(): void {
    this.directoryStore.getDirectoryConfig().subscribe(
      (config) => (this.widgetConfig = config)
    );
    this.dialogForm = this.fb.group({
      name: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, ValidateEmail]),
      phone: new FormControl('', ValidatePhone),
      message: new FormControl(''),
    });
  }

  public cancelDialog(): void {
    this.dialogForm.reset();
    this.dialogRef.close();
  }

  public returnRequester() {
    let requester = {
      name: this.dialogForm.value.name,
      email: this.dialogForm.value.email,
      phone: this.dialogForm.value.phone
    };
    this.directoryAddRequestService.sendAddRequest(
      requester,
      this.data.community,
      this.data.interest_areas,
      this.dialogForm.value.message
    ).subscribe(
      (val)=> {
        this.snackbar.open(this.translate.instant("directory.creation.sent"), "OK");
        this.directoryEmbedRequestService.newObjectsRequest = {};
        this.directoryAddRequestService.initFields(this.widgetConfig, this.data.community);

        this.dialogForm.reset();
        this.directoryAddRequestService.addRequestForm.reset();

        this.dialogRef.close();
        this.location.back();
      },
      (error)=> {
        if (error.status == 409) {
          this.snackbar.open(this.translate.instant("directory.creation.sent_ec"), "KO");
        } else {
          this.snackbar.open(this.translate.instant("directory.creation.sent_e"), "KO");
        }
        this.dialogRef.close();
      });

  }

}
