import { OpenedElement, FocusedElement} from './../../models/directory-opened-element.model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DirectoryResultsStoreService {

  // Focus: element hovered on the map or list
  private focusedElement = new BehaviorSubject<FocusedElement>(null);
  private focusedElementData = new BehaviorSubject(null);

  // Opened: element clicked on the map or list
  private openedElement = new BehaviorSubject<OpenedElement>(null);
  private openedElementData = new BehaviorSubject(null);

  private parentOpenedElement: OpenedElement;

  constructor() { }

  // Sync Methods
  public getOpenedElementSync() {
    return this.openedElement.getValue()
  }

  // Getters
  public getFocusedElement(): Observable<FocusedElement> {
    return this.focusedElement.asObservable();
  }
  public getFocusedElementData(): Observable<any> {
    return this.focusedElementData.asObservable();
  }

  public getOpenedElement(): Observable<OpenedElement> {
    return this.openedElement.asObservable();
  }
  public getOpenedElementData(): Observable<any> {
    return this.openedElementData.asObservable();
  }

  public getParentOpenedElement(): OpenedElement {
    return this.parentOpenedElement;
  }

  // Setters
  public setFocusedElement(focusedElement: FocusedElement) {
    // console.log("setFocusedElement",focusedElement)
    if (focusedElement || (!focusedElement && !this.openedElement.value)) {
      this.focusedElement.next(focusedElement);
    }
  }
  public setFocusedElementData(focusedElementData: any) {
    // console.log("setFocusedElementData",focusedElementData)
    if(focusedElementData) this.focusedElementData.next(focusedElementData);
  }

  public setOpenedElement(openedElement: OpenedElement) {
    this.openedElement.next(openedElement);
  }
  public setOpenedElementData(openedElementData: any) {
    if(openedElementData) this.openedElementData.next(openedElementData);
  }

  public setParentOpenedElement(parentOpenedElement: OpenedElement) {
    this.parentOpenedElement = parentOpenedElement;
  }
}
