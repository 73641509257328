import { DirectoryConfig, DirectoryFieldForSheet } from 'src/app/directory/models/directory-config.model';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DirectorySharedDataService } from 'src/app/directory/services/directory-shared-data.service';

@Component({
  selector: 'app-entity-adb-element-details',
  templateUrl: './entity-adb-element-details.component.html',
  styleUrls: ['./entity-adb-element-details.component.scss']
})
export class EntityAdbElementDetailsComponent implements OnInit {

  public widgetConfig: DirectoryConfig;
  public fieldValues;
  public fieldsId = [];

  public currentAdbFields = [];


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private directoryData: DirectorySharedDataService,
    public dialogRef: MatDialogRef<EntityAdbElementDetailsComponent>,
  ) { }

  ngOnInit(): void {
    this.fieldValues = this.data.fieldValues;
    this.directoryData.getDirectoryConfig().subscribe(
      (config) => {
        this.widgetConfig = config;
        this.fieldsId = Object.keys(this.fieldValues);
        let allConfiguredFields = this.setAllconfiguredFields(this.widgetConfig);
        this.currentAdbFields = [];
        this.fieldsId.forEach(id => {
          this.currentAdbFields.push(
            allConfiguredFields.find(x => x.id === id)
          );
        });
      }
    );
  }

  setAllconfiguredFields(directoryConfig: DirectoryConfig): DirectoryFieldForSheet[] {
    let allConfiguredFields = []
    let fieldsGroups = directoryConfig.sheet.fieldsgroup;
    fieldsGroups.forEach(fg => {
      allConfiguredFields = [...allConfiguredFields, ...fg.fields];
    });
    return allConfiguredFields;
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
