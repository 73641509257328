
<ng-container *ngIf="hasMultipleLinks">
  <ul *ngIf="visibleEntities">
    <li *ngFor="let entityLink of visibleEntities">
      <app-entity-link [id]="entityLink.id" [label]="entityLink.label" [trueLink]="trueLink"></app-entity-link>
    </li>
  </ul>
</ng-container>

<ng-container *ngIf="!hasMultipleLinks">
  <ng-container *ngFor="let entityLink of visibleEntities">
    <app-entity-link [id]="entityLink.id" [label]="entityLink.label" [trueLink]="trueLink"></app-entity-link>
  </ng-container>
</ng-container>