import { Component, Input, OnChanges } from "@angular/core";
import { FormGroup } from "@angular/forms";

import { DirectoryAddRequestService } from "src/app/directory/services/directory-add-request/directory-add-request.service";
import { DirectorySharedDataService } from "src/app/directory/services/directory-shared-data.service";

import { FieldType } from "src/app/directory/models/fields/field.model";
import { DirectoryConfig } from "src/app/directory/models/directory-config.model";
import { DirectoryEntityComponent } from "../../directory-entity/directory-entity.component";
import { concatMap } from "rxjs/operators";
import { forkJoin, of } from "rxjs";

@Component({
    selector: 'app-directory-fields-request',
    templateUrl: './directory-fields-request.component.html',
    styleUrls: ['./directory-fields-request.component.scss']
})
export class DirectoryFieldsRequestComponent implements OnChanges {
    public FieldType = FieldType;
    public currentLang: string;
    public formGroup: FormGroup;
    public isSubrequest: boolean = false;

    public showButton: boolean = false;
    public displayGroupsTitle: boolean = false;
    public fieldsGroups: any[];

    public linkedChoiceValue: LinkedChoiceValues[] = [];
    public newEntitiesList: any[] = [];

    @Input() config: DirectoryConfig;
    @Input() lang: string;
    @Input() community: any;
    @Input() adbId?: string = "";

    constructor(
        private directoryData: DirectorySharedDataService,
        public directoryAddRequestService: DirectoryAddRequestService
    ) {
    }

    ngOnChanges(): void {
        this.currentLang = this.lang;
        // ADB's id will be given once we have iterated over all add request's fields
        if (this.adbId && this.adbId != "")
            this.isSubrequest = true;

        this.directoryAddRequestService.initFields(this.config, this.community, this.adbId);
        if (this.isSubrequest) {
            // We are in subrequest's form
            this.formGroup = this.directoryAddRequestService.subrequestForm;

            // If widget is of type ADB AND selected ADB hasn't the same id as the current ADB
            //      OR
            // Widget is of type entity AND selected ADB is not "entityForm"
            if ((this.config.concernedAssociatedDB != "" && this.adbId != this.config.concernedAssociatedDB) ||
                    (this.config.concernedAssociatedDB === "" && this.adbId != DirectoryEntityComponent.ENTITY_KEYWORD)) {
                for (let resource of this.config.addSubRequests?.subResourceList) {
                    // If selected ADB has been configured in subrequests
                    if (resource.id === this.adbId) {
                        this.displayGroupsTitle = resource.displayGroupsTitle;
                        this.fieldsGroups = resource.fieldsgroup;
                        break;
                    }
                }
            } else {
                // Take fieldsgroups from add request config
                this.displayGroupsTitle = this.config.addRequests?.displayGroupsTitle;
                this.fieldsGroups = this.config.addRequests?.fieldsgroup;
            }
        } else {
            // We are in add request's form
            this.formGroup = this.directoryAddRequestService.addRequestForm;

            this.displayGroupsTitle = this.config.addRequests?.displayGroupsTitle;
            this.fieldsGroups = this.config.addRequests?.fieldsgroup;
        }

        // Filter fields depending on the community for entity types || UEL/MEL field types (used when multiple communities selection)
        if (this.community != "") {
            this.fieldsGroups = this.fieldsGroups.filter((fieldsgroup) =>
                fieldsgroup.fields.some((field) => field.communities.includes(this.community))
            );
        }
        this.fieldsGroups = this.fieldsGroups.filter((fieldsgroup) => fieldsgroup.fields.length > 0);

        this.linkedChoiceValue = [];
        let _linkedChoiceValueObs = [];
        this.fieldsGroups.forEach((fieldsgroup) => {
            fieldsgroup.fields.forEach((field) => {
                if (field.type === FieldType.LinkedChoice) {
                    _linkedChoiceValueObs.push(
                    this.directoryData.findList(field.range).pipe(concatMap((elementsResult) => {
                        let elements = elementsResult['elements'];

                        if(field.availableValues) {
                            elements = elements.filter(
                              // Only keep list element if found in availableValue
                              x => field.availableValues.map(availableValue => availableValue.key).includes(x.element_id)
                            );
                        }

                        return of({
                            list: elements,
                            field: field,
                            field_value: field.defaultValue ? {'element_id' : field.defaultValue} : null,
                            parent_value: null
                        })
                    })))
                }
            })
        });

        forkJoin(_linkedChoiceValueObs).subscribe((_linkedChoiceValue:LinkedChoiceValues[]) => {

            // all is done, let's rock !

            this.linkedChoiceValue = _linkedChoiceValue;

        });



    }

    public itemsTrackedBy(index, item) {
        return index;
    }

    /** Cross binding for linked choice parent values */
    crossOffValue(newValue) {

        // Find selected field index as it may depend on position
        let fieldIndex: number = this.linkedChoiceValue.findIndex((i) => newValue.selectedField.id === i.field.id);
        // Find selected field
        let fieldList = this.linkedChoiceValue.find((list) => list.field.id === newValue.selectedField.id).list;

        if (newValue.selectedValue != null) {
            // A value has been selected
            let linkedValue = newValue.selectedValue;
            // Update current field value and parent value params
            this.linkedChoiceValue[fieldIndex].field_value = linkedValue;
            this.formGroup.patchValue({[this.linkedChoiceValue[fieldIndex].field.code]: linkedValue.element_id});
            if (linkedValue.core_hasParentValue) {
                this.linkedChoiceValue[fieldIndex].parent_value = fieldList.find((x) =>
                    x.element_id === linkedValue.core_hasParentValue.element_id);

                // Parents only (from child to parent)
                this.updateParentFieldValue(fieldList, newValue.selectedField, linkedValue.core_hasParentValue.element_id);
            }
            // Children only (from parent to child)
            this.updateChildFieldValue(newValue.selectedField, linkedValue);
        } else {
            // NULL value has been selected
            this.linkedChoiceValue[fieldIndex].field_value = null;
            this.formGroup.patchValue({[this.linkedChoiceValue[fieldIndex].field.code]: null});
            // Give null values to all children except for parentValue
            this.updateChildFieldValue(newValue.selectedField, null);
        }
    }

    updateParentFieldValue(list, field, parentElementId) {
        let currentIndex = this.linkedChoiceValue.findIndex((i) => field.id === i.field.id);
        let currentField = this.linkedChoiceValue[currentIndex];
        let currentParentFieldId = currentField.field.ancestry[0];
        let currentParentField = this.linkedChoiceValue.find((x) => x.field.id === currentParentFieldId);
        let currentParentValue = list.find((x) => x.element_id === parentElementId);

        if (currentParentField.field_value === null) {
            currentParentField.field_value = currentParentValue;
            this.formGroup.patchValue({[currentParentField.field.code]: currentParentValue.element_id});
            // Unless we are at the root level, parent will always have a grandparent
            if (currentParentValue.core_hasParentValue) {
                let elementGrandParent = list.find((x) => x.element_id === currentParentValue.core_hasParentValue.element_id);
                currentParentField.parent_value = elementGrandParent;

                this.updateParentFieldValue(list, currentParentField.field, elementGrandParent.element_id);
            }
        }
    }

    updateChildFieldValue(field, currentValue) {
        let currentIndex = this.linkedChoiceValue.findIndex((i) => field.id === i.field.id);
        let currentParentValue = this.linkedChoiceValue[currentIndex].parent_value;
        let counter = 0;

        while (counter < this.linkedChoiceValue.length) {
            let currentField = this.linkedChoiceValue[counter];

            if (currentField.field.ancestry.includes(field.id)) {
                if (currentValue != null) {
                    // If parent tree is different, give null values to children
                    if ((currentField.field_value != null) &&
                        (currentField.parent_value.element_id != currentValue.element_id)) {
                        currentField.field_value = null;
                        this.formGroup.patchValue({[currentField.field.code]: null});
                    }
                    // Give selectedValue to all children
                    currentField.parent_value = currentValue;
                } else {
                    currentField.field_value = null;
                    this.formGroup.patchValue({[currentField.field.code]: null});
                    currentField.parent_value = currentParentValue;
                }
            }

            counter++;
        }
    }
}

interface LinkedChoiceValues {
    list: any[];
    field: any;
    field_value: any;
    parent_value: any;
}
