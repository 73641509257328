<div class="directory-list-wrapper" [class.container]="!hasMapDisplayed">

  <nav class="directory-results-header">
    <div class="util-el-background">
      <p class="directory-results-count">
        {{ nbResults }}
        <ng-container *ngIf="widgetConfig.subject && widgetConfig.subject[widgetLang]; else basicresults"> {{ widgetConfig.subject[widgetLang]}}</ng-container>
        <ng-template #basicresults>{{ 'directory.results.nb' | translate }}</ng-template>
      </p>
      <div class="flex-spacer"></div>
      <div class="directory-results-view-switch" *ngIf="areListAndMapAvailable()">
        <button
          *ngIf="hasMapDisplayed"
          mat-button
          (click)="toggleOffMap()"
        >
          {{ 'directory.results.list' | translate }}
        </button>
        <button
          *ngIf="!hasMapDisplayed"
          mat-button
          appCustomizedFontColor
          appCustomizedHoverColor
          [disableRipple]="!hasMapDisplayed"
          (click)="toggleOffMap()"
        >
          {{ 'directory.results.list' | translate }}
        </button>

        <mat-divider vertical="true"></mat-divider>
        <button
          mat-button
          *ngIf="hasMapDisplayed"
          appCustomizedFontColor
          appCustomizedHoverColor
          [disableRipple]="hasMapDisplayed"
          (click)="toggleOnMap()"
        >
          {{ 'directory.results.map' | translate }}
        </button>
        <button
          mat-button
          *ngIf="!hasMapDisplayed"
          (click)="toggleOnMap()"
        >
          {{ 'directory.results.map' | translate }}
        </button>
      </div>
      <button
        mat-mini-fab
        *ngIf="canListbeHidden() && !isSheetOpen()"
        (click)="hideList()"
        class="directory-list-close-button"
        [class.list-on-the-left]="isListOnTheLeft()"
        appCustomizedHoverColor
      >
        <mat-icon appCustomizedFontColor>{{isListOnTheLeft() ? 'chevron_left' : 'chevron_right' }}</mat-icon>
      </button>
    </div>
  </nav>
  <ng-container *ngIf="resultsList && resultsList.length > 0">
  <cdk-virtual-scroll-viewport itemSize="{{itemSize}}" minBufferPx="{{itemSize*3*virtualFactor}}" maxBufferPx="{{itemSize*6*virtualFactor}}" class="virtual-scroll-viewport">
    <div class="directory-list-core" [class.thumbnail-list]="widgetConfig.layout.hasThumbnail">
      <ng-container *cdkVirtualFor="let result of resultsList ; trackBy : itemsTrackedBy" >
        <app-directory-summary [summaryData]="result" [vScrollSize]="itemSize"></app-directory-summary>
      </ng-container>
    </div>
  </cdk-virtual-scroll-viewport>
</ng-container>
</div>


<div *ngIf="!hasMapDisplayed" class="partners-logos" [class.list-on-the-left]="isListOnTheLeft()">
  <img src="../../../../assets/medias/img/craft-logo.png" alt="Logo Craft">
  <img [src]="widgetConfig.logo" alt="Logo Partenaire" *ngIf="widgetConfig.logo">
  <img src="../../../../assets/medias/img/bdi-logo.jpg" alt="Logo BDI" *ngIf="!widgetConfig.logo">
</div>

<div class="floating-buttons" [class.list-on-the-left]="isListOnTheLeft()" *ngIf="!hasMapDisplayed">
  <button *ngIf="widgetConfig?.addRequests?.hasAddRequestsPerMessage" mat-raised-button appearance="with-icon" (click)="openCreationRequestDialog()">
    <mat-icon>add_circle</mat-icon>
    {{ 'directory.results.add_request' | translate }}
  </button>
  <a *ngIf="widgetConfig?.addRequests?.hasAddRequestsPerExternalLink"
      mat-raised-button
      appearance="with-icon"
      [href]="widgetConfig?.addRequests?.creationFormLink"
      target="_blank">
    <mat-icon>add_circle</mat-icon>
    {{ 'directory.results.add_request' | translate }}
  </a>
  <a *ngIf="widgetConfig?.addRequests?.hasAddRequestsPerEmbeddedForm"
      mat-raised-button
      appearance="with-icon"
      [routerLink]="'/'+widgetLang+'/annuaire/'+widgetId+'/demande-ajout'">
    <mat-icon>add_circle</mat-icon>
    {{ 'directory.results.add_request' | translate }}
  </a>
  <button mat-raised-button appearance="with-icon" class="ml-2" (click)="openDataSourceDialog()">
    <mat-icon>info_outlined</mat-icon>
    {{ 'directory.source' | translate }}
  </button>
</div>
