<div class="directory-entity-contact-wrapper">
  <div class="dialog-header">
      <h1>{{ 'directory.askLeave.title' | translate }}</h1>
  </div>
  
    <mat-dialog-actions align="end">
      <button mat-button (click)="confirmLeave()">{{ 'directory.askLeave.confirmLeave' | translate }}</button>
      <button mat-raised-button color="primary" (click)="stay()">{{ 'directory.askLeave.stay' | translate }}</button>
    </mat-dialog-actions>

</div>
