import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

import { DirectorySharedDataService } from '../directory-shared-data.service';
import { DirectoryAddRequestService } from '../directory-add-request/directory-add-request.service';
import { DirectoryEmbedRequestService } from '../directory-embed-request/directory-embed-request.service';

import { FieldType } from '../../models/fields/field.model';
import { ModificationsValue, Requester } from './../../models/directory-modifications-request.model';

@Injectable({
  providedIn: 'root',
})
export class DirectoryModificationsRequestService {
  private modificationsRequests = new BehaviorSubject<ModificationsValue[]>([]);

  public isEditionModeActive: boolean = false;
  public requester: Requester;
  public currentElementDomain: string;

  constructor(
    private http: HttpClient,
    public directoryAddRequestService: DirectoryAddRequestService,
    public directoryEmbedRequestService: DirectoryEmbedRequestService,
    private directoryData: DirectorySharedDataService
  ) {}

  public getModificationsRequests(): Observable<ModificationsValue[]> {
    return this.modificationsRequests.asObservable();
  }
  public changeModificationsRequests(newModificationsRequests): void {
    this.modificationsRequests.next(newModificationsRequests);
  }

  public updateModificationsRequest(modificationToUpdate: ModificationsValue) {
    // Modifications before the new update
    const allModificationsRequests = this.modificationsRequests.getValue();

    const updatedModificationsRequests = allModificationsRequests.filter((currentModificationsRequest) => 
      currentModificationsRequest.field_id !== modificationToUpdate.field_id
    );

    updatedModificationsRequests.push(modificationToUpdate);
    this.changeModificationsRequests(updatedModificationsRequests);
  }

  public removeModificationsRequest(modificationToDelete: ModificationsValue) {
    // Modifications before the new update
    const allModificationsRequests = this.modificationsRequests.getValue();

    const updatedModificationsRequests = allModificationsRequests.filter((currentModificationsRequest) => 
      currentModificationsRequest.field_id !== modificationToDelete.field_id
    );

    this.changeModificationsRequests(updatedModificationsRequests);
  }

  public isCurrentDomainField(fieldId, domain) {    
    if(this.currentElementDomain != null && this.currentElementDomain != "") {
      // is widget based on associated DB
      if (fieldId.includes("hasListName") || domain === this.currentElementDomain)
        return true;
    } else {
      // the widget is based on entity
      if (!fieldId.includes("hasListName") && (domain == null || domain == undefined || fieldId.includes("hasName")))
        return true;
    }   
    return false; 
  }

  /** Send data */
  public sendRequest(requester: Requester, entityId: string): Observable<any> {
    let allValues = this.modificationsRequests.getValue().some((field) => field.field.type != FieldType.LinkedChoice) ? 
                    this.modificationsRequests.getValue().filter(value => value.value != null) : 
                    this.modificationsRequests.getValue();

    let fullRequest = {
      values: allValues,
      requester: requester,
      new_entities: this.directoryEmbedRequestService.newObjectsRequest.new_entities,
      new_associated_data: this.directoryEmbedRequestService.newObjectsRequest.new_associated_data,
    }
    
    // Reset service list
    this.directoryEmbedRequestService.updateNewEntity({});
    this.directoryEmbedRequestService.updateNewElement({});
    this.directoryEmbedRequestService.newObjectsRequest = {};
    
    const headers = this.directoryData.httpHeaders;
    if (this.currentElementDomain != null && this.currentElementDomain != "") {
      return this.http.put(`${environment.apiUri}/v5/${this.directoryData.widgetLang}/associated-data/${this.currentElementDomain}/elements/${entityId}`, fullRequest,
      {
        headers,
      });
    } else {
      return this.http.put(`${environment.apiUri}/v5/${this.directoryData.widgetLang}/entities/${entityId}`, fullRequest,
      {
        headers,
      });
    }
  }

  public destroyStore() {
    this.directoryEmbedRequestService.newObjectsRequest = {};
    this.isEditionModeActive = false;
    this.changeModificationsRequests([]);
    this.requester = null;
  }
}