import { AbstractControl } from '@angular/forms';
import { parsePhoneNumberFromString } from 'libphonenumber-js/min'

export function ValidatePhone(control: AbstractControl) {
  if(control.value) {
    let countryCode = null;
    if (String(control.value).startsWith('0')) {
      countryCode = navigator.language.split(/[-;]/)[0].toUpperCase();
    }
    const phoneNumber = parsePhoneNumberFromString(control.value, countryCode);
    return phoneNumber && phoneNumber.isValid() ? null : {
      phone: true
    };
  }
  return;
}

