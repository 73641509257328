import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DirectoryConfig } from '../../models/directory-config.model';
import { DirectoryAddRequestService } from '../../services/directory-add-request/directory-add-request.service';

@Component({
  selector: 'app-directory-community-select',
  templateUrl: './directory-community-select.component.html',
  styleUrls: ['./directory-community-select.component.scss']
})
export class DirectoryCommunitySelectComponent implements OnInit {
  public listPossibilities = [];
  public currentLang: string;

  @Input() widgetConfig: DirectoryConfig;

  constructor(
    private translateService: TranslateService,
    public directoryAddRequestService: DirectoryAddRequestService
  ) { }

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang;
    if (this.widgetConfig.addRequests) {
      this.listPossibilities = this.widgetConfig.addRequests.communitiesList;
    }
  }
}
