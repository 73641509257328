<form [formGroup]="formGroup">
  <div *ngIf="!imgFieldValue" class="new-value">
    <button mat-stroked-button
        color="primary" 
        class="stroked mr-2" 
        (click)="imageInput.click()">
      {{ 'fields.image.chooseImage' | translate }}
    </button>
    <input #imageInput
      type="file"
      accept="image/*"
      class="inputfile" 
      id="{{ 'inputfile-'+field.id }}" 
      (change)="processFile(imageInput)">
    <em>{{ 'fields.image.noImageSelected' | translate }}</em>
  </div>
  <div class="initial-value" *ngIf="imgFieldValue">
    <img [src]="imgFieldValue" class="img-thumbnail" [alt]="localForm.value['file-'+field.id].name"/>
    <p><em>
      {{ localForm.value['file-'+field.id].name }}
    </em></p>
    <button mat-stroked-button 
        class="stroked mr-2 mt-3"
        color="primary"
        (click)="removeImage()">
      {{ 'fields.image.removeImage' | translate }}
    </button>
  </div>
  <mat-form-field appearance="outline" class="image-id-holder">
    <input matInput
      [formControlName]="field.code"
      [required]="field.required"
      type="text">
  </mat-form-field>
</form>


<!-- <ng-template #image>
  <div class="values-comparison">
    <div class="initial-value" *ngIf="imgFieldValue">
      <p class="body2">
        {{ 'directory.requests.currentValue' | translate }} :
      </p>
      <img [src]="imgFieldValue" class="img-thumbnail" [alt]="modificationRequestForm.value['file-'+field.id].name"/>
      <p><em>
        {{ modificationRequestForm.value['file-'+field.id].name }}
      </em></p>
      <button mat-stroked-button 
          class="stroked mr-2 mt-3"
          color="primary"
          (click)="removeImage()">
        {{ 'fields.image.removeImage' | translate }}
      </button>
    </div>
    <div *ngIf="!imgFieldValue" class="new-value">
      <p class="body2">
        {{ 'directory.requests.newValue' | translate }} :
      </p>
      <button mat-stroked-button
          color="primary" 
          class="stroked mr-2" 
          (click)="imageInput.click()">
        {{ 'fields.image.chooseImage' | translate }}
      </button>
      <input #imageInput
        type="file"
        accept="image/*"
        class="inputfile" 
        id="{{ 'inputfile-'+field.id }}" 
        (change)="processFile(imageInput)">
      <em>{{ 'fields.image.noImageSelected' | translate }}</em>
    </div>
  </div>
</ng-template> -->