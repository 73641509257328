import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DirectoryConfig } from 'src/app/directory/models/directory-config.model';
import { ModificationsValue } from 'src/app/directory/models/directory-modifications-request.model';
import { DirectoryModificationsRequestService } from 'src/app/directory/services/directory-modifications-request/directory-modifications-request.service';
import { DirectoryFieldForRequestDialogComponent } from '../../directory-field-for-request-dialog/directory-field-for-request-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { ValidatePhone, ValidateEmail } from 'src/app/shared/shared.module';
import { FieldType } from 'src/app/directory/models/fields/field.model';

@Component({
  selector: 'app-directory-modifications-request-validation',
  templateUrl: './directory-modifications-request-validation.component.html',
  styleUrls: ['./directory-modifications-request-validation.component.scss'],
})
export class DirectoryModificationsRequestValidationComponent implements OnInit {
  public widgetConfig: DirectoryConfig;
  public modificationsRequests: ModificationsValue[] = [];
  public personnalInfosForm: FormGroup;
  public currentLang: string;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<DirectoryModificationsRequestValidationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModificationsValue[],
    private directoryModificationsRequestService: DirectoryModificationsRequestService,
    public translateService: TranslateService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.modificationsRequests = this.data;
    this.currentLang = this.translateService.currentLang;
    this.personnalInfosForm = this.fb.group({
      name: new FormControl('',Validators.required),
      email: new FormControl('',[Validators.required, ValidateEmail]),
      phone: new FormControl('', ValidatePhone)
    });
  }

  public validate(){
    this.dialogRef.close({
      name: this.personnalInfosForm.get('name').value,
      phone: this.personnalInfosForm.get('phone').value,
      email: this.personnalInfosForm.get('email').value,
    });
  }

  public editModification(modification: ModificationsValue, index: number) {
    const dialogRef = this.dialog.open(DirectoryFieldForRequestDialogComponent, {
      width: '800px',
      maxWidth: '100vw',
      panelClass: 'custom-dialog-field-request-container',
      data: modification,
    });

    // Executes after editing any modified field listed in the summary request dialog
    dialogRef.afterClosed().subscribe((editedFields) => {
      // modification -> selected field
      // editedFields -> all fields with new values (array used by LC)

      // Are there any modifications after opening the edit dialog?
      if (editedFields && editedFields.length > 0 && editedFields[0]) {
        // When a value has changed, the original tree will change as well so it loses compatibility with its children
        // editedFields will have multiple values if modification's a LC type

        // If LC type: Get all fields to delete from our family tree
        if (modification.field.type === FieldType.LinkedChoice) {
          for (let fieldToDelete of this.modificationsRequests) {
            if (fieldToDelete.field.type === FieldType.LinkedChoice) {
              // If they have the same last item on field.ancestry => they share the same tree
              let isChild = ((modification.field.ancestry.length > 0) &&
                            ((modification.field.ancestry.at(-1) === fieldToDelete.field.ancestry.at(-1)) ||
                            (modification.field.ancestry.at(-1) === fieldToDelete.field_id)));
              let isRoot = ((modification.field.ancestry.length === 0) &&
                            ((modification.field_id === fieldToDelete.field.ancestry.at(-1)) ||
                            (modification.field_id === fieldToDelete.field_id)));

              if (isChild || isRoot) {
                this.directoryModificationsRequestService.removeModificationsRequest(fieldToDelete);
                this.modificationsRequests = this.modificationsRequests.filter((x) => x.field_id !== fieldToDelete.field_id);
              }
            }
          }
        } else {
          this.directoryModificationsRequestService.removeModificationsRequest(modification);
          this.modificationsRequests = this.modificationsRequests.filter((x) => x.field_id !== modification.field_id);
        }

        let listIndex: number;
        editedFields.forEach((edited) => {
          // We need to add edited fields
          this.modificationsRequests.push(edited);

          listIndex = this.modificationsRequests.findIndex(x => x.field_id === edited.field_id);
          this.directoryModificationsRequestService.updateModificationsRequest(edited);
          if (listIndex === -1) {
            listIndex = index;
          }
          this.modificationsRequests[listIndex] = edited;
        })
      }
    });
  }

  public showImage(modification: ModificationsValue) {
    return (modification.field.type === FieldType.Image);
  }

  public deleteModification(modification: ModificationsValue, index: number) {
    this.modificationsRequests.forEach((fieldToDelete) => {
      if (modification.field.type === FieldType.LinkedChoice) this.getChildrenAndDelete(fieldToDelete, modification);

      // After deleting all its children (LinkedChoice only), we can delete the selected field
      if (modification.field_id === fieldToDelete.field_id) {
        this.directoryModificationsRequestService.removeModificationsRequest(fieldToDelete);
        this.modificationsRequests = this.modificationsRequests.filter((x) => x.field_id !== fieldToDelete.field_id);
      }
    })
  }

  getChildrenAndDelete(childToDelete, modification) {
    // Check if the selected field is a LinkedChoice type and it has children
    if (childToDelete.field.type === FieldType.LinkedChoice && 
        childToDelete.field.ancestry.some((y) => y === modification.field_id)) {
      // Delete all children and remove them from the list
      this.directoryModificationsRequestService.removeModificationsRequest(childToDelete);
      this.modificationsRequests = this.modificationsRequests.filter((x) => x.field_id !== childToDelete.field_id);
    }
  }
}
