import { CONSTANTS } from 'src/environments/constants';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DirectorySearchService } from 'src/app/directory/services/directory-search/directory-search.service';
import { DirectoryConfig } from 'src/app/directory/models/directory-config.model';
import { FieldType } from 'src/app/directory/models/fields/field.model';
import { DirectoryEntityComponent } from '../../directory-entity/directory-entity.component';

@Component({
  selector: 'app-find-entity-dialog',
  templateUrl: './find-entity-dialog.component.html',
  styleUrls: ['./find-entity-dialog.component.scss']
})
export class FindEntityDialogComponent implements OnInit {
  public CONSTANTS = CONSTANTS;
  title: string;
  multiple: boolean = false;

  currentCommunities: string[] = [];
  selectedEntities: string[] = [];
  availableEntities: any[] = [];
  results: any[] = [];
  filteredResults: any[] = [];

  communityFilteredResults: any[] = [];
  interestAreaFilteredResults: any[] = [];

  public isSubrequest: boolean = false;
  public config: DirectoryConfig;
  public field: any;

  constructor(
    private searchService: DirectorySearchService,
    public dialogRef: MatDialogRef<FindEntityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      title: string,
      field?: any,
      value?: string[],
      multiple?: boolean,
      isSubrequest?: boolean,
      config?: DirectoryConfig,
      newEntities?: any[]
    }
  ) {}

  ngOnInit() {
    if (this.data.title)
      this.title = this.data.title;
    if (this.data.value)
      this.selectedEntities = this.data.value;
    if (this.data.field)
      this.field = this.data.field;
    if (this.data.multiple)
      this.multiple = true;

    if (this.data.isSubrequest)
      this.isSubrequest = this.data.isSubrequest;
    if (this.data.config)
      this.config = this.data.config;

    if (this.data.field.communityRestriction)
      this.communityFilteredResults = this.data.field.communityRestriction; // accessible_communities
    if (this.data.field.interestAreaRestriction)
      this.interestAreaFilteredResults = this.data.field.interestAreaRestriction; // accessible_interest_areas

    // Get all entities before applying any filters
    this.searchService.findAllEntities().subscribe((results) => {
      // Apply community and interest area restrictions
      if (this.communityFilteredResults.length > 0 && this.interestAreaFilteredResults.length > 0) {
        // Add Entities whose assigned Communities and Interest Areas equals those of the field
        this.results = this.getFilteredEntities(results['entities']);

        // Add new entities created from an old iteration
        if (this.data.newEntities) {
          this.data.newEntities.forEach((entity) => {
            this.results.unshift(entity);
          });
        }

        this.filteredResults = this.results;
      }
    });
  }

  public getFilteredEntities(entities) {
    let filteredEntities1: any = [];
    let filteredEntities2: any = [];

    // Communities
    if (this.communityFilteredResults.length > 0) {
      entities.forEach((entity) => {
        this.communityFilteredResults.forEach((community) => {
          if (entity.community.some((entityCommunity) => entityCommunity.community_id === community.community_id) && (filteredEntities1.indexOf(entity) === -1)) {
            filteredEntities1.push(entity);
          }
        })
      });
    }
    // Interest areas
    if (this.interestAreaFilteredResults.length > 0) {
      filteredEntities1.forEach((entity) => {
        this.interestAreaFilteredResults.forEach((interestArea) => {
          if (entity.interest_areas.some((entityIA) => entityIA.interest_area_id === interestArea.interest_area_id) && (filteredEntities2.indexOf(entity) === -1)) {
            filteredEntities2.push(entity);
          }
        });
      });
    }

    return filteredEntities2;
  }

  public cancelDialog(): void {
    this.dialogRef.close({ confirmation: false });
  }
  public confirmChoice(): void {
    // If we are not in the subrequest form & a new entity has been selected, send it to FindEntity Service
    if (!this.isSubrequest && this.selectedEntities.some(id => id.includes("_:"))) {
      let selectedNewEntities = [];
      this.selectedEntities.forEach((entityId) => {
        if (entityId.includes("_:")) {
          let currentNewSelectedEntity = this.filteredResults.find(entity => entity.entity_id === entityId);
          selectedNewEntities.push(currentNewSelectedEntity)
        }
      });
      this.dialogRef.close({ confirmation: true, entities: this.selectedEntities, newEntities: selectedNewEntities });
    } else
      this.dialogRef.close({ confirmation: true, entities: this.selectedEntities });
  }

  public getEntityInAvailableEntities(entityId: string) {
    let entity =  this.availableEntities.find(x => x.entity_id === entityId);
    if (entity) {
      return entity;
    }
  }

  public getFieldValue(fields: any[], fieldId: string) {
    if (fields && fieldId) {
      return fields.find(x => x.entity_id === fieldId).value;
    }
  }

  public isSelected(id: string) {
    if (Array.isArray(this.selectedEntities)) {
      if (this.selectedEntities.find(x => x === id)) {
        return true;
      }
      return false;
    } else {
      if (this.selectedEntities === id) return true;
    }
    return false;
  }

  public toggleSelection(id: string) {
    if (this.selectedEntities.find(x => x === id)) {
      this.selectedEntities = this.selectedEntities.filter(x => x !== id);
    }
    else {
      this.availableEntities = this.availableEntities.filter(x => x.entity_id !== id);
      this.availableEntities.push(
        this.results.find(x => x.entity_id === id)
      );
      if (this.multiple || this.selectedEntities.length === 0) {
        this.selectedEntities.push(id);
      }
      else {
        this.selectedEntities = [id];
      }

    }
  }

  public delete(entityId: string) {
    this.selectedEntities = this.selectedEntities.filter(x => x !== entityId);
  }

  onSearch(searchQuery: string) {
    this.filteredResults = this.results.filter(option => option[CONSTANTS.mainName].toLowerCase().includes(searchQuery.toLowerCase()));
  }

  hasSubrequestAdb(): boolean {
    // If addsubrequests has been activated & is not a subrequest & is not add request exception
    if (this.isNotAddRequestException()) {
        // Entity types -> all UEL/MEL field types
        // ADB types -> check if there are any configured ADB in addsubrequests with field ADB
        if ((this.config.concernedAssociatedDB === "") ||
          this.config.addSubRequests?.subResourceList.some((resource) => resource.id === DirectoryEntityComponent.ENTITY_KEYWORD))
            return true;
        return false;
    }
    return false;
  }

  isNotAddRequestException(): boolean {
    let widgetADBType = this.config.concernedAssociatedDB != "";

    // Context 1 -> widget with add request AND subrequest activated
      // If add request AND subrequests are activated
      //    AND
      // IS NOT a subrequest
    if (this.config.addRequests?.hasAddRequestsPerEmbeddedForm &&
        this.config.addSubRequests?.hasAddSubRequestsPerEmbeddedForm &&
        !this.isSubrequest)
      return true;

    // Context 2 -> widget without add request AND subrequest activated
      // If adb type, button will appear only if ADB has been configurated in subrequests
      // If entity type, as add request contains widget's form configuration, we don't have any configured fieldsgroup for subrequests
    // IF add request is not activated AND subrequests is activated
    //    AND
    // IS an adb type
    else if (!this.config.addRequests?.hasAddRequestsPerEmbeddedForm &&
        this.config.addSubRequests?.hasAddSubRequestsPerEmbeddedForm &&
        widgetADBType)
      return true;

    else
      return false;
  }

  getNewElement(event) {
    // Add the new element to entities list
      this.filteredResults.unshift(event);

    if (this.field.type === FieldType.UniqueEntityLink)
      this.selectedEntities = [event.entity_id];
    else
      this.selectedEntities.unshift(event.entity_id);

    this.isSelected(event.entity_id);
  }

  getNewEntityData(entityId: string) {
    if (this.filteredResults.some(entity => entity.entity_id === entityId) && entityId.includes("_:"))
      return this.filteredResults.find(entity => entity.entity_id === entityId);
    return null;
  }
}
