import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { DirectoryConfig } from 'src/app/directory/models/directory-config.model';
import { DirectoryModificationsRequestService } from 'src/app/directory/services/directory-modifications-request/directory-modifications-request.service';
import { DirectorySharedDataService } from 'src/app/directory/services/directory-shared-data.service';
import { DirectoryFieldForRequestDialogComponent } from '../../directory-field-for-request-dialog/directory-field-for-request-dialog.component';
import { AddFieldTextComponent } from '../add-field/add-field-text/add-field-text.component';

@Component({
  selector: 'app-entity-details-card',
  templateUrl: './entity-details-card.component.html',
  styleUrls: ['./entity-details-card.component.scss'],
})
export class EntityDetailsCardComponent implements OnInit {
    public subscription: Subscription;
    public widgetConfig: DirectoryConfig;
    public entityId: string;

    constructor(
        public dialogRefField: MatDialogRef<AddFieldTextComponent>,
        public dialogRefModif: MatDialogRef<DirectoryFieldForRequestDialogComponent>,
        public directoryModificationsRequestService: DirectoryModificationsRequestService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private directoryData: DirectorySharedDataService
    ) { }

    ngOnInit(): void {
        this.entityId = this.data.entityId;
        
        this.subscription = this.directoryData.getDirectoryConfig().subscribe((config: DirectoryConfig) => {
            this.widgetConfig = config;
        })
    }

    closeAndEditDialog() {
        this.dialogRefField.close("edit");
        this.dialogRefModif.close("edit");
        this.subscription.unsubscribe();
    }

    closeDialog() {
        this.dialogRefField.close("cancel");
        this.dialogRefModif.close("cancel");
        this.subscription.unsubscribe();
    }
}