import { CONSTANTS } from 'src/environments/constants';
import { DirectoryConfig, DirectoryField, DirectoryFieldForSheet } from 'src/app/directory/models/directory-config.model';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DirectorySearchService } from '../../services/directory-search/directory-search.service';
import { DirectorySharedDataService } from '../../services/directory-shared-data.service';
import { DirectoryEntityContactDialogComponent } from '../dialog/directory-entity-contact-dialog/directory-entity-contact-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-directory-adb',
  templateUrl: './directory-adb.component.html',
  styleUrls: ['./directory-adb.component.scss']
})
export class DirectoryAdbComponent implements OnInit, OnChanges {
  public CONSTANTS = CONSTANTS;
  public widgetConfig: DirectoryConfig;

  @Input() range: string;
  @Input() id: string;
  @Input() config: DirectoryConfig;

  public elementValues: any;
  public fieldsId: any = [];
  public currentAdbFields: any = [];

  constructor(
    private directorySearch: DirectorySearchService,
    private directoryData: DirectorySharedDataService,
    public dialog: MatDialog,    
  ) {}

  ngOnInit(): void {
    this.directoryData
      .getDirectoryConfig()
      .subscribe((config) => (this.widgetConfig = config));
  }

  ngOnChanges() {
    if(this.id && this.range) {
      this.directorySearch.getAdbElement(this.range, this.id).subscribe(
        (element) => {
          this.elementValues = element;
          this.fieldsId = Object.keys(element);
          let allConfiguredFields = this.setAllconfiguredFields(this.config);
          this.currentAdbFields = [];
          this.fieldsId.forEach(id => {
            this.currentAdbFields.push(
              allConfiguredFields.find(x => x.id === id)
            );
          });
        }
      )
    }
  }

  canContact() {
    if (this.widgetConfig && this.widgetConfig.requests.hasContactRequests 
        && this.widgetConfig.requests.contact.code && this.elementValues 
          && this.elementValues[this.widgetConfig.requests.contact.code]) {
      return true;
    }
    return false;
  }

  public openContactDialog(contact?: DirectoryField) {
    let contactCode = this.widgetConfig.requests.contact.code;
    let contactEmail;
    if(this.elementValues[contactCode]) {
      contactEmail = this.elementValues[contactCode];
    } else {
      contactEmail = this.widgetConfig.responsibleEmail;
    }
    if(contactEmail) {
      const dialogRef = this.dialog.open(DirectoryEntityContactDialogComponent, {
        width: '600px',
        maxWidth: '100vw',
        maxHeight: '90vh',
        data: {
          entity_id: this.id,
          entity_name: this.elementValues[CONSTANTS.name],
          widget_name: this.widgetConfig.name,
          referer: document.referrer,
          contact_email: contactEmail
        },
        autoFocus: false,
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe((success: string) => {});
    }
  }

  setAllconfiguredFields(directoryConfig: DirectoryConfig): DirectoryFieldForSheet[] {
    let allConfiguredFields = []
    let fieldsGroups;

    if (directoryConfig.layout.onlySpecificFields) {
      fieldsGroups = directoryConfig?.secondarySheets?.find(adb => {return adb.classId == this.range;}).fieldsgroup;
    } else {
      fieldsGroups = directoryConfig.sheet.fieldsgroup;
    }
    fieldsGroups.forEach(fg => {
      allConfiguredFields = [...allConfiguredFields, ...fg.fields];
    });
    return allConfiguredFields;
  }

}
