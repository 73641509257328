<div class="directory-summary-wrapper util-el-background util-states"
  matRipple
  (mouseenter)="openPreviewOnMap(summaryData.entity_id)"
  (mouseleave)="closePreviewOnMap()"
  (click)="focusOnEntity(summaryData.entity_id)"
  [class.thumbnail-mode]="widgetConfig.layout.hasThumbnail"
  [class.util-border-bottom-1]="!widgetConfig.layout.hasThumbnail"
  [style.height]="trueSize+'px'"
  *ngIf="ready == 2"
>
  <h2 class="directory-summary-title pb-2">{{summaryData[widgetConfig?.summary.title.id]}}</h2>
  <p class="directory-summary-description" ellipsis [ellipsis-content]="summaryData[widgetConfig.summary.description.id]?summaryData[widgetConfig.summary.description.id]:''" ></p>
  <figure class="directory-summary-logo" *ngIf="!widgetConfig.layout.hasThumbnail">
    <img
      *ngIf="summaryData[widgetConfig?.summary.logo.id]"
      [src]="summaryData[widgetConfig?.summary.logo.id]"
      [alt]="summaryData[widgetConfig?.summary.logo.id] + ' logo'"/>
  </figure>
  <div class="directory-summary-logo" *ngIf="widgetConfig?.layout.hasThumbnail">
    <div
      class="directory-summary-cover"
      *ngIf="summaryData[widgetConfig?.summary.logo.id] != null"
      [style]="'background-image: url('+urlEnc(summaryData[widgetConfig?.summary.logo.id])+')'">
    </div>
  </div>
  <div class="directory-summary-icons" >
    <ng-container *ngFor="let legendLine of summaryData['legendIcons']">
      <img
        [src]="legendLine.icon"
        alt=""
        height="18" width="18"
        *ngIf="(!legendLine.markerChoice || legendLine.markerChoice == 'custom')"
        class="mr-2"/>

      <div *ngIf="(legendLine.markerChoice == 'predefined')"
        class="mr-2"
        [ngClass]="legendLine.iconBoxShape? 'icon-box' : 'icon-circle'"
        [ngStyle]="{ 'height': '18px', 'width': '18px', 'border-color': (legendLine.iconBorder && !legendLine.iconInvertMode) || (!legendLine.iconBorder && legendLine.iconInvertMode)  ? legendLine.iconMarkerColor : '#fff', 'background-color': legendLine.iconInvertMode ? legendLine.iconMarkerColor : '#FFF' }"
        >
        <div 
            class="{{legendLine.iconMarker}}">
          <div
            class="icon-background"
            [ngStyle]="{ 'background-color': legendLine.iconInvertMode ? '#FFF' : legendLine.iconMarkerColor }"
            ></div>
        </div>
      </div>
    </ng-container>
  </div>  
</div>
