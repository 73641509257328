import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";

import { DirectoryAddRequestService } from "src/app/directory/services/directory-add-request/directory-add-request.service";
import { DirectoryConfig } from "src/app/directory/models/directory-config.model";
import { AddFieldListComponent } from "../../fields/add-field/add-field-list/add-field-list.component";
import { FieldType } from "src/app/directory/models/fields/field.model";
import { DirectoryEntityComponent } from "../../directory-entity/directory-entity.component";

@Component({
    selector: 'app-directory-add-subrequest-dialog',
    templateUrl: './directory-add-subrequest-dialog.component.html',
    styleUrls: ['./directory-add-subrequest-dialog.component.scss']
})
export class DirectoryAddSubrequestDialogComponent implements OnInit {
    public communitySelection: FormGroup;
    public config: DirectoryConfig;
    public currentLang: string;
    public selectedCommunity: any;
    public listCommunities: any[] = [];
    public adbId: string;
    public fieldType: string;
    public comment: string = '';

    public showForm: boolean = false;

    constructor(
        private translate: TranslateService,
        public dialogRef: MatDialogRef<AddFieldListComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public directoryAddRequestService: DirectoryAddRequestService,
        private fb: FormBuilder,
    ) {}

    ngOnInit(): void {
        this.config = this.data.config;
        this.adbId = this.data.adbId;
        this.currentLang = this.translate.currentLang;
        if (this.data.fieldType)
            this.fieldType = this.data.fieldType;

        // Get Admin comment for this form page
        let currentResource;
        if (this.config.addSubRequests?.subResourceList.some(resource => resource.id === this.adbId))
            currentResource = this.config.addSubRequests?.subResourceList.find(resource => resource.id === this.adbId);
        else if ((this.config.concernedAssociatedDB != "" && this.config.concernedAssociatedDB === this.adbId) ||
                (this.config.concernedAssociatedDB === "" && this.adbId === DirectoryEntityComponent.ENTITY_KEYWORD))
            currentResource = this.config.addRequests;

        if (currentResource)
            this.comment = currentResource.comment[this.currentLang];

        // For UEL/MEL fields -> we need the community
        if (this.fieldType === FieldType.UniqueEntityLink || this.fieldType === FieldType.MultipleEntityLink) {
            // Take community from widget's config and filter community from field's restrictions (if any)
            if (this.data.communityRestriction) {
                this.listCommunities = this.config.addRequests.communitiesList.filter((community) =>
                    this.data.communityRestriction.some((comrestr) =>
                        (comrestr.community_id && comrestr.community_id === community.id)
                    )
                );
            } else
                this.listCommunities = this.config.addRequests.communitiesList.filter((community) => community.id != null);

            // If there are more than one community to choose, ask user
            if (this.listCommunities.length > 1) {
                this.communitySelection = this.fb.group({
                    community: new FormControl(''),
                });

                this.communitySelection.get("community").valueChanges.subscribe((selectedCommunity) => {
                    this.selectedCommunity = selectedCommunity;
                    this.showForm = true;
                });
            } else {
                this.selectedCommunity = this.listCommunities[0].id;
                this.showForm = true;
            }
        } else {
            this.selectedCommunity = "";
            this.showForm = true;
        }
    }

    /** Dialog */
    public returnSubrequest() {
        this.dialogRef.close(["success", this.selectedCommunity]);
    }

    public cancelDialog(): void {
        this.directoryAddRequestService.subrequestForm.reset();
        this.dialogRef.close("cancel");
    }
}
