import { Component, Input, OnInit } from '@angular/core';
import { AddField } from '../add-field.model';
import { DirectoryAddRequestService } from 'src/app/directory/services/directory-add-request/directory-add-request.service';
import { FindEntityService } from 'src/app/directory/services/directory-find-entity/directory-find-entity.service';
import { DirectorySharedDataService } from 'src/app/directory/services/directory-shared-data.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-field-image',
  templateUrl: './add-field-image.component.html',
  styleUrls: ['./add-field-image.component.scss']
})
export class AddFieldImageComponent extends AddField  implements OnInit {

  public localForm: FormGroup;
  public imgFieldValue: string;


  constructor(
    public directoryAddRequestService: DirectoryAddRequestService,
    private fb: FormBuilder,
    private directoryStore: DirectorySharedDataService,
    private translate: TranslateService,
    private snackbar: MatSnackBar
  ) {
    super(directoryAddRequestService);
  }

  ngOnInit(): void {
    this.localForm = this.fb.group({
      [`file-${this.field.id}`]: new FormControl('')
    });
  }


  tryToUpload() {
    const formData = new FormData();
    formData.append('image', this.localForm.get("file-"+this.field.id).value);
    const result =  this.directoryStore.uploadImg(formData).subscribe(
      // good day
      body => {
        this.imgFieldValue = body.toString();
        this.formGroup.patchValue({
          [this.field.code]: body
        });
    }, body => { // bad day      
      this.snackbar.open(this.translate.instant("fields.image.notGoodFormat"), "KO");
      this.removeImage();
    });
  }


  /** Image field */
  public processFile(imageInput: any) {
    let image: File = imageInput.files[0];
    if (image) {
      if (  Math.round(( image.size/ 1024)) < 5242 ) {
        this.localForm.patchValue({[`file-${this.field.id}`]: image });
        this.tryToUpload();
      } else {
        this.snackbar.open(this.translate.instant("fields.image.tooBig"), "OK");
      }
    }
  }

  public removeImage() {
    this.localForm.patchValue({ [`file-${this.field.id}`]: null });
    this.imgFieldValue = '';
  }

}
