import { DirectoryConfig } from 'src/app/directory/models/directory-config.model';
import { Component, OnInit } from '@angular/core';
import { DirectorySharedDataService } from 'src/app/directory/services/directory-shared-data.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-directory-header',
  templateUrl: './directory-header.component.html',
  styleUrls: ['./directory-header.component.scss'],
})
export class DirectoryHeaderComponent implements OnInit {
  public widgetConfig: DirectoryConfig;
  public hasMapDisplayed: boolean;
  public currentLang: string;

  constructor(
    private directoryData: DirectorySharedDataService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang;
    this.directoryData.getDirectoryConfig().subscribe(
      (config) => (this.widgetConfig = config)
    );
  }

}
