import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DirectoryComponent } from './components/directory/directory.component';
import { DirectoryEntityPageComponent } from './pages/directory-entity-page/directory-entity-page.component';
import { DirectoryAddRequestComponent } from './pages/directory-add-request/directory-add-request.component';
import { DirectoryFieldForRequestDialogComponent } from './components/directory-field-for-request-dialog/directory-field-for-request-dialog.component';

const directoryRoutes: Routes = [
  {
    path: ':lang/annuaire/:widgetId',
    component: DirectoryComponent,
  },
  {
    path: ':lang/annuaire/:widgetId/entity/:entityId',
    children: [
      {
        path: '',
        component: DirectoryEntityPageComponent,
      },
      {
        path: '',
        component: DirectoryFieldForRequestDialogComponent,
      }
    ]
  },
  {
    path: ':lang/annuaire/:widgetId/entity/:entityId/:editMode',
    component: DirectoryEntityPageComponent,
  },
  {
    path: ':lang/annuaire/:widgetId/entity/:entityId/:editMode/:domain',
    component: DirectoryEntityPageComponent,
  },
  {
    path: ':lang/annuaire/:widgetId/demande-ajout',
    component: DirectoryAddRequestComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(directoryRoutes)],
  exports: [RouterModule],
})
export class DirectoryRoutingModule {}
