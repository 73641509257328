import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ValidatePhone, ValidateEmail } from 'src/app/shared/shared.module';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

import { DirectoryAddRequestService } from 'src/app/directory/services/directory-add-request/directory-add-request.service';

import { AddFieldTextComponent } from '../../fields/add-field/add-field-text/add-field-text.component';
import { DirectoryConfig } from 'src/app/directory/models/directory-config.model';

@Component({
  templateUrl: './directory-visibility-request-validation-dialog.component.html',
  styleUrls: ['./directory-visibility-request-validation-dialog.component.scss']
})
export class DirectoryVisibilityRequestValidationDialogComponent implements OnInit {
  public dialogForm: FormGroup;
  public widgetConfig: DirectoryConfig;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddFieldTextComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackbar: MatSnackBar,
    private translate: TranslateService,
    public directoryAddRequestService: DirectoryAddRequestService
  ) {}

  ngOnInit(): void {
    this.dialogForm = this.fb.group({
      name: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, ValidateEmail]),
      phone: new FormControl('', ValidatePhone),
      message: new FormControl(''),
    });
  }

  public cancelDialog(): void {
    this.dialogRef.close();
  }

  public returnRequester() {
    let requester = {
      name: this.dialogForm.value.name,
      email: this.dialogForm.value.email,
      phone: this.dialogForm.value.phone
    };
    this.directoryAddRequestService.sendVisibilityRequest(
      requester,
      this.dialogForm.value.message,
      this.data.entityId,
      this.data.widgetId
    ).subscribe(
      (val)=> {
        this.snackbar.open(this.translate.instant("directory.creation.sent"), "OK");
        this.dialogRef.close();
      },
      (error)=> {
        if (error.status == 409) {
          this.snackbar.open(this.translate.instant("directory.creation.sent_ec"), "KO");
        } else {
          this.snackbar.open(this.translate.instant("directory.creation.sent_e"), "KO");
        }
        this.dialogRef.close();
      }
    );
  }
}
