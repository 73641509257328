import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Requester } from 'src/app/directory/models/directory-modifications-request.model';

import { DirectoryAddRequestService } from 'src/app/directory/services/directory-add-request/directory-add-request.service';
import { DirectorySharedDataService } from 'src/app/directory/services/directory-shared-data.service';
import { DirectoryVisibilityRequestValidationDialogComponent } from '../../../dialog/directory-visibility-request-validation-dialog/directory-visibility-request-validation-dialog.component';
import { EntityDetailsCardComponent } from '../../entity-details-card/entity-details-card.component';
import { AddField } from '../add-field.model';

@Component({
  selector: 'app-add-field-text',
  templateUrl: './add-field-text.component.html',
  styleUrls: ['./add-field-text.component.scss']
})
export class AddFieldTextComponent extends AddField implements OnInit {
  public placeholder: string = "";
  public pattern: string = "";

  @Input() textarea: boolean = false;
  @Input() float: boolean = false;
  @Input() type: string = "text";
  @Input() subtype: string = "text";

  constructor(
    public directoryAddRequestService: DirectoryAddRequestService,
    public directoryStore: DirectorySharedDataService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router
  ) {
    super(directoryAddRequestService);
  }

  ngOnInit(): void {
    if (this.type === "url") {
      this.placeholder = "http(s)://......";
      this.pattern = "^(https?://)([\\da-z.-]+)\\.([a-z]{2,22})(.*)"; // strange day ... ([-a-zA-Z0-9@:%_+.~#?&/=]*) doesnt work
    } else if (this.type === "email") {
      this.placeholder = "user@mail.com";
      this.pattern = "^([a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,22})$";
    } else if (this.subtype === "number" && !this.float) { // numeric
      this.pattern = "^[+-]?([1-9]+[0-9]*)$";
    } else if (this.subtype === "number" && this.float) { // float
      this.pattern = "^[+-]?(([0-9]*[.]||[0-9]*[,])?[0-9]+)$";
    }
  }

  openShowEntityModal() {
    let entityId = this.directoryAddRequestService.existSiretId;

    const dialogRef = this.dialog.open(EntityDetailsCardComponent, {
      width: '1000px',
      maxWidth: '100vw',
      maxHeight: '90vh',
      panelClass: 'entity-sheet-dialog',
      data: { 
        entityId: entityId
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === "edit") {
        this.router.navigate(["/"+
            this.directoryStore.widgetLang+
            "/annuaire/"+
            this.directoryStore.widgetId+
            "/entity/"+
            entityId+
            "/modifications"]);
      }
    });
  }

  openVisibilityRequestModal() {
    let entityId = this.directoryAddRequestService.existSiretId;

    const dialogRef = this.dialog.open(DirectoryVisibilityRequestValidationDialogComponent, {
      width: '600px',
      maxWidth: '100vw',
      maxHeight: '90vh',
      data: {
        entityId: entityId,
        widgetId: this.directoryStore.widgetId
      },
      autoFocus: false,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((requester: Requester) => {});
  }
}
