import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { concatMap } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { DirectoryResultsStoreService } from './../../services/directory-results-store/directory-results-store.service';
import { DirectorySearchService } from './../../services/directory-search/directory-search.service';
import { DirectorySharedDataService } from 'src/app/directory/services/directory-shared-data.service';

import { OpenedElement } from './../../models/directory-opened-element.model';
import { DirectoryConfig } from 'src/app/directory/models/directory-config.model';
import { DirectoryEntityDialogComponent } from '../dialog/directory-entity-dialog/directory-entity-dialog.component';

@Component({
  selector: 'app-directory-entity-sheet',
  templateUrl: './directory-entity-sheet.component.html',
  styleUrls: ['./directory-entity-sheet.component.scss'],
})
export class DirectoryEntitySheetComponent implements OnInit, OnDestroy {
  public currentOpenedEntityId: string;
  public openedElementRange: string;

  public widgetConfig: DirectoryConfig;
  public widgetId: string;
  public widgetLang: string;
  public nbResults: number;
  resultsList: any; //List of elements on directory-list <ul>
  public subscriptionFirstLevel: Subscription;
  public subscriptionConfig: Subscription;
  public subscriptiongetSearchResults: Subscription;

  @Input() isSheet: boolean = true;
  @Output() closeDialog = new EventEmitter<boolean>();
  @Output() previewMode = new EventEmitter<boolean>(true);

  constructor(
    private resultsStore: DirectoryResultsStoreService,
    private directoryData: DirectorySharedDataService,
    private directorySearchService: DirectorySearchService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.widgetId = this.directoryData.widgetId;
    this.widgetLang = this.directoryData.widgetLang;

    this.subscriptiongetSearchResults = this.directorySearchService.getSearchResults().
      subscribe((x) => {
        this.resultsList = x;
        this.nbResults = this.resultsList.length;
    });
    this.subscriptionConfig = this.directoryData.getDirectoryConfig().      
      subscribe((config) => {
        // console.log("widgetConfigEntitySheet",config)
        this.widgetConfig = config;
    });

    this.subscriptionFirstLevel = this.resultsStore.getOpenedElement().subscribe((openedElement: OpenedElement) => {
      if (openedElement && openedElement.isMain) {
        this.currentOpenedEntityId = openedElement.id;
        this.openedElementRange = openedElement.range;
      } else {
        this.openedElementRange = null;
        this.currentOpenedEntityId = null;
      }
    });
  }

  public closeEntitySheet(): void {
    // console.log("closeEntitySheet")
    // comportement dont je dois m'inspirer
    this.resultsStore.setOpenedElementData(null);
    this.resultsStore.setOpenedElement(null);
  }

  public ngOnDestroy(): void {
      // console.log("destroyEntitySheet")
      this.subscriptionFirstLevel.unsubscribe();
      this.subscriptiongetSearchResults.unsubscribe();
      this.subscriptionConfig.unsubscribe();
  }

  public closeEntityDialog(): void {
    this.previewMode.emit(false); // Display columns when edit mode
    this.closeDialog.emit(true);
  }

  public openEntityInDialog() {
    this.dialog.open(DirectoryEntityDialogComponent, {
      width: '1280px',
      maxWidth: '100vw',
      maxHeight: '90vh',
      panelClass: 'entity-sheet-dialog',
      closeOnNavigation: true,
      disableClose: true
    });
  }

  canGoToPreviousEntity(): boolean {
    if (this.getCurrentEntityPositionInResults() > 0) return true;
    return false;
  }
  canGoToNextEntity(): boolean {
    if ((1 + this.getCurrentEntityPositionInResults()) !== this.nbResults) return true;
    return false;
  }

  switchToNeighbourEntity(currentPosition: number, movement: number) {
    let newIndex = currentPosition + movement;

    this.resultsStore.setOpenedElement(null);
    this.resultsStore.setOpenedElement({
      id: this.resultsList[newIndex].entity_id ? this.resultsList[newIndex].entity_id : this.resultsList[newIndex].element_id,
      range: this.widgetConfig.isFocusOnAssociatedDB && this.widgetConfig.concernedAssociatedDB ? this.widgetConfig.concernedAssociatedDB : null,
      isMain: true
    });
  }

  public getCurrentEntityPositionInResults(): number {
    return this.resultsList.findIndex(x => (x.entity_id ? x.entity_id : x.element_id) === this.currentOpenedEntityId);
  }
}
