import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { FindEntityService } from 'src/app/directory/services/directory-find-entity/directory-find-entity.service';
import { DirectoryAddRequestService } from 'src/app/directory/services/directory-add-request/directory-add-request.service';

import { DirectoryConfig, DirectoryFieldForSheet } from 'src/app/directory/models/directory-config.model';
import { DirectoryEmbedRequestService } from 'src/app/directory/services/directory-embed-request/directory-embed-request.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-field-entity-link',
  templateUrl: './edit-field-entity-link.component.html',
  styleUrls: ['./edit-field-entity-link.component.scss']
})

/**
 * Manages all entity link field types
 * Used in Field for request (dialog), Add request (form), Add subrequest (dialog)
 */

export class EditFieldEntityLinkComponent implements OnInit, OnDestroy {
  public unsubscribe: Subscription;
  public editEntityFieldForm: FormGroup;
  public fieldCodeValue: string[] = [];
  public allNewEntities: any[] = [];

  @Input() field: DirectoryFieldForSheet;
  @Input() multiple: boolean = false;
  @Input() formGroup: FormGroup;
  @Input() isSubrequest: boolean = false;
  @Input() config: DirectoryConfig;
  @Output() chosenEntities = new EventEmitter();

  constructor(
    public directoryAddRequestService: DirectoryAddRequestService,
    private directoryEmbedRequestService: DirectoryEmbedRequestService,
    private findEntity: FindEntityService
  ) {}

  ngOnInit(): void {
    // Create new control and get the chosen Entity

    // ive got the default value in the parent formGroup -> this.formGroup

    if (this.formGroup.value[this.field.code] != null) {
      this.fieldCodeValue = this.multiple ? this.formGroup.value[this.field.code] : [this.formGroup.value[this.field.code]];
    }

    this.editEntityFieldForm = new FormGroup({
      fieldCode: new FormControl(this.fieldCodeValue)
    });

    this.editEntityFieldForm.get('fieldCode').valueChanges.subscribe((data) => {
      this.fieldCodeValue = data;
      this.chosenEntities.emit(data);
    });

    // Listen to any changes on embedRequest service concerning new added entities
    this.unsubscribe = this.directoryEmbedRequestService.getNewEntity().subscribe((newEntity) => {
      if (newEntity && newEntity.values) {
        let entityName = newEntity.values.find(val => val.field_id === "6cb44afb-56e5-4b8b-9b1c-4df8c155d545_hasName").value;
        // Reconstruct element format
        this.allNewEntities.unshift({
          entity_id: newEntity.id,
          "6cb44afb-56e5-4b8b-9b1c-4df8c155d545_hasName": entityName,
          selectedCommunity: newEntity.community,
          values: newEntity.values
        });
      }
    });
  }

  chooseEntity() {
    this.findEntity.search(
      this.multiple ? "directory.findEntities.selectMultipleEntities" : "directory.findEntities.selectOneEntity", // title
      this.field,
      this.fieldCodeValue ? this.fieldCodeValue : [],
      this.multiple,
      this.isSubrequest,
      this.config,
      this.allNewEntities.length > 0 ? this.allNewEntities : [] // Reopen Find Entity dialog after having created a new entity
    )
    .then(res => {
      let entitiesIds = res['entities'];
      let newEntities = res['newEntities'];

      this.processChosenEntities(entitiesIds, newEntities);
    }).catch(error => {});
  }

  processChosenEntities(entitiesIds, newEntities) {
    // If there are new entities from the dialog form -> filter, format and send each one of them to Fields Request
    if (newEntities && newEntities.length > 0) {
      this.directoryEmbedRequestService.formatNewEntity(newEntities, this.field, this.config.interestAreas);
    } else {
      // Alert parent that there are no new entities selected -> remove all new entites saved for this field
      this.directoryEmbedRequestService.formatNewEntity(entitiesIds, this.field, this.config.interestAreas);
    }
    
    // Child form
    this.editEntityFieldForm.patchValue({
      fieldCode: entitiesIds
    });
    // Parent form -> needs to send the selected data
    this.formGroup.patchValue({
      [this.field.code]: entitiesIds
    });
    this.formGroup.markAsTouched();
  }
  
  getNewEntityData(entityId: string) {
    if (this.allNewEntities.some(entity => entity.entity_id === entityId))
      return this.allNewEntities.find(entity => entity.entity_id === entityId);
    return null;
  }

  public delete(entityIdToDelete: string) {
    if (entityIdToDelete.includes("_:")) {
      this.directoryEmbedRequestService.formatNewEntity(entityIdToDelete, this.field, this.config.interestAreas);
    }

    // Child form
    this.editEntityFieldForm.patchValue({
      fieldCode: this.fieldCodeValue.filter(x => x !== entityIdToDelete)
    });
    // Parent form -> needs to send the selected data
    this.formGroup.patchValue({
      [this.field.code]: this.fieldCodeValue.filter(x => x !== entityIdToDelete)
    });
    this.formGroup.markAsTouched();
  }
  
  ngOnDestroy(): void {
    if (this.unsubscribe) this.unsubscribe.unsubscribe();
  }
}