import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { DirectorySharedDataService } from '../../services/directory-shared-data.service';
import { DirectoryFiltersStoreService } from '../../services/directory-filters-store/directory-filters-store.service';

import { DirectoryConfig, DirectoryLegend } from '../../models/directory-config.model';
import { DirectoryAppliedFilter } from '../../models/directory-applied-filter.model';

@Component({
  selector: 'app-directory-legend',
  templateUrl: './directory-legend.component.html',
  styleUrls: ['./directory-legend.component.scss'],
})
export class DirectoryLegendComponent implements OnInit {
  public widgetConfig: DirectoryConfig;
  public legend: DirectoryLegend;
  public selectedLegend: string[] = [];
  public currentLang: string;

  @Output() nbAppliedLegend = new EventEmitter<number>();
  @Output() emitForUpdateMap = new EventEmitter<boolean>();
  constructor(
    private directoryData: DirectorySharedDataService,
    private directoryFiltersStore: DirectoryFiltersStoreService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang;
    this.directoryData
      .getDirectoryConfig()
      .pipe(
        tap((config: DirectoryConfig) => {
          this.widgetConfig = config;
          this.legend = config.legend;
        }),
        // take(1),
        // tap((config: DirectoryConfig) => {
        //   this.selectedLegend = config.legend.groups.map(x => x.title);
        // })
      )
      .subscribe();

    this.directoryFiltersStore.getAppliedFilters().pipe(
      tap((appliedFilters: DirectoryAppliedFilter[]) => {
        const legendFilter = appliedFilters.find(x => x.concernedField === 'legendList');
        if (legendFilter) {
          this.selectedLegend = legendFilter.value;
        }
      })
    ).subscribe();
  }

  public hideLegend(): void {
    this.emitForUpdateMap.emit(true)
    this.directoryData.changeLegendState(false);
  }

  public onCheckboxChange(
    event: MatCheckboxChange,
    legendLineConcerned: string
  ) {
    if (event.checked === true && !this.selectedLegend.includes(legendLineConcerned)) {
      this.selectedLegend.push(legendLineConcerned);
    }
    else if(event.checked === false) {
      this.selectedLegend = this.selectedLegend.filter(
        (item) => item !== legendLineConcerned
      );
    }
    this.updateAppliedFilters(this.selectedLegend);
  }

  public onAllCheckboxChange(event?: MatCheckboxChange) {
    let allLegendLines = this.widgetConfig.legend.groups.map(x => x.title);
    if(this.selectedLegend.length !== allLegendLines.length) { // notFull => SelectAll
      this.selectedLegend = allLegendLines;
    }
    else { // AlreadyFull => deselectAll
      this.selectedLegend = [];
    }
    this.updateAppliedFilters(this.selectedLegend);
  }

  private updateAppliedFilters(expectedValue: string[]) {
    const currentFilter: DirectoryAppliedFilter = {
      concernedField: 'legendList',
      type: 'MultipleChoiceLegend',
      value: expectedValue,
    };
    if (!expectedValue || expectedValue.length === 0) {
      this.directoryFiltersStore.removeFilter(currentFilter);
    } else {
      this.directoryFiltersStore.updateFilter(currentFilter);
    }
    this.nbAppliedLegend.emit(this.selectedLegend.length);
  }

  public canLegendBeHidden(): boolean {
    if (this.widgetConfig.layout.core.hasList) {
      return false;
    }
    return true;
  }

  public isLegendOnTheLeft(): boolean {
    if (this.widgetConfig && this.widgetConfig.layout.isLegendOnLeft) {
      return true;
    }
    return false;
  }

  public isChecked(lineTitle: string): boolean {
    if (this.selectedLegend.includes(lineTitle)) {
      return true;
    }
    return false;
  }
  public isAllChecked(): boolean {
    let allLegendLines = this.widgetConfig.legend.groups.map(x => x.title);
    if (this.selectedLegend.length === allLegendLines.length) {
      return true;
    }
    return false;
  }
  public isAllIndeterminate(): boolean {
    let allLegendLines = this.widgetConfig.legend.groups.map(x => x.title);
    if (this.selectedLegend.length > 0 &&  this.selectedLegend.length < allLegendLines.length) {
      return true;
    }
    return false;
  }

  public translationCompatibilityForOlderWidget(objectOrString, lang): {} {
    if(typeof objectOrString !== 'object') {
      let tempValue = objectOrString
      objectOrString = {};
      objectOrString[lang] = tempValue;
    }
    return objectOrString[lang];
  }

}
