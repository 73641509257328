<div
  class="entity-card-wrapper util-el-background util-border"
  [class.util-states]="selectable"
  [class.activated]="isSelected"
  (click)="toggleSelection()">
  <p *ngIf="title" class="body1 bold">{{ title }}</p>
  <div>
    <span *ngIf="subtitleSubject" class="caption1 medium">{{ subtitleSubject }} :</span>
    <span *ngIf="subtitle" class="caption1 medium">{{ subtitle }}</span>
  </div>
  <p *ngIf="caption" class="subtitle2">{{ caption }}</p>

  <button class="delete-button" mat-icon-button (click)="triggerDelete()" *ngIf="deletable">
    <mat-icon>delete</mat-icon>
  </button>

</div>
