<div class="find-entity-dialog-wrapper">
  <div class="find-entity-dialog-header headline6 px-4">
    <p>{{ title | translate }}</p>
  </div>
  <div class="find-entity-dialog-search px-4">
    <mat-form-field appearance="outline" class="mb-2 mat-field">
      <mat-label>{{ 'directory.findEntities.searchEntity' | translate }}</mat-label>
      <input matInput 
        (keyup)="onSearch($event.target.value)">
      <mat-icon matPrefix>search</mat-icon>
    </mat-form-field>

    <div class="entity-card-list">
      <app-entity-card *ngFor="let result of filteredResults"
        [title]="result[CONSTANTS.mainName]"
        [entityData]="getNewEntityData(result.entity_id)"
        [isSelected]="isSelected(result.entity_id)"
        (select)="toggleSelection(result.entity_id)"></app-entity-card>
    </div>
    <app-add-subrequest-button 
      *ngIf="hasSubrequestAdb()"
      class="add-button mt-2"
      [config]="config" 
      [adbId]="field.range"
      [field]="field"
      (subrequestElement)="getNewElement($event)"></app-add-subrequest-button>
  </div>

  <div class="find-entity-dialog-save util-el-surface-primary px-4">
    <p class="mt-0 mb-4">{{ 'directory.findEntities.selectedEntities' | translate }}</p>
    <div class="entity-card-list" *ngIf="selectedEntities.length > 0">
      <app-entity-card *ngFor="let entityId of selectedEntities"
        [entityData]="getNewEntityData(entityId)"
        [entityId]="entityId"
        [deletable]="true"
        (delete)="delete(entityId)"></app-entity-card>
    </div>
  </div>
  <div class="find-entity-dialog-footer">
    <button mat-button 
        (click)="cancelDialog()">
      {{ 'directory.findEntities.cancel' | translate }}
    </button>
    <button mat-raised-button 
        color="primary" 
        (click)="confirmChoice()">
      {{ 'directory.findEntities.ok' | translate }}
    </button>
  </div>
</div>