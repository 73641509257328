<div class="dialog-header">
    <h1>{{ 'directory.requests.addNewElement' | translate }}</h1>
    <div class="flex-spacer"></div>
    <button mat-icon-button (click)="cancelDialog()">
        <mat-icon>close</mat-icon>
    </button>
</div>

<div class="container">
    <div class="row">
        <div class="col-12 col-lg-4">
            <div>
                <h2 class="body1 bold">{{ 'directory.requests.add_request' | translate }}</h2>
                <p class="admin-comment">{{ comment }}</p>
                <p class="required-indicator">* {{ 'directory.requests.requiredFields' | translate }}</p>
            </div>
        </div>
        <div class="col-12 col-lg-8">
            <!-- Multiple available communities -->
            <div *ngIf="listCommunities.length > 1">
                <p class="button1 medium">
                    {{ 'directory.requests.selectLocalisation' | translate }}
                    <span class="required-indicator">*</span>
                </p>
                <form [formGroup]="communitySelection">
                    <mat-form-field appearance="outline">
                        <mat-select
                                *ngIf="listCommunities"
                                formControlName="community"
                                required="true">
                            <mat-option
                                    *ngFor="let community of listCommunities"
                                    [value]="community.id">
                                {{ community.title[currentLang] }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </form>
            </div>
            
            <!-- Subrequests form -->
            <form [formGroup]="directoryAddRequestService.subrequestForm" *ngIf="directoryAddRequestService.subrequestForm && showForm">
                <app-directory-fields-request
                    [config]="config"
                    [lang]="currentLang"
                    [adbId]="adbId"
                    [community]="selectedCommunity"></app-directory-fields-request>
            </form>
        </div>
    </div>

    <mat-dialog-actions class="justify-content-end">
        <button mat-button 
                (click)="cancelDialog()">
            {{ 'directory.contact.cancel' | translate }}
        </button>
        <button mat-raised-button 
                color="primary" 
                [disabled]="directoryAddRequestService.subrequestForm.invalid"
                (click)="returnSubrequest()">
            {{ 'directory.requests.valid' | translate }}
        </button>
    </mat-dialog-actions>
</div>