<div class="directory-filters-legend" *ngIf="isLegendInFilters()" #legendButton cdkOverlayOrigin>
  <ng-container *ngIf="!isSmallScreen;else mobileLegendToggle">
    <button
      mat-stroked-button
      class="directory-dropdown-button mat-rounded-button button1"
      appCustomizedFontColor
      appCustomizedHoverColor
      (click)="openLegendDropdown()"
    >
      {{ 'directory.filters.legend' | translate }}
      <ng-container *ngIf="nbAppliedLegend > 0">
        ({{nbAppliedLegend}})
      </ng-container>
    </button>
  </ng-container>
  <ng-template #mobileLegendToggle>
    <button mat-icon-button color="accent" class="directory-dropdown-button" (click)="openLegendDropdown()">
      <mat-icon>category</mat-icon>
    </button>
  </ng-template>
</div>

<ng-template cdk-portal>
  <div class="directory-legend-dropdown-wrapper" [@transformPanelWrap]>
    <div [@transformPanel]="'showing'" class="directory-legend-dropdown-core">
      <app-directory-legend (nbAppliedLegend)="setNbAppliedLegend($event)"></app-directory-legend>
    </div>
    <div class="directory-legend-dropdown-footer util-el-background util-border-top-1">
      <div class="flex-spacer"></div>
      <button mat-button color="primary" (click)="closeLegendDropdown()">{{ 'directory.filters.close' | translate }}</button>
    </div>
  </div>
</ng-template>
