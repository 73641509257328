import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DateAdapter } from '@angular/material/core';

import { DirectoryAddRequestService } from 'src/app/directory/services/directory-add-request/directory-add-request.service';
import { AddField } from '../add-field.model';

@Component({
  selector: 'app-add-field-date',
  templateUrl: './add-field-date.component.html',
  styleUrls: ['./add-field-date.component.scss']
})
export class AddFieldDateComponent extends AddField implements OnInit {

  constructor(
    private translateService: TranslateService,
    public directoryAddRequestService: DirectoryAddRequestService,
    private dateAdapter: DateAdapter<Date>
  ) {
    super(directoryAddRequestService);
    this.dateAdapter.setLocale(this.translateService.currentLang);
  }

  ngOnInit(): void {
  }

}
