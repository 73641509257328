import { Component } from '@angular/core';

@Component({
  selector: 'app-add-field-entity-link',
  templateUrl: './add-field-entity-link.component.html',
  styleUrls: ['./add-field-entity-link.component.scss']
})
/**
 * This type of field is being managed by EditFieldEntityLink component
 */
export class AddFieldEntityLinkComponent {
}
