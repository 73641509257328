import { DirectoryAppliedFilter } from './../../models/directory-applied-filter.model';
import { DirectoryEntitySummary } from 'src/app/directory/models/directory-entity-summary.model';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'directoryApplyFilters',
})
export class DirectoryApplyFiltersPipe implements PipeTransform {
  transform(
    summaryList: DirectoryEntitySummary[],
    appliedFilters: DirectoryAppliedFilter[]
  ): DirectoryEntitySummary[] {
    if (!summaryList || appliedFilters.length === 0) return summaryList;
    return summaryList.filter((val) => this.keepSummary(val, appliedFilters));
  }

  keepSummary(
    summary: DirectoryEntitySummary,
    appliedFilters: DirectoryAppliedFilter[]
  ): boolean {
    let pass: boolean = true;

    appliedFilters.forEach((filter) => {
      let correctValue = summary[filter.concernedField]
      if(!correctValue) { // either its absent or its child property
        correctValue = this.findVal(summary, filter.concernedField);
      }
      if (
        !this.checkProperty(
          filter.type,
          correctValue,
          filter.value
        )
      ) {
        pass = false;
      }
    });

    if (pass) return true;
    return false;
  }

  checkProperty(
    type: string,
    propertyValue: any,
    expectedValues: any | boolean | string[]
  ): boolean {
    if (!propertyValue) return false;
    if (!expectedValues || expectedValues.length === 0) return true;

    if (type === 'TripleState') {
      return this.checkBoolean(propertyValue, expectedValues);
    }
    if (type === 'Boolean') {
      return this.checkBoolean(propertyValue, expectedValues);
    }
    if (type === 'City') {
      return this.checkCity(propertyValue, expectedValues);
    }
    if (type === 'Textarea') {
      return this.checkString(propertyValue, expectedValues);
    }
    if (type === 'NonLocalizedText') {
      return this.checkString(propertyValue, expectedValues);
    }
    if (
      type === 'UniqueChoiceList' ||
      type === 'MultipleChoiceList' ||
      type === 'OrderedMultipleChoiceList' ||
      type === 'MultipleChoiceListWithComment'
      ) {
      return this.checkList(propertyValue, expectedValues);
    }
    if (type === 'MultipleChoiceLegend') {
      return this.checkLegend(propertyValue, expectedValues);
    }
    if (type === 'ExternalSource' || type === 'ExternalSourceAssociatedDb') {
      return this.checkString(propertyValue, expectedValues);
    }

    // type unrecognized
    return false;
  }

  traduceBoolean(value: string): boolean {
    if (value === 'Yes') return true;
    if (value === 'No') return false;
    if (value === 'true') return true;
    if (value === 'false') return false;
  }

  checkBoolean(propertyValue: string, expectedValues: boolean): boolean {
    if (this.traduceBoolean(propertyValue) === expectedValues) {
      return true;
    }
    if (this.traduceBoolean(propertyValue) === false) {
      return false;
    }
  }

  checkCity(
    propertyValue: { city: string; zip_code: string },
    expectedValues: string[]
  ): boolean {
    if (
      propertyValue.city && propertyValue.city.includes(expectedValues[0])
    ) {
      return true;
    }
    if (propertyValue.zip_code && propertyValue.zip_code.includes(expectedValues[0])) {
      return true;
    }
    return false;
  }

  checkLegend(propertyValue: string[], expectedValues: string[]): boolean {
    let pass: boolean = false;
    expectedValues.forEach((expectedValue: string) => {
      if (propertyValue.includes(expectedValue)) {
        pass = true;
      }
    });
    if(pass) return true;
    return false;
  }

  // checkList(propertyValue: {list_id: string, elements: string[]}, expectedValues: string[]): boolean {
  //   let pass: boolean = false;
  //   expectedValues.forEach((expectedValue: string) => {
  //     if (propertyValue.elements.includes(expectedValue)) {
  //       pass = true;
  //     }
  //   });
  //   if(pass) return true;
  //   return false;
  // }

  checkList(propertyValue: any[], expectedValues: string[]): boolean {
    let pass: boolean = false;
    expectedValues.forEach((expectedValue: string) => {
      if(Array.isArray(propertyValue)) {
        if (propertyValue.find(x => x.element_id === expectedValue)) {
          pass = true;
        }
      }
      if(propertyValue["element_id"]) {
        if (expectedValues.find(x => x === propertyValue["element_id"])) {
          pass = true;
        }
      }
    });
    if(pass) return true;
    return false;
  }

  checkString(propertyValue: string, expectedValues: string[]): boolean {
    if (propertyValue && propertyValue.includes(expectedValues[0])) {
      return true;
    }
    return false;
  }

  isUnreachedData(data: any): boolean {
    if(data.element_id) {
      if(Object.keys(data).length === 1) return true
    }
    return false;
  }

  findVal(object, key) { //find Object key recursively
    let objectValue;
    Object.keys(object).some(k => {
      if (k === key) {
          objectValue = object[k];
          return true;
      }
      if (object[k] && typeof object[k] === 'object') {
          objectValue = this.findVal(object[k], key);
          return objectValue !== undefined;
      }
    });
    return objectValue;
}


}
