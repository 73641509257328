import { Component, OnInit, ViewContainerRef, ViewChildren, ViewChild, QueryList } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { DirectorySharedDataService } from '../../services/directory-shared-data.service';
import { DirectoryConfig } from '../../models/directory-config.model';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { Portal, CdkPortal } from '@angular/cdk/portal';
import { matSelectAnimations } from '@angular/material/select';

@Component({
  selector: 'app-directory-legend-dropdown',
  templateUrl: './directory-legend-dropdown.component.html',
  styleUrls: ['./directory-legend-dropdown.component.scss'],
  animations: [
    matSelectAnimations.transformPanelWrap,
    matSelectAnimations.transformPanel,
  ],
})
export class DirectoryLegendDropdownComponent implements OnInit {
  public layoutChanges = this.breakpointObserver.observe([
    '(max-width: 599px)',
  ]);
  public isSmallScreen: boolean;
  public widgetConfig: DirectoryConfig;
  public hasListDisplayed: boolean;
  private overlayRef: any;

  @ViewChildren(CdkPortal) templatePortals: QueryList<Portal<any>>;
  @ViewChild('legendButton') legendButton;
  public nbAppliedLegend: number = 0;

  constructor(
    public breakpointObserver: BreakpointObserver,
    private directoryData: DirectorySharedDataService,
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef
  ) {}

  ngOnInit(): void {
    this.layoutChanges.subscribe((result) => {
      this.updateScreenBreakpoints();
    });
    this.directoryData.getDirectoryConfig().subscribe(
      (config: DirectoryConfig) => (this.widgetConfig = config)
    );
    this.directoryData.getHasListDisplayed().subscribe(
      (state) => (this.hasListDisplayed = state)
    );
  }
  private updateScreenBreakpoints() {
    this.isSmallScreen = this.breakpointObserver.isMatched(
      '(max-width: 599px)'
    );
  }

  public openLegendDropdown(): void | boolean {
    if (this.overlayRef) {
      this.closeLegendDropdown();
      return false;
    }
    const strategy = this.overlay
      .position()
      .flexibleConnectedTo(this.legendButton)
      .withPositions([
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        },
        {
          originX: 'end',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'bottom',
        },
      ]);
    const config = new OverlayConfig({
      hasBackdrop: false,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      positionStrategy: strategy,
    });
    this.overlayRef = this.overlay.create(config);
    this.overlayRef.attach(this.templatePortals.first);
  }

  public setNbAppliedLegend(event): void {
    if(event !== this.widgetConfig.legend.groups.length) {
      this.nbAppliedLegend = event;
    }
    else {
      this.nbAppliedLegend = 0;
    }
  }


  public closeLegendDropdown(): void {
    this.overlayRef.detach();
    this.overlayRef = null;
  }

  public isLegendInFilters(): boolean {
    if (
      this.widgetConfig.layout?.core?.hasList &&
      this.widgetConfig.layout?.hasLegend &&
      this.widgetConfig.legend
    ) {
      return true;
    }
    return false;
  }

}
