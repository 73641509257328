import { Injectable } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

import { FieldType } from '../../models/fields/field.model';
import { CreationValue, Requester } from '../../models/directory-add-request.model';
import { DirectoryConfig, DirectoryFieldForAddRequests } from 'src/app/directory/models/directory-config.model';

import { DirectorySharedDataService } from '../directory-shared-data.service';
import { DirectorySearchService } from '../directory-search/directory-search.service';
import { DirectoryUtilsService } from '../directory-utils/directory-utils.service';
import { DirectoryEmbedRequestService } from '../directory-embed-request/directory-embed-request.service';
import { $$ } from 'protractor';
import { DirectoryEntityComponent } from '../../components/directory-entity/directory-entity.component';

@Injectable({
  providedIn: 'root'
})
export class DirectoryAddRequestService {
  private FieldType = FieldType;
  private fieldsForAddRequestsFromConfig: DirectoryFieldForAddRequests[];

  public communityForm: FormGroup;
  public addRequestForm: FormGroup = new FormGroup({});
  public subrequestForm: FormGroup = new FormGroup({});

  private urlValidators = '^https?://([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';

  public existSiret: boolean = false;
  public existSiretName: string;
  public existSiretId: string;
  public entityDetailsButton: boolean = false;
  public entityVisibilityRequestButton: boolean = false;

  private newEntitiesToSend: NewEntity[] = [];
  private newElementsToSend: NewElement[] = [];

  constructor(
    private fb: FormBuilder,
    private directoryData: DirectorySharedDataService,
    private directorySearch: DirectorySearchService,
    private directoryUtils: DirectoryUtilsService,
    public directoryEmbedRequestService: DirectoryEmbedRequestService,
    private http: HttpClient
  ) { }

  public initForm(directoryConfig: DirectoryConfig) {
    this.directoryData.concernedAssociatedDB = directoryConfig.concernedAssociatedDB;
    this.initCommunity(directoryConfig);
    if(this.communityForm.get('community').value) {
      this.initFields(directoryConfig, this.communityForm.get('community').value);
    }
  }

  public initCommunity(directoryConfig: DirectoryConfig) {
    let communityDefaultValue;
    if(
      directoryConfig.addRequests &&
      directoryConfig.addRequests.communitiesList &&
      directoryConfig.addRequests.communitiesList.length === 1
    ) {
      communityDefaultValue = directoryConfig.addRequests.communitiesList[0].id;
    }
    this.communityForm = this.fb.group({
      community: new FormControl(communityDefaultValue, Validators.required),
    });
    this.communityForcePatch();
  }

  public initFields(directoryConfig: DirectoryConfig, community: string, subrequestAdb?: string) {
    const formgroup: any = {};
    let fieldsFromConfig: any[] = [];
    this.existSiret = false;

    let isSubrequest: boolean = false;
    if (subrequestAdb && subrequestAdb != "")
      isSubrequest = true;
    let widgetEntityType = directoryConfig.concernedAssociatedDB === "";

    // If this is a subrequest
    //    AND
    //    IS a widget of entity type AND IS a field of UEL/MEL type (and so they use "entityForm" as id)
    //        OR
    //    IS a widget of adb type AND IS a field of UCL/MCL type with the same id as the widget's concernedAssociatedDB
    if (isSubrequest &&
        ((widgetEntityType && subrequestAdb != DirectoryEntityComponent.ENTITY_KEYWORD) ||
        (!widgetEntityType && subrequestAdb != directoryConfig.concernedAssociatedDB))) {
      for (let resource of directoryConfig.addSubRequests?.subResourceList) {
        if (resource.id === subrequestAdb) {
          fieldsFromConfig = resource.fieldsgroup.map(x => x.fields);
          break;
        }
      }
    } else
      fieldsFromConfig = directoryConfig.addRequests.fieldsgroup.map(x => x.fields);

    // Merge all fields together and keep only fields in fieldsgroups
    fieldsFromConfig = Array.prototype.concat.apply([], fieldsFromConfig);
    // Keep fields from selected communities (entities only)
    if (community && community != "")
      fieldsFromConfig = fieldsFromConfig.filter(x => x.communities.includes(community));

    fieldsFromConfig.forEach((field: any) => {
      let validators = [];
      let fieldDefaultValue = null;

      if ('defaultValue' in field)
        fieldDefaultValue = field.type === "Boolean" ? String(field.defaultValue) : field.defaultValue;
      if (field.required)
        validators.push(Validators.required);
      if (field.type === "Url")
        validators.push(Validators.pattern(this.urlValidators));
      if (field.type === "Email")
        validators.push(Validators.email);

      formgroup[field.id] = new FormControl(fieldDefaultValue, validators);

      if (field.id === '6cb44afb-56e5-4b8b-9b1c-4df8c155d545_hasNationalIdentifier') {
        formgroup[field.id].valueChanges.pipe(debounceTime(500)).subscribe((newSiret) => {
          this.directoryData.testSiret(newSiret, null).subscribe((response) => { //null because we are creating a new entity
            this.existSiret = Boolean(response.body['id']);

            if (this.existSiret) {
              this.existSiretName = response.body['name'];
              this.existSiretId = response.body['id'];
              // Reinitialize booleans
              this.entityDetailsButton = false;
              this.entityVisibilityRequestButton = false;
              // Entities listed in widget's list (without taking into account any filter)
              this.detailsOrNotificationTests(this.existSiretId, directoryConfig.layout.hasFilters);
            }
          });
        })
      }
    });

    if (subrequestAdb && subrequestAdb != "")
      this.subrequestForm = this.fb.group(formgroup);
    else {
      this.addRequestForm = this.fb.group(formgroup);
      this.fieldsForAddRequestsFromConfig = fieldsFromConfig;
    }
  }

  public sendAddRequest(requester: Requester, community: string, interestAreas: string[], message: string) {
    let valuesForAddRequest: CreationValue[] = [];
    let formValues: {[fieldCode: string]: any}[] = this.addRequestForm.value;

    Object.keys(formValues).forEach(fieldCode => {
      // this means a value list
      if (formValues[fieldCode] !== null) {
        if (Array.isArray(formValues[fieldCode])) {
          let fieldType = this.fieldsForAddRequestsFromConfig.find(field => field.id === fieldCode).type;
          if (fieldType === FieldType.MultipleEntityLink) {
            formValues[fieldCode] = this.makeEntityLinksElement(formValues[fieldCode]);
          }
          else if (fieldType === FieldType.UniqueEntityLink) {
            formValues[fieldCode] = formValues[fieldCode][0] ? formValues[fieldCode][0] : "";
            this.makeEntityLinksElement([formValues[fieldCode]]);
          }
          else {
            // this means a value list
            formValues[fieldCode] = this.makeElement(formValues[fieldCode]);
          }
        }

        // protect for null values
        if (formValues[fieldCode] != '') {
          valuesForAddRequest.push({
            field_id: fieldCode, // TODO: why are we using the code as ID?
            value: formValues[fieldCode]
          });
        }
      }
    });

    let addAddRequest = {
      community: community,
      interest_areas: interestAreas,
      values: valuesForAddRequest,
      comment: message,
      requester: requester,
      new_entities: this.newEntitiesToSend,
      new_associated_data: this.newElementsToSend,
    }

    // Reset service list
    this.directoryEmbedRequestService.updateNewEntity({});
    this.directoryEmbedRequestService.updateNewElement({});
    this.directoryEmbedRequestService.newObjectsRequest = {};

    const headers = this.directoryData.httpHeaders;
    if (this.directoryData.concernedAssociatedDB != null && this.directoryData.concernedAssociatedDB != "") {
      return this.http.post(`${environment.apiUri}/v5/${this.directoryData.widgetLang}/associated-data/${this.directoryData.concernedAssociatedDB}/request`, addAddRequest,
      {
        headers, observe: 'response'
      });
    } else {
      return this.http.post(`${environment.apiUri}/v5/${this.directoryData.widgetLang}/entities/request`, addAddRequest,
      {
        headers, observe: 'response'
      });
    }
  }

  public makeElement(elements) {
    let result = [];

    elements.forEach((element) => {
      result.push({element_id: element});

      if (element.includes("_:") &&
          this.directoryEmbedRequestService.newObjectsRequest.new_associated_data.some(el => el.id === element))
        this.newElementsToSend.push(this.directoryEmbedRequestService.newObjectsRequest.new_associated_data.find(el => el.id === element));
    });

    return result;
  }
  public makeEntityLinksElement(entities) {
    let result = [];
    entities.forEach((entity) => {
      result.push({entity_id: entity});

      if (entity.includes("_:") &&
          this.directoryEmbedRequestService.newObjectsRequest.new_entities.some(ent => ent.id === entity))
        this.newEntitiesToSend.push(this.directoryEmbedRequestService.newObjectsRequest.new_entities.find(ent => ent.id === entity));
    });
    return result;
  }

  public communityForcePatch() {
    // force update (to trigger form generation)
    let communityValue = this.communityForm.get('community').value;
    if(communityValue) this.communityForm.controls.community.patchValue(communityValue);
  }

  public detailsOrNotificationTests(entityId: string, hasFilters: boolean) {
    /**
      * IF filter banner is not visible:
      *   -> Test using entities results list. Is entity listed?
      *       YES -> details button
      *       NO -> visible request button
      * ELSE if filter is visible:
      *   -> Ask back IF entity is visible in widget's config (check if filters are hidding this entity)
      *       YES -> test IF entity is listed in results list
      *             YES -> add button with visible request
      *             NO -> remove the active filters that are hidding the entity + IF there is a legend, reactivate all legend inputs
      *                   Does the entity exist in the new results lists?
      *                         YES -> details button
      *                         NO -> visible request button
      *       NO -> entity will never be visible; visible request button
    */
    this.directorySearch.getSearchResults().subscribe((entities) => {
      if (!hasFilters) {
        if (entities.some(entity => entity.entity_id === entityId)) {
          this.entityDetailsButton = true;
        } else {
          this.entityVisibilityRequestButton = true;
        }
      } else {
        this.directoryData.testEntityVisibility(entityId).subscribe((response) => {
          if (response.status === 200) {
            if (entities.some(entity => entity.entity_id === entityId)) {
              this.entityDetailsButton = true;
            } else {
              this.directorySearch.findResultsForVisibility().subscribe((newEntities) => {
                if (newEntities.some(entity => entity.entity_id === entityId)) {
                  this.entityDetailsButton = true;
                } else {
                  this.entityVisibilityRequestButton = true;
                }
              });
            }
          } else {
            this.entityVisibilityRequestButton = true;
          }
        }, (error) => {
          // entity is not visible
          this.entityVisibilityRequestButton = true;
         });
      }
    });
  }

  public sendVisibilityRequest(requester: Requester, message: string, entityId: string, widgetId: string) {
    let addVisibilityRequest = {
      requester: requester,
      message: message,
      entityId: entityId,
      widgetId: widgetId,
      criteria: this.directorySearch.setParamCriteriaString,
      urlSource: this.directoryUtils.urlSource
    }

    const headers = this.directoryData.httpHeaders;
    return this.http.post(`${environment.apiUri}/v5/${this.directoryData.widgetLang}/entities/visibilityRequest`, addVisibilityRequest,
      {
        headers, observe: 'response'
      });
  }
}

export interface NewObjectRequest {
    new_entities?: NewEntity[];
    new_associated_data?: NewElement[];
}
interface NewEntity {
    id: string;
    community: string;
    interest_areas: string[];
    values: Values[];
}
interface NewElement {
    id: string;
    listId: string;
    listName: string;
    parentUUID?: string;
    values: Values[];
}
interface Values {
    field_id: string;
    value: string | string[];
}
