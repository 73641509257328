import { FormControl, FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DirectorySharedDataService } from 'src/app/directory/services/directory-shared-data.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ValidateEmail } from 'src/app/shared/shared.module';

@Component({
  selector: 'app-directory-entity-contact-dialog',
  templateUrl: './directory-entity-contact-dialog.component.html',
  styleUrls: ['./directory-entity-contact-dialog.component.scss'],
})
export class DirectoryEntityContactDialogComponent implements OnInit {
  public dialogForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<DirectoryEntityContactDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private directoryStore: DirectorySharedDataService,
    private snackbar: MatSnackBar,
    private translate: TranslateService
//     data: {
//       entity_id: string,
//       contact_email: string
//     }
  ) {}

  ngOnInit(): void {
    this.dialogForm = this.fb.group({
      name: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, ValidateEmail]),
      society: new FormControl(),
      phone: new FormControl(),
      address: new FormControl(),
      city: new FormControl(),
      message: new FormControl('', Validators.required),
    });
  }

  public cancelDialog(): void {
    this.dialogRef.close();
  }

  returnForm() {
    let contactRequest = {
      requester: {
        name: this.dialogForm.get('name').value,
        email: this.dialogForm.get('email').value
      },
      request: {
        company: this.dialogForm.get('society').value,
        address: this.dialogForm.get('address').value,
        city: this.dialogForm.get('city').value,
        phone: this.dialogForm.get('phone').value,
        message: this.dialogForm.get('message').value
      },
      aboutcompany: this.data.entity_name,
      email: this.data.contact_email,
      referer: this.data.referer,
      widget: this.data.widget_name,
      id: this.data.entity_id
    };

    this.directoryStore.contactEntity(contactRequest).subscribe(
      (val)=> {
        this.snackbar.open(this.translate.instant("directory.contact.sent"), "OK");
        this.dialogRef.close();
      },
      (error)=> {
        this.snackbar.open(this.translate.instant("directory.contact.sent_e"), "KO");
      }
    )




    // requester: { name: string, email: string }, request: {company: string, address: string, city: string, phone: string, message: string }, email: string, element_id: string
  }
}
