<div class="directory-entity-wrapper">
  <header class="directory-entity-header">
    <div class="directory-entity-header-logo-title">
      <app-directory-entity-field
        *ngIf="(summary?.logo?.value && previewMode) || (!previewMode && summary?.logo?.field?.id)"
        class="directory-entity-header-logo"
        [class.no-name]="previewMode"
        [field]="summary?.logo?.field"
        [fieldValue]="summary?.logo?.value"
        [entityId]="entityId"
        [isEditable]="isEditable"></app-directory-entity-field>
      <app-directory-entity-field
        class="directory-entity-header-title"
        [field]="summary?.title?.field"
        [fieldValue]="summary?.title?.value"
        [entityId]="entityId"
        [isEditable]="isEditable"></app-directory-entity-field>
    </div>
    <div
      class="directory-entity-header-banner"
      [style.backgroundImage]="'url('+urlEnc(summary?.banner?.value)+')'"
      *ngIf="summary?.banner?.value && previewMode"></div>
    <div
      class="directory-entity-header-banner col-6 p-0"
      *ngIf="!previewMode && summary?.banner?.field?.id">
      <app-directory-entity-field
        class="directory-entity-header-banner"
        [field]="summary?.banner?.field"
        [fieldValue]="summary?.banner?.value"
        [entityId]="entityId"
        [isEditable]="isEditable"></app-directory-entity-field>
    </div>
    <div class="directory-entity-field-value directory-entity-header-description" *ngIf="summary?.description?.field?.id">
      <app-directory-entity-field
        [class.no-name]="previewMode"
        [field]="summary?.description?.field"
        [fieldValue]="summary?.description?.value"
        [entityId]="entityId"
        [isEditable]="isEditable"></app-directory-entity-field>
    </div>
  </header>
  <button mat-stroked-button
      *ngIf="canContact()"
      class="directory-entity-contact-button"
      appearance="with-icon"
      color="primary"
      (click)="openContactDialog()">
    <mat-icon>mail</mat-icon>
    <span>{{ 'directory.contact.entity_contact'|translate }}</span>
  </button>

  <div class="entity-groups-col" [class.row]="!previewMode">
    <ng-container *ngFor="let group of filteredFieldsGroups | orderByImpure: 'order'">
      <div class="directory-entity-fields-group util-el-surface mt-3 col-12" [class.col-md-6]="!previewMode && filteredFieldsGroups.length > 1 && !widgetConfig.layout.isFullSize">
        <h3 *ngIf="sheetToUse?.displayGroupsTitle && group.title" class="mt-0">
          {{ translationCompatibilityForOlderWidget(group.title, currentLang) }}
        </h3>
          <ng-container *ngFor="let field of group.fields | orderByImpure: 'order'">
            <app-directory-entity-field
              [field]="field"
              [fieldValue]="currentEntityData[field.id]"
              [class]="'field--'+field.id"
              [entityId]="entityId"
              [entity]="currentEntityData"
              [isEditable]="isEditable"></app-directory-entity-field>
          </ng-container>
      </div>
    </ng-container>
  </div>
</div>
