import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { DirectoryEntitySummary } from 'src/app/directory/models/directory-entity-summary.model';
import { OpenedElement } from 'src/app/directory/models/directory-opened-element.model';
import { DirectoryResultsStoreService } from 'src/app/directory/services/directory-results-store/directory-results-store.service';

@Component({
  selector: 'app-directory-entity-dialog',
  templateUrl: './directory-entity-dialog.component.html',
  styleUrls: ['./directory-entity-dialog.component.scss'],
})
export class DirectoryEntityDialogComponent implements OnInit, OnDestroy {
  public subscriptionLevels: Subscription;
  public currentOpenedEntityId: DirectoryEntitySummary['entity_id'];
  public openedLinkedElementId: string;
  public goBack: boolean = false;
  public isEntitySheetOpened: boolean = false;
  public isLinkedElementSheetOpened: boolean = false;

  constructor(
    private resultsStore: DirectoryResultsStoreService,
    public dialogRef: MatDialogRef<DirectoryEntityDialogComponent>
  ) { }



  ngOnInit(): void {
    //this.dialogRef.disableClose = true;
    if (!this.subscriptionLevels || this.subscriptionLevels.closed) {
      this.subscriptionLevels = this.resultsStore.getOpenedElement().subscribe((openedElement: OpenedElement) => {
        if (openedElement) { //Executes once the element is selected
          if (openedElement && openedElement.id) {    
            if (openedElement.isMain || this.goBack) { //Opens main Element
              this.currentOpenedEntityId = openedElement.id;
              this.openedLinkedElementId = null;
              this.closeMainElement();
              this.openMainElement(openedElement.id);
              this.closeLinkedElement();
              this.goBack = false;
            } else { //Opens linked Element
              this.currentOpenedEntityId = null;
              this.openedLinkedElementId = openedElement.id;
              this.openLinkedElement(openedElement.id);
              this.closeMainElement();
            }
          }
        } else { //Starts with everything closed + close button clicked
          this.currentOpenedEntityId = null;
          this.openedLinkedElementId = null;
          this.goBack = false;
          this.closeMainElement();
          this.closeLinkedElement();
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.resultsStore.setFocusedElement(null)
    this.resultsStore.setFocusedElementData(null)
    this.resultsStore.setOpenedElement(null)
    this.resultsStore.setOpenedElementData(null)
    // permet quand on ferme de dézoomer la carte et de voir l'ensemble des éléments 
    this.subscriptionLevels.unsubscribe();
  }

  public openMainElement(id: DirectoryEntitySummary['entity_id']): void {
    this.isEntitySheetOpened = true;
  }
  public closeMainElement(): void {
    this.isEntitySheetOpened = false;
  }

  public openLinkedElement(id: string): void {
    this.isLinkedElementSheetOpened = true;
  }
  public closeLinkedElement(): void {
    this.isLinkedElementSheetOpened = false;
  }

  stateOfSecondarySheet(state: boolean) {
    this.goBack = state;

    let parentOpenedElement: OpenedElement = this.resultsStore.getParentOpenedElement();
    this.resultsStore.setParentOpenedElement(null);
    this.resultsStore.setOpenedElement(parentOpenedElement);
  }

  closeDialog(event) {
    this.resultsStore.setFocusedElement(null)
    this.resultsStore.setFocusedElementData(null)
    this.resultsStore.setOpenedElement(null)
    this.resultsStore.setOpenedElementData(null)
    // permet quand on ferme de dézoomer la carte et de voir l'ensemble des éléments 
    if (event === true) {
      this.currentOpenedEntityId = null;
      this.openedLinkedElementId = null;
      this.goBack = false;
      this.closeMainElement();
      this.closeLinkedElement();
      this.dialogRef.close();
    }
  }
}
