import { Component, Input, EventEmitter, Output, SimpleChanges, OnChanges } from '@angular/core';
import { CONSTANTS } from 'src/environments/constants';

import { LinkedChoiceField } from 'src/app/directory/models/fields/linked-choice-field.model';

@Component({
    selector: 'app-linked-choice',
    templateUrl: './linked-choice.component.html',
    styleUrls: ['./linked-choice.component.scss']
})
export class LinkedChoiceComponent implements OnChanges {
    public CONSTANTS = CONSTANTS;
    public currentElementWithDepth: any;
    public allElements: any[];
    public availableElements: any[];
    public filteredList: any[];
    public selectedValue: any;

    @Input('field') field: LinkedChoiceField;
    @Input('value') value: any;
    @Input('parentValue') parentValue: any;
    @Input('list') elements: any[];

    @Output() valueToShare = new EventEmitter();

    ngOnChanges(changes: SimpleChanges) {
        // If there is no selected value, this.value is null
        if (this.value != null) this.selectedValue = this.value.element_id;

        if (this.elements.length > 0) { // Check if the list has been received
            // Give a depth level to each element
            this.assignDepthLevel();
            // Get field's value
            this.getFieldValue(this.field, this.value, this.parentValue);
        }
        
        // Value has changed
        if (changes.value && !changes.value.firstChange && (changes.value.currentValue != changes.value.previousValue)) {
            if (changes.value.currentValue != null) {
                this.value = changes.value.currentValue;
                this.selectedValue = changes.value.currentValue.element_id; 
            } else {
                this.value = null;
                this.selectedValue = null;
            }
        }
        // Parent value has changed
        if (changes.parentValue && !changes.parentValue.firstChange && (changes.parentValue.currentValue != changes.parentValue.previousValue)) {
            this.parentValue = changes.parentValue.currentValue;
        }
    }

    assignDepthLevel() {
        let hashArr = {};
        hashArr['undefined'] = [];
        this.elements.forEach((element) => {
            if ('core_hasParentValue' in element) {
                if (!(element.core_hasParentValue.element_id in hashArr)) hashArr[element.core_hasParentValue.element_id] = [];
                hashArr[element.core_hasParentValue.element_id].push(element);
            } else {
                hashArr['undefined'].push(element);
            }
        });
        // List of elements with depth param
        this.allElements = this.hierarchySort(hashArr, 'undefined', [], 0);
    }

    hierarchySortFunc(a, b) {
        return a.core_hasOrder - b.core_hasOrder;
    }

    hierarchySort(hashArr, key, result, depth) {
        if (!(key in hashArr)) return;
        let arr = hashArr[key].sort(this.hierarchySortFunc);
        for (const item of arr) {
            item.depth = depth;
            result.push(item);
            this.hierarchySort(hashArr, item.element_id, result, depth + 1);
        }
        return result;
    }

    getFieldValue(field, value, parent) {
        // Do field have a value?
        if (value != null) {
            if (value.core_hasParentValue) {
                // It's a child field with value. List elements (parents and siblings) with >= current depth and same parent id
                this.availableElements = this.allElements.filter((element) => 
                    (field.ancestry.length > element.depth) ||
                    ((field.ancestry.length === element.depth) &&
                        (value.core_hasParentValue.element_id === element.core_hasParentValue.element_id))
                );
            } else {
                // It's a root field with value. List elements with depth 0
                this.availableElements = this.allElements.filter((element) => element.depth === 0);
            }
        } else {
            // Do field have a parent?
            if (field.parent_field_id) {
                // It's a child field without value. Do field's parent have a value?
                if (parent != null) {
                    // Parent has a value (otherwise it's a root field). List elements with parent id = element parent id
                    this.availableElements = this.allElements.filter((element) => this.searchElementsToAdd(field.ancestry.length, element, parent));
                } else {
                    // No field has a value. List all element with element.depth >= depth
                    this.availableElements = this.allElements.filter((element) => field.ancestry.length >= element.depth);
                }
            } else {
                // It's a root field without value. List elements with depth 0
                this.availableElements = this.allElements.filter((element) => element.depth === 0);
            }
        }
        this.filteredList = this.availableElements;
    }

    searchElementsToAdd(depth, element, parent) {
        if (depth < element.depth) return false;
        if (parent.element_id === element.element_id) return true; // Add parent
        if (this.isParentOfMyParent(element, parent) || this.isChildrenOfMyParent(element, parent)) return true;
        return false;
    }

    isParentOfMyParent(element, parent): boolean {
        if (parent.core_hasParentValue) {
             if (element.element_id === parent.core_hasParentValue.element_id) { // It's a parent's parent
                return true;
            } else {
                let searchParent = this.allElements.find((x) => x.element_id === parent.core_hasParentValue.element_id);
                return this.isParentOfMyParent(element, searchParent);
            }
        } else {
            return false;
        }
    }

    isChildrenOfMyParent(element, parent): boolean {
        if (element.core_hasParentValue) {
            if (element.core_hasParentValue.element_id === parent.element_id) { //It's a child of "parent"
                return true;
            } else {
                let searchChild = this.allElements.find((x) => x.element_id === element.core_hasParentValue.element_id);
                return this.isChildrenOfMyParent(searchChild, parent);
            }
        } else { // It's not a child
            return false;
        }
    }

    onFieldChange(newValue, selectedField) {
        let selectedValue = newValue.value;
        this.valueToShare.emit({selectedValue, selectedField});
    }

    onSearch(searchQuery: string) {
        this.filteredList = this.availableElements.filter((element) => element.core_hasListName.toLowerCase().includes(searchQuery.toLowerCase()));
    }
}