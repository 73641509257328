<app-directory-entity-sheet 
    *ngIf="currentOpenedEntityId" 
    (closeDialog)="closeDialog($event)" 
    [isSheet]="false" 
    class="app-directory-entity-sheet"></app-directory-entity-sheet>
<app-directory-secondary-sheet 
    *ngIf="openedLinkedElementId" 
    (closeSecondSheet)="stateOfSecondarySheet($event)"
    (closeAndEditSecondSheet)="closeDialog($event)"
    [fullSize]="true"
    class="app-directory-entity-sheet"></app-directory-secondary-sheet>