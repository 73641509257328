<div class="directory-modifications-request-validation-wrapper">
  <div class="header">
    <div class="dialog-header util-border-bottom-1 util-border-background pb-3 mb-3">
      <h2 class="headline6 bold">
        {{ 'directory.requests_recap.title' | translate }}
      </h2>
      <div class="flex-spacer"></div>
      <button mat-button [mat-dialog-close]="null">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div class="core">
    <table class="table">
      <caption class="hidden">{{ 'directory.requests_recap.description' | translate }}</caption>
      <thead>
        <tr>
          <th class="first-col body2 bolder" scope="col">
            {{ 'directory.requests_recap.entityField' | translate }}
          </th>
          <th class="snd-col body2 bolder" scope="col">
            {{ 'directory.requests_recap.entityValue' | translate }}
          </th>
        </tr>
      </thead>
      <tbody class="util-el-surface">
        <tr *ngFor="let modification of modificationsRequests; let i = index">
          <td class="first-col body2">
            <ng-container *ngIf="modification.fieldTitle && modification.field.type != 'LinkedChoice'">
              {{ modification.fieldTitle[currentLang] }}
            </ng-container>
            <ng-container *ngIf="modification.field.type === 'LinkedChoice'">
              {{ modification.fieldTitle }}
            </ng-container>
            <!-- temporary solution time to get name in the config widget -->
            <ng-container *ngIf="!modification.fieldTitle && modification.field_id.includes('Name')">
              {{ 'directory.requests_recap.entityName' | translate }}
            </ng-container>
            <ng-container *ngIf="!modification.fieldTitle && !modification.field_id.includes('Name')">
              Logo
            </ng-container>
          </td>
          <td class="snd-col body2" *ngIf="!showImage(modification)">
            {{ modification.readableValue }}
          </td>
          <td class="snd-col body2" *ngIf="showImage(modification)">
            <img [src]="modification.readableValue" alt=""/>
          </td>
          <td class="third-col body2">
            <div class="buttons">
              <button mat-icon-button
                  (click)="editModification(modification, i)"
                  [matTooltip]="'directory.requests_recap.edit' | translate">
                <mat-icon>edit</mat-icon>
              </button>
              <button mat-icon-button
                  (click)="deleteModification(modification, i)"
                  [matTooltip]="'directory.requests_recap.delete' | translate">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <h3 class="body1 bold mt-4">
      {{ 'directory.contact.your_infos' | translate }}
    </h3>
    <form [formGroup]="personnalInfosForm">
      <div class="form-fields-container">
        <mat-form-field appearance="alternate-fill bordered">
          <mat-label>
            {{ 'directory.contact.your_name' | translate }}*
          </mat-label>
          <input matInput
            formControlName="name"
            type="text">
        </mat-form-field>

        <mat-form-field appearance="alternate-fill bordered" class="ml-3">
          <mat-label>
            {{ 'directory.contact.your_email' | translate }}*
          </mat-label>
          <input matInput
            formControlName="email"
            type="email">
        </mat-form-field>

        <mat-form-field appearance="alternate-fill bordered" class="ml-3">
          <mat-label>
            {{ 'directory.contact.your_phone' | translate }}
          </mat-label>
          <input matInput
            formControlName="phone"
            type="tel">
        </mat-form-field>
        <button class="sendPersonnalInfosForm" mat-raised-button color="primary" [disabled]="personnalInfosForm.invalid" (click)="validate()"
          >{{ 'directory.requests.header.send' | translate }}
        </button>
      </div>

      <p class="caption1 medium my-3">
        {{ 'directory.contact.rgpd' | translate }}
      </p>
    </form>
  </div>
</div>
