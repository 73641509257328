import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { DirectoryAddRequestService } from '../../services/directory-add-request/directory-add-request.service';
import { DirectoryConfig } from 'src/app/directory/models/directory-config.model';

@Component({
  selector: 'app-directory-add-request-form',
  templateUrl: './directory-add-request-form.component.html',
  styleUrls: ['./directory-add-request-form.component.scss']
})
export class DirectoryAddRequestFormComponent implements OnInit {
  public currentLang: string = 'fr';
  public selectedCommunity: any;
  public showForm: boolean = false;

  @Input() widgetConfig: DirectoryConfig;

  constructor(
    private translate: TranslateService,
    public directoryAddRequestService: DirectoryAddRequestService,
  ) {}

  ngOnInit(): void {
    this.currentLang = this.translate.currentLang;

    this.directoryAddRequestService.communityForm.get('community').valueChanges.subscribe((selectedCommunity) => {
      this.selectedCommunity = selectedCommunity;
      this.showForm = true;
    });

    // Retrigger community init, this was not listening on in first init
    this.directoryAddRequestService.communityForcePatch();
  }
}