<form [formGroup]="editEntityFieldForm">
  <app-entity-card
    *ngFor="let entityId of fieldCodeValue"
    [entityData]="getNewEntityData(entityId)"
    [entityId]="entityId"
    [deletable]="true"
    (delete)="delete(entityId)"></app-entity-card>
  <mat-form-field appearance="outline" class="entity-id-holder">
    <input matInput
      formControlName="fieldCode"
      type="text">
  </mat-form-field>

  <button mat-stroked-button
      (click)="chooseEntity()"
      class="select-entities-button">
    {{ (multiple ? 'directory.findEntities.selectMultipleEntities' : 'directory.findEntities.selectOneEntity') | translate }}
  </button>
</form>
