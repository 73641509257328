<!-- <pre>{{fields | json}}</pre> -->
<div class="dialog-header">
    <h1>{{data.title}}</h1>
    <div class="flex-spacer"></div>
    <button mat-icon-button (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

<ng-container *ngFor="let field of currentAdbFields | orderByImpure: 'order'">
  <!-- <p>{{field | json}}</p> -->
  <ng-container *ngIf="field">
    <app-directory-entity-field
      [field]="field"
      [fieldValue]="fieldValues[field.id]"
      [class]="'field--'+field.id+' dialogAdb'"
    >
    </app-directory-entity-field>
  </ng-container>
</ng-container>
