<div class="directory-header-wrapper" [ngClass]="widgetConfig?.style.isMainColorContrastBlack ? 'util-el-placeholder-second' : 'util-el-placeholder-main'" [style.backgroundColor]="widgetConfig?.style.mainColor">
  <div class="directory-header-title">
    <img class="partner-logo" *ngIf="widgetConfig?.header?.logo" [src]="widgetConfig?.header?.logo" height="70" [alt]="widgetConfig?.name + ' logo'">
    <h1 class="util-font-color">{{widgetConfig?.header?.title[currentLang]}}</h1>
  </div>
  <div class="flex-spacer"></div>
  <div class="directory-header-actions">

  </div>
</div>
