import { Component, Input, OnInit } from '@angular/core';
import { DirectoryAddRequestService } from 'src/app/directory/services/directory-add-request/directory-add-request.service';
import { AddField } from '../add-field.model';

@Component({
  selector: 'app-add-field-boolean',
  templateUrl: './add-field-boolean.component.html',
  styleUrls: ['./add-field-boolean.component.scss']
})
export class AddFieldBooleanComponent extends AddField  implements OnInit {
  @Input() tripleState: boolean = false;

  constructor(public directoryAddRequestService: DirectoryAddRequestService) {
    super(directoryAddRequestService);
  }

  ngOnInit(): void {
  }

}
