import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { CONSTANTS } from 'src/environments/constants';
import { AddField } from '../add-field.model';
import { DirectoryConfig } from 'src/app/directory/models/directory-config.model';
import { FieldType } from 'src/app/directory/models/fields/field.model';

import { DirectoryAddRequestService } from 'src/app/directory/services/directory-add-request/directory-add-request.service';
import { DirectorySharedDataService } from 'src/app/directory/services/directory-shared-data.service';
import { DirectoryUtilsService } from 'src/app/directory/services/directory-utils/directory-utils.service';
import { DirectoryEmbedRequestService } from 'src/app/directory/services/directory-embed-request/directory-embed-request.service';

@Component({
  selector: 'app-add-field-list',
  templateUrl: './add-field-list.component.html',
  styleUrls: ['./add-field-list.component.scss']
})
export class AddFieldListComponent extends AddField implements OnInit, OnDestroy {
  public CONSTANTS = CONSTANTS;
  public selectSubscription: Subscription[] = [];

  public listPossibilities = [];
  public filteredListPossibilities = [];
  public searchQuery: string = null;
  public eventsIdList: string[] = [];
  public valuesList: any[] = [];
  public valuesIdList: string[] = [];
  public selectedValue: string;

  @Input() multiple: boolean = false;
  @Input() isSubrequest: boolean = false;
  @Input() config: DirectoryConfig;
  @Input() elementData: any[];
  @Output() newAdbObject = new EventEmitter();

  constructor(
    public directoryAddRequestService: DirectoryAddRequestService,
    private directoryEmbedRequestService: DirectoryEmbedRequestService,
    private directoryStore: DirectorySharedDataService,
    private utils: DirectoryUtilsService
  ) {
    super(directoryAddRequestService);
  }

  ngOnInit(): void {
    this.selectSubscription.push(this.directoryStore.findList(this.field.range).pipe(
      map((list: any) => {
        list.elements = this.utils.orderList(list.elements)[0];
        if(list && list.elements && this.field.availableValues) {
          let filteredList = list.elements.filter(
            // Only keep list element if found in availableValue
            x => this.field.availableValues.map(availableValue => availableValue.key).includes(x.element_id)
          );
          return filteredList;
        }
        else { return list.elements }
      })
    )
    .subscribe((list: any) => {
      if (this.formGroup.get(this.field.code).value != null) {
        if (this.multiple) {
          this.valuesIdList = this.formGroup.get(this.field.code).value;
          this.valuesList = list.filter( x =>  this.valuesIdList.includes(x.element_id) );
        } else {
          this.selectedValue = this.formGroup.get(this.field.code).value;
        }
      }
      this.listPossibilities = list;
      this.filteredListPossibilities = list;
    }));

    // Listen to any changes on embedRequest service concerning new added elements
    this.selectSubscription.push(this.directoryEmbedRequestService.getNewElement().subscribe((newElement) => {
      // If new element points to the same list as field's range, add it to listPossibilities
      if (newElement && newElement.listId === this.field.range) {
        // Reconstruct element format
        this.filteredListPossibilities.unshift({
          element_id: newElement.id,
          core_hasListName: newElement.listName,
          core_hasOrder: 0,
          depth: 0,
          values: newElement.values
        });
      }
    }));
  }

  /** Form */
  onSearch(searchQuery: string) {
    this.filteredListPossibilities = this.listPossibilities.filter((option) =>
      option[CONSTANTS.name].toLowerCase().includes(searchQuery.toLowerCase())
    );
  }

  hasSubrequestAdb(field): boolean {
    if (this.isNotAddRequestException(field.range)) {
      // Check if there are any configured ADB in subrequests with field ADB
      //    OR
      // If add requests has been activated AND field range points to the concerned ADB
      if (this.config.addSubRequests?.subResourceList.some((resource) => resource.id === field.range) ||
          (this.config.addRequests?.hasAddRequestsPerEmbeddedForm && this.config.concernedAssociatedDB === field.range))
          return true;
      return false;
    }
    return false;
  }

  isNotAddRequestException(fieldRange): boolean {
    let widgetEntityType = this.config.concernedAssociatedDB === "";
    let widgetADBType = this.config.concernedAssociatedDB != "";

    // Context 1 -> widget with add request activated
      // If add request AND subrequests are activated
      //    AND
      // IS NOT a subrequest
      //    AND
      // If field has an ADB
    if (this.config.addRequests?.hasAddRequestsPerEmbeddedForm &&
        this.config.addSubRequests?.hasAddSubRequestsPerEmbeddedForm &&
        (!this.isSubrequest &&
          (fieldRange != null || fieldRange != "")))
      return true;

    // Context 2 -> widget without add request activated AND subrequest activated
      // If entity type, button will appear only if ADB has been configurated in subrequests
      // If adb type, as add request contains widget's form configuration, we don't have any configured fieldsgroup for subrequests
    // IF add request is not activated AND subrequests is activated
    //    AND
    //    IS an entity type
    //        OR
    //    IS an adb type & field range is not equal to concernedAssociatedDB
    else if (!this.config?.addRequests.hasAddRequestsPerEmbeddedForm &&
        this.config?.addSubRequests.hasAddSubRequestsPerEmbeddedForm &&
        (widgetEntityType ||
          (widgetADBType && this.field.range != this.config.concernedAssociatedDB)))
      return true;

    else
      return false;
  }

  getNewElement(event) {
    if (this.field.type === FieldType.UniqueChoiceList) {
      this.selectedValue = event.element_id;
      this.patchOptionValue(event);
    } else {
      this.eventsIdList.unshift(event.element_id);
      this.toggleAdbElement(event);
    }

    // If we are not in the subrequest form & a new element has been selected, send it to embed service
    if (!this.isSubrequest && event.element_id.includes("_:")) {
      this.directoryEmbedRequestService.formatNewElement(event, this.field);
    }
  }

  /** Unique choice list */
  public patchOptionValue(addElement) {
    if ((typeof addElement === "string") && (addElement === "none")) {
      this.formGroup.patchValue({[this.field.code]: null});
    } else {
      this.formGroup.patchValue({[this.field.code]: addElement.element_id});
    }
  }

  public isChecked(element): boolean {
    if (this.formGroup.get(this.field.code).value === element.element_id ||
        (this.formGroup.get(this.field.code).value === null && element === "none"))
      return true;
    return false;
  }

  /** Hierarchical list - multiple & ordered multiple choice list */
  changeElement(event) {
    this.eventsIdList = event.value;
  }

  public toggleAdbElement(addElement: AddElement): void {
    let newValue = this.valuesList;
    newValue = newValue.filter((x) => x.element_id !== addElement.element_id);

    // If we can find the selected element in the eventsList, then it has been selected
    const selected = this.eventsIdList.includes(addElement.element_id);
    if (selected) {
      newValue.push(addElement);
      newValue = this.addElementAndParents(addElement, newValue);
    } else {
      newValue = this.removeElementAndChildren(addElement, newValue);
    }

    this.valuesList = newValue;

    this.valuesIdList = this.valuesList.map((id) => id.element_id);
    this.formGroup.patchValue({[this.field.code]: this.valuesIdList});
  }

  public addElementAndParents(addElement: AddElement, newValue) {
    if (addElement) {
      newValue = newValue.filter((x) => x.element_id !== addElement.element_id);
      newValue.push(addElement);

      if (addElement.core_hasParentValue != undefined && addElement.core_hasParentValue['element_id']) {
        let parentAddElement = this.listPossibilities.find((x) => x.element_id === addElement.core_hasParentValue['element_id']);
        newValue = this.addElementAndParents(parentAddElement, newValue);
      }
    }
    return newValue;
  }

  public removeElementAndChildren(addElement: AddElement, newValue) {
    if (addElement) {
      newValue = newValue.filter((x) => x.element_id !== addElement.element_id);

      this.listPossibilities.forEach((child) => {
        if (child.core_hasParentValue && child.core_hasParentValue['element_id'] === addElement.element_id) {
          newValue = this.removeElementAndChildren(child, newValue);
        }
      }) ;
    }
    return newValue;
  }

  ngOnDestroy(): void {
    this.selectSubscription.forEach((subs) => subs.unsubscribe());
  }
}

export interface AddElement {
  element_id: string;
  score: number;
  core_hasParentValue?: string;
  values?: any[]; // Values from subrequestForm
}

export interface NewAdbElement {
  id: string;
  listId?: string;
  listName?: string;
  parentUUID?: string;
  values: Values[];
}
interface Values {
    field_id: string;
    value: string | string[];
}
