import { DirectorySearchService } from './../../../services/directory-search/directory-search.service';
import { Component, Input, OnInit } from '@angular/core';
import { FieldTypes } from 'src/app/directory/models/fields/field.model';
import { CONSTANTS } from 'src/environments/constants';
import { DirectoryResultsStoreService } from 'src/app/directory/services/directory-results-store/directory-results-store.service';
import { DirectorySharedDataService } from 'src/app/directory/services/directory-shared-data.service';
import { DirectoryConfig } from 'src/app/directory/models/directory-config.model';

@Component({
  selector: 'app-entity-adb-element',
  templateUrl: './entity-adb-element.component.html',
  styleUrls: ['./entity-adb-element.component.scss']
})
export class EntityAdbElementComponent implements OnInit {
  public CONSTANTS = CONSTANTS;
  public fieldTypes = FieldTypes;
  public widgetConfig: DirectoryConfig;

  public currentElementValues;

  @Input() value: string;
  @Input() range: string;
  @Input() elementId: string;
  @Input() externalElement: boolean;

  constructor(
    private directorySearch: DirectorySearchService,
    private resultsStore: DirectoryResultsStoreService,
    private directoryStore: DirectorySharedDataService,

  ) { }

  ngOnInit(): void {
    this.directoryStore.getDirectoryConfig().subscribe((config: DirectoryConfig) => {
      this.widgetConfig = config;
    });
    this.directorySearch.getAdbElement(this.range, this.elementId).subscribe(
      (element) => {
        this.currentElementValues = element;
      }
    )
  }

  openElementDialog(): void {
    this.resultsStore.setFocusedElement({
      id: this.elementId,
      range: this.range,
      isMain: this.widgetConfig.isFocusOnAssociatedDB && this.widgetConfig.concernedAssociatedDB === this.range ? true : false
    });
    this.resultsStore.setFocusedElementData(this.value);
    this.resultsStore.setOpenedElement({
      id: this.elementId,
      range: this.range,
      isMain: this.widgetConfig.isFocusOnAssociatedDB && this.widgetConfig.concernedAssociatedDB === this.range ? true : false
    });
    this.resultsStore.setOpenedElementData(this.value);
  }

}
