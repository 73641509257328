import { Injectable } from "@angular/core";

import { FieldType } from '../../models/fields/field.model';
import { Observable, ReplaySubject } from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class DirectoryEmbedRequestService {
    public newObjectsRequest: NewObjectRequest = {};
    public subrequestsCounter: number = 0;

    public concernedFieldsEntitiesList: any[] = [];
    public concernedFieldsElementsList: any[] = [];
    public newEntitiesList: NewEntity[] = [];
    public newElementsList: NewElement[] = [];

    private newEntityObs = new ReplaySubject<NewEntity>();
    private newElementObs = new ReplaySubject<NewElement>();

    public getNewEntity(): Observable<NewEntity> {
        return this.newEntityObs.asObservable();
    }
    public updateNewEntity(newEntity): void {
      this.newEntityObs.next(newEntity);
    }

    public getNewElement(): Observable<NewElement> {
        return this.newElementObs.asObservable();
    }
    public updateNewElement(newElement): void {
      this.newElementObs.next(newElement);
    }

    public getSubrequestsCounter(): number {
        return this.subrequestsCounter++;
    }

    public formatNewEntity(allEntities, concernedField, interestAreas) {
        let index: number = this.concernedFieldsEntitiesList.findIndex(field => field.id === concernedField.id);
        
        // If UEL/MEL, the object received is an array of objects
        // Check if it's a new selection of entities or a selection of an existant entity (UEL)
        if (index != -1 ||
                (typeof allEntities === "string" && allEntities.includes("_:"))) {
            this.concernedFieldsEntitiesList.forEach((field, i) => {
                if (field.id === concernedField.id) {
                    this.concernedFieldsEntitiesList.splice(i, 1);
                    this.newEntitiesList.splice(i, 1);
                }
            });
        }
    
        // Add new entity to new entities list -> careful, do not accept existant entities
        if ((typeof allEntities === "object" && allEntities != null) &&
                allEntities.some(entity => entity.entity_id && entity.entity_id.includes("_:"))) {
            // We'll add only new entities
            allEntities.forEach((newEntity) => {
                if (newEntity && newEntity.entity_id.includes("_:") &&
                        !this.newEntitiesList.some(e => e.id === newEntity.entity_id)) {
                    let formattedEntity: NewEntity = {
                        id: newEntity.entity_id,
                        community: newEntity.selectedCommunity,
                        interest_areas: interestAreas,
                        values: this.formatValues(newEntity, concernedField.type)
                    }
                    this.concernedFieldsEntitiesList.push(concernedField);
                    this.newEntitiesList.push(formattedEntity);

                    // Update list for all listeners
                    this.updateNewEntity(formattedEntity);
                }
            });
        }
    }

    public formatNewElement(newElement, concernedField) {
        let index: number = this.concernedFieldsElementsList.findIndex(field => field.id === concernedField.id);

        // If field is already in the list & UCL type -> accept only the last recieved element
        //      OR
        // If the new element has been unselected (UCL/MCL types)
        if ((index != -1 && concernedField.type === FieldType.UniqueChoiceList) ||
                (typeof newElement === "string" && newElement.includes("_:"))) {
            this.concernedFieldsElementsList.splice(index, 1);
            this.newElementsList.splice(index, 1);
        }
        
        if (typeof newElement === "object" && newElement != null) {
            let formattedElement: NewElement = {
                id: newElement.element_id,
                listId: concernedField.range,
                listName: newElement["core_hasListName"],
                values: this.formatValues(newElement, concernedField.type)
            }
            this.concernedFieldsElementsList.push(concernedField);
            this.newElementsList.push(formattedElement);

            // Update list for all listeners
            this.updateNewElement(formattedElement);
        }
    }

    formatValues(object, type) {
        let values: Values[] = [];
        for (const [key, value] of Object.entries(object.values)) {
            if (value != null && key != "core_hasListName") {
                // Use old format for MCL and MEL types
                let formattedValue;
                if (Array.isArray(value) && type === FieldType.MultipleEntityLink)
                    formattedValue = this.makeEntityValues(value);
                else if (Array.isArray(value))
                    formattedValue = this.makeElementValues(value);
                else
                    formattedValue = value as string;

                values.push({
                    field_id: key,
                    value: formattedValue
                });
            }
        }

        return values;
    }
    makeElementValues(elements) {
        let result = [];
        elements.forEach(element => {
            result.push({element_id: element})
        });

        return result;
    }
    makeEntityValues(entities) {
        let result = [];
        entities.forEach(entity => {
          result.push({entity_id: entity})
        });

        return result;
    }

    public prepareNewObjectsForValidationDialog() {
        this.newObjectsRequest = {
            new_entities: this.newEntitiesList,
            new_associated_data: this.newElementsList
        }
    }
}

export interface NewObjectRequest {
    new_entities?: NewEntity[];
    new_associated_data?: NewElement[];
}
interface NewEntity {
    id: string;
    community: string;
    interest_areas: string[];
    values: Values[];
}
interface NewElement {
    id: string;
    listId: string;
    listName: string;
    parentUUID?: string;
    values: Values[];
}
interface Values {
    field_id: string;
    value: any | any[];
}