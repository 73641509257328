import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CONSTANTS } from 'src/environments/constants';

import { DirectoryAddRequestService } from 'src/app/directory/services/directory-add-request/directory-add-request.service';

import { FieldType } from '../../../../models/fields/field.model';
import { LinkedChoiceField } from 'src/app/directory/models/fields/linked-choice-field.model';

@Component({
    selector: 'app-add-field-linked-choice',
    templateUrl: './add-field-linked-choice.component.html',
    styleUrls: ['./add-field-linked-choice.component.scss']
})
export class AddFieldLinkedChoiceComponent implements OnChanges {
    public FieldType = FieldType;
    public CONSTANTS = CONSTANTS;

    public selectedValue: any;
    public allElements: any[];
    public availableElements: any[];
    public filteredList: any[];
    
    @Input('list') elementsList: any[];
    @Input('field') field: LinkedChoiceField;
    @Input('value') value: any;
    @Input('parent') parent: any;
    
    @Output() valueToShare = new EventEmitter();

    constructor(
        public directoryAddRequestService: DirectoryAddRequestService,
    ) {}
    
    ngOnChanges(changes: SimpleChanges) {

        if (this.elementsList && this.elementsList.length > 0) { // Check if the list has elements

            if (this.value) {
                this.selectedValue = this.value.element_id
            } else {
                this.selectedValue = null
            }


            // Give a depth level to each element
            this.assignDepthLevel();
            // Get field's value
            this.getFieldValue(this.field, this.value, this.parent);

            if (this.value && changes.value.firstChange) { // emit for initialisation
                let elToSend = this.allElements.find((x) => x.element_id === this.selectedValue);
                this.value = elToSend // to have complete element, not juste element_id property
        
                this.valueToShare.emit({
                    selectedValue: elToSend, 
                    selectedField: this.field
                });
            }            
        }        
    }

    /** Elements list */
    assignDepthLevel() {
        let hashArr = {};
        hashArr['undefined'] = [];
        this.elementsList.forEach((element) => {
            if ('core_hasParentValue' in element) {
                if (!(element.core_hasParentValue.element_id in hashArr)) hashArr[element.core_hasParentValue.element_id] = [];
                hashArr[element.core_hasParentValue.element_id].push(element);
            } else {
                hashArr['undefined'].push(element);
            }
        });
        // List of elements with depth param
        this.allElements = this.hierarchySort(hashArr, 'undefined', [], 0);
    }
    hierarchySortFunc(a, b) {
        return a.core_hasOrder - b.core_hasOrder;
    }
    hierarchySort(hashArr, key, result, depth) {
        if (!(key in hashArr)) return;
        let arr = hashArr[key].sort(this.hierarchySortFunc);
        for (const item of arr) {
            item.depth = depth;
            result.push(item);
            this.hierarchySort(hashArr, item.element_id, result, depth + 1);
        }
        return result;
    }

    /** Get values for each field */
    getFieldValue(field, value, parent) {
        // Do field have a value?
        if (value) {
            if (value.core_hasParentValue) {
                // It's a child field with value. List elements (parents and siblings) with >= current depth and same parent id
                this.availableElements = this.allElements.filter((element) => 
                    (field.ancestry.length > element.depth) ||
                    ((field.ancestry.length === element.depth) &&
                        (value.core_hasParentValue.element_id === element.core_hasParentValue.element_id))
                );
            } else {
                // It's a root field with value. List elements with depth 0
                this.availableElements = this.allElements.filter((element) => element.depth === 0);
            }
        } else {
            // Is it a root or a child field?
            if (field.ancestry.length > 0) {
                // It's a child field without value. Do field's parent have a value?
                if (parent) {
                    // Parent has a value (otherwise it's a root field). List elements with parent id = element parent id
                    this.availableElements = this.allElements.filter((element) => this.searchElementsToAdd(field.ancestry.length, element, parent));
                } else {
                    // No field has a value. List all element with depth >= element.depth
                    this.availableElements = this.allElements.filter((element) => field.ancestry.length >= element.depth);
                }
            } else {
                // It's a root field without value. List elements with depth 0
                this.availableElements = this.allElements.filter((element) => element.depth === 0);
            }
        }
        this.filteredList = this.availableElements;
    }
    searchElementsToAdd(depth, element, parent) {
        if (depth < element.depth) return false;
        if (parent.element_id === element.element_id) return true; // Add parent
        if (this.isParentOfMyParent(element, parent) || this.isChildrenOfMyParent(element, parent)) return true;
        return false;
    }
    isParentOfMyParent(element, parent): boolean {
        if (parent != undefined && parent.core_hasParentValue) {
             if (element.element_id === parent.core_hasParentValue.element_id) { // It's a parent's parent
                return true;
            } else {
                let searchParent = this.allElements.find((x) => x.element_id === parent.core_hasParentValue.element_id);
                return this.isParentOfMyParent(element, searchParent);
            }
        } else {
            return false;
        }
    }
    isChildrenOfMyParent(element, parent): boolean {
        if (element.core_hasParentValue) {
            if (element.core_hasParentValue.element_id === parent.element_id) { //It's a child of "parent"
                return true;
            } else {
                let searchChild = this.allElements.find((x) => x.element_id === element.core_hasParentValue.element_id);
                return this.isChildrenOfMyParent(searchChild, parent);
            }
        } else { // It's not a child
            return false;
        }
    }

    onFieldChange(newValue, selectedField) {
        this.selectedValue = newValue.value;
        let elToSend = this.allElements.find((x) => x.element_id === this.selectedValue);
        
        this.valueToShare.emit({
            selectedValue: elToSend ? elToSend : null, // to avoid undefind
            selectedField
        });
    }

    onSearch(searchQuery: string) {
        this.filteredList = this.availableElements.filter((element) => element.core_hasListName.toLowerCase().includes(searchQuery.toLowerCase()));
    }
}