import { Component, OnInit, Input } from '@angular/core';
import { DirectorySharedDataService } from 'src/app/directory/services/directory-shared-data.service';
import { DirectoryConfig } from 'src/app/directory/models/directory-config.model';
import { DirectoryEntitySummary } from 'src/app/directory/models/directory-entity-summary.model';
import { DirectoryResultsStoreService } from '../../services/directory-results-store/directory-results-store.service';
import { concatMap } from 'rxjs/operators';

@Component({
  selector: 'app-directory-summary',
  templateUrl: './directory-summary.component.html',
  styleUrls: ['./directory-summary.component.scss'],
})
export class DirectorySummaryComponent implements OnInit {
  public widgetConfig: DirectoryConfig;
  public hasMapDisplayed: boolean;
  public ready: number = 0;
  public trueSize: number;
  @Input() summaryData: DirectoryEntitySummary;
  @Input() vScrollSize: number;

  constructor(
    private resultsStore: DirectoryResultsStoreService,
    private directoryData: DirectorySharedDataService
  ) {}

  ngOnInit(): void {
    if (this.summaryData) {
//       console.log(this.summaryData)
      if (this.ready < 2) this.ready++;
    }
    this.directoryData.getDirectoryConfig().
    pipe(
      concatMap( (config) => {
        this.widgetConfig = config;
        if (this.widgetConfig?.layout?.hasThumbnail) {
          this.trueSize = 190;
        } else {
          this.trueSize = this.vScrollSize;
        }
        return this.directoryData.getHasMapDisplayed();})
    ).subscribe(
      (state) => {this.hasMapDisplayed = state; if (this.ready < 2) this.ready++;}
    );
  }

  public openPreviewOnMap(id: string): void {
    if (this.hasMapDisplayed) {
//       console.log("openPreviewOnMapSummaryComp",id)
      this.resultsStore.setFocusedElement({
        id: id,
        range: this.widgetConfig.isFocusOnAssociatedDB && this.widgetConfig.concernedAssociatedDB ? this.widgetConfig.concernedAssociatedDB : null,
        isMain: true
      });
      this.resultsStore.setFocusedElementData(this.summaryData);
    }
  }

  public closePreviewOnMap(): void {
    if (this.hasMapDisplayed) {
      this.resultsStore.setFocusedElement(null);
      this.resultsStore.setFocusedElementData(null);
    }
  }

  public focusOnEntity(id: string): void {
    this.resultsStore.setOpenedElement({
      id: id,
      range: this.widgetConfig.isFocusOnAssociatedDB && this.widgetConfig.concernedAssociatedDB ? this.widgetConfig.concernedAssociatedDB : null,
      isMain: true
    });
    this.resultsStore.setOpenedElementData(this.summaryData);
  }


  // can't use simple quote then encodeURI for backgroundImage url
  public urlEnc(url: string) {
      return encodeURI(url);
  }

}
