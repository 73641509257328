<div class="directory-field-for-request-wrapper">
  <div class="header-dialog headline6 bold util-el-background util-border-bottom-1 pb-3 mb-4">
    <span>
      {{ 'directory.requests.title' | translate }}
    </span>
    <div class="flex-spacer"></div>
    <button mat-button mat-dialog-close><mat-icon>close</mat-icon></button>
  </div>

  <form [formGroup]="modificationRequestForm" class="dialog-form" *ngIf="field">
    <ng-container [ngSwitch]="field.type">
      <ng-container *ngSwitchCase="FieldType.MultipleChoiceList">
        <ng-container *ngTemplateOutlet="lm"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="FieldType.ExternalSourceAssociatedDb">
        <ng-container *ngTemplateOutlet="lm"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="FieldType.MultipleChoiceListWithComment">
        <ng-container *ngTemplateOutlet="lm"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="FieldType.OrderedMultipleChoiceList">
        <ng-container *ngTemplateOutlet="lm"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="FieldType.UniqueChoiceList">
        <ng-container *ngTemplateOutlet="lu"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="FieldType.MultipleEntityLink">
        <ng-container *ngTemplateOutlet="ell"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="FieldType.UniqueEntityLink">
        <ng-container *ngTemplateOutlet="el"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="FieldType.Boolean">
        <ng-container *ngTemplateOutlet="boolean"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="FieldType.TripleState">
        <ng-container *ngTemplateOutlet="ts"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="FieldType.URL">
        <ng-container *ngTemplateOutlet="url"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="FieldType.Email">
        <ng-container *ngTemplateOutlet="email"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="FieldType.Image">
        <ng-container *ngTemplateOutlet="image"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="FieldType.Date">
        <ng-container *ngTemplateOutlet="date"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="FieldType.Numeric">
        <ng-container *ngTemplateOutlet="numeric"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="FieldType.Float">
        <ng-container *ngTemplateOutlet="float"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="FieldType.LinkedChoice">
        <ng-container *ngTemplateOutlet="lc"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="FieldType.NonLocText">
        <ng-container *ngTemplateOutlet="text"></ng-container>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <ng-container *ngTemplateOutlet="text"></ng-container>
      </ng-container>
    </ng-container>

    <ng-template #lm>
      <div class="values-comparison">
        <div class="initial-value">
          <p class="body2">
            {{ 'directory.requests.currentValue' | translate }} :
          </p>
          <ul class="entity-field-value">
            <li *ngFor="let value of fieldValue" class="readonly-input-class">
              {{ value[CONSTANTS.name] }}
            </li>
          </ul>
        </div>
        <div class="new-value">
          <p class="body2">
            {{ 'directory.requests.newValue' | translate }} :
          </p>
          <input type="hidden" formControlName="newFieldValue">
          <ul class="lm-elements">
            <li *ngFor="let possibility of listPossibilities">
              <mat-checkbox
                  [checked]="isLmChecked(possibility)"
                  [ngClass]="'depth-'+possibility.depth"
                  (change)="toggleAdbElement(possibility, $event)"
                  class="lm-checkbox"
                  [class.edited]="hasLmBeenEdited(possibility)">
                {{ possibility[CONSTANTS.name] }}
              </mat-checkbox>
            </li>
          </ul>

          <app-add-subrequest-button 
            *ngIf="hasSubrequestAdb(field)"
            class="add-button my-1 mr-3"
            [config]="config" 
            [adbId]="field.range"
            [field]="field"
            (subrequestElement)="getNewElement($event)"></app-add-subrequest-button>
        </div>
      </div>
    </ng-template>

    <ng-template #lu>
      <div class="values-comparison">
        <div class="initial-value">
          <p class="body2">
            {{ 'directory.requests.currentValue' | translate }} :
          </p>
          <p class="readonly-input-class" *ngIf="fieldValue">{{ fieldValue[CONSTANTS.name] }}</p>
        </div>
        <div class="new-value">
          <p class="body2">
            {{ 'directory.requests.newValue' | translate }} :
          </p>
          <mat-radio-group
              *ngIf="listPossibilities"
              class="radio"
              formControlName="newFieldValue">
            <mat-radio-button
                *ngFor="let possibility of listPossibilities"
                [value]="possibility.element_id"
                [ngClass]="'depth-'+possibility.depth"
                [checked]="isLuChecked(possibility)"
                class="lu-radio"
                [class.edited]="hasLuBeenEdited(possibility)">
              {{ possibility[CONSTANTS.name] }}
            </mat-radio-button>
          </mat-radio-group>

          <app-add-subrequest-button 
            *ngIf="hasSubrequestAdb(field)"
            class="add-button my-1 mr-3"
            [config]="config" 
            [adbId]="field.range"
            [field]="field"
            (subrequestElement)="getNewElement($event)"></app-add-subrequest-button>
        </div>
      </div>
    </ng-template>

    <ng-template #boolean>
      <div class="values-comparison">
        <div class="initial-value">
          <p class="body2">
            {{ 'directory.requests.currentValue' | translate }} :
          </p>
          <p class="readonly-input-class">{{ 'fields.boolean.'+fieldValue | translate }}</p>
        </div>
        <div class="new-value">
          <p class="body2">
            {{ 'directory.requests.newValue' | translate }} :
          </p>
          <mat-radio-group formControlName="newFieldValue" class="radio">
            <mat-radio-button
                class="mr-1"
                [checked]="fieldValue === 'true'"
                value="true">
              {{ 'fields.boolean.true' | translate }}
            </mat-radio-button>
            <mat-radio-button
                class="mr-1"
                [checked]="fieldValue === 'false'"
                value="false">
              {{ 'fields.boolean.false' | translate }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </ng-template>

    <ng-template #ts>
      <div class="values-comparison">
        <div class="initial-value">
          <p class="body2">
            {{ 'directory.requests.currentValue' | translate }} :
          </p>
          <p class="readonly-input-class">{{ 'fields.triplestate.'+fieldValue | translate }}</p>
        </div>
        <div class="new-value">
          <p class="body2">
            {{ 'directory.requests.newValue' | translate }} :
          </p>
          <mat-radio-group formControlName="newFieldValue" class="radio">
            <mat-radio-button
                class="mr-1"
                [checked]="fieldValue === 'Yes'"
                value="Yes">
              {{ 'fields.triplestate.Yes' | translate }}
            </mat-radio-button>
            <mat-radio-button
                class="mr-1"
                [checked]="fieldValue === 'No'"
                value="No">
              {{ 'fields.triplestate.No' | translate }}
            </mat-radio-button>
            <mat-radio-button
              class="mr-1"
              [checked]="fieldValue === 'None'"
              value="None">
              {{ 'fields.triplestate.None' | translate }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </ng-template>

    <ng-template #ell>
      <div class="values-comparison">
        <div class="initial-value">
          <p class="body2">
            {{ 'directory.requests.currentValue' | translate }} :
          </p>
          <!--<ul *ngIf="fieldValue" class="entity-field-value">
            <li *ngFor="let entityLink of fieldValue" class="readonly-input-class">
              <app-entity-link [id]="entityLink.element_id"></app-entity-link>
            </li>
          </ul>-->
          <div *ngIf="fieldValue" class="entity-field-value readonly-input-class-list">
            <app-entities-links [fieldValue]="fieldValue"></app-entities-links>
          </div>
          <p *ngIf="!fieldValue" class="mt-1">{{ 'directory.findEntities.noEntitySelected' | translate }}</p>
        </div>
        <div class="new-value">
          <p class="body2">
            {{ 'directory.requests.newValue' | translate }} :
          </p>
          <app-edit-field-entity-link 
              [field]="field" 
              type="text" 
              [multiple]="true" 
              [formGroup]="directoryAddRequestService.subrequestForm"
              [isSubrequest]="false" 
              [config]="config"
              (chosenEntities)="getEntities($event)"></app-edit-field-entity-link>
        </div>
      </div>
    </ng-template>

    <ng-template #el>
      <div class="values-comparison">
        <div class="initial-value">
          <p class="body2">
            {{ 'directory.requests.currentValue' | translate }} :
          </p>
          <div *ngIf="fieldValue" class="readonly-input-class">
            <!--<app-entity-link [id]="fieldValue"></app-entity-link>-->
            <app-entities-links [fieldValue]="fieldValue"></app-entities-links>
          </div>
          <p *ngIf="!fieldValue" class="mt-1">{{ 'directory.findEntities.noEntitySelected' | translate }}</p>
        </div>
        <div class="new-value">
          <p class="body2">
            {{ 'directory.requests.newValue' | translate }} :
            <app-edit-field-entity-link 
                [field]="field" 
                type="text" 
                [formGroup]="directoryAddRequestService.subrequestForm"
                [isSubrequest]="false" 
                [config]="config"
                (chosenEntities)="getEntities($event)"></app-edit-field-entity-link>
        </div>
      </div>
    </ng-template>

    <ng-template #url>
      <div class="values-comparison">
        <div class="initial-value">
          <p class="body2">
            {{ 'directory.requests.currentValue' | translate }} :
          </p>
          <mat-form-field appearance="outline" class="readonly">
            <input matInput
              formControlName="fieldValue" readonly disabled>
          </mat-form-field>
        </div>
        <div class="new-value">
          <p class="body2">
            {{ 'directory.requests.newValue' | translate }} :
          </p>
          <mat-form-field appearance="outline">
            <input matInput
            formControlName="newFieldValue"
            type="url"
            placeholder="http(s)://..."
            pattern="^(https?://)[-a-zA-Z0-9.]{2,256}\.[a-z]{2,22}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)$">
          </mat-form-field>
        </div>
      </div>
    </ng-template>

    <ng-template #email>
      <div class="values-comparison">
        <div class="initial-value">
          <p class="body2">
            {{ 'directory.requests.currentValue' | translate }} :
          </p>
          <mat-form-field appearance="outline" class="readonly">
            <input matInput
              formControlName="fieldValue" readonly disabled>
          </mat-form-field>
        </div>
        <div class="new-value">
          <p class="body2">
            {{ 'directory.requests.newValue' | translate }} :
          </p>
          <mat-form-field appearance="outline">
            <input matInput
              formControlName="newFieldValue"
              type="email"
              placeholder="user@mail.com"
              pattern="^([a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,6})$">
          </mat-form-field>
        </div>
      </div>
    </ng-template>

    <ng-template #image>
      <div class="values-comparison">
        <div class="initial-value" *ngIf="imgFieldValue">
          <p class="body2">
            {{ 'directory.requests.currentValue' | translate }} :
          </p>
          <img [src]="imgFieldValue" class="img-thumbnail" [alt]="modificationRequestForm.value['file-'+field.id].name"/>
          <p><em>
            {{ modificationRequestForm.value['file-'+field.id].name }}
          </em></p>
          <button mat-stroked-button
              class="stroked mr-2 mt-3"
              color="primary"
              (click)="removeImage()">
            {{ 'fields.image.removeImage' | translate }}
          </button>
        </div>
        <div *ngIf="!imgFieldValue" class="new-value">
          <p class="body2">
            {{ 'directory.requests.newValue' | translate }} :
          </p>
          <button mat-stroked-button
              color="primary"
              class="stroked mr-2"
              (click)="imageInput.click()">
            {{ 'fields.image.chooseImage' | translate }}
          </button>
          <input #imageInput
            type="file"
            accept="image/*"
            class="inputfile"
            id="{{ 'inputfile-'+field.id }}"
            (change)="processFile(imageInput)">
          <em>{{ 'fields.image.noImageSelected' | translate }}</em>
        </div>
      </div>
    </ng-template>

    <ng-template #date>
      <div class="values-comparison">
        <div class="initial-value">
          <p class="body2">
            {{ 'directory.requests.currentValue' | translate }} :
          </p>
          <mat-form-field appearance="outline" class="readonly">
            <input matInput
              formControlName="fieldValue"
              [matDatepicker]="dp1" disabled>
            <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
            <mat-datepicker #dp1></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="new-value">
          <p class="body2">
            {{ 'directory.requests.newValue' | translate }} :
          </p>
          <mat-form-field appearance="outline">
            <input matInput
              formControlName="newFieldValue"
              [matDatepicker]="picker"
              (click)="picker.open()">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </ng-template>

    <ng-template #numeric>
      <div class="values-comparison">
        <div class="initial-value">
          <p class="body2">
            {{ 'directory.requests.currentValue' | translate }} :
          </p>
          <mat-form-field appearance="outline" class="readonly">
            <input matInput
              formControlName="fieldValue" readonly disabled>
          </mat-form-field>
        </div>
        <div class="new-value">
          <p class="body2">
            {{ 'directory.requests.newValue' | translate }} :
          </p>
          <mat-form-field appearance="outline">
            <input matInput
              formControlName="newFieldValue"
              type="text"
              pattern="^([1-9]+[0-9]*)$">
          </mat-form-field>
        </div>
      </div>
    </ng-template>

    <ng-template #float>
      <div class="values-comparison">
        <div class="initial-value">
          <p class="body2">
            {{ 'directory.requests.currentValue' | translate }} :
          </p>
          <mat-form-field appearance="outline" class="readonly">
            <input matInput
              formControlName="fieldValue" readonly disabled>
          </mat-form-field>
        </div>
        <div class="new-value">
          <p class="body2">
            {{ 'directory.requests.newValue' | translate }} :
          </p>
          <mat-form-field appearance="outline">
            <input matInput
              formControlName="newFieldValue"
              type="text"
              pattern="^[+-]?(([0-9]*[.]||[0-9]*[,])?[0-9]+)$">
          </mat-form-field>
        </div>
      </div>
    </ng-template>

    <ng-template #lc>
      <div class="values-comparison">
        <div class="initial-value">
          <p class="body2">
            {{ 'directory.requests.currentValue' | translate }} :
          </p>
          <div *ngFor="let linkedField of linkedChoiceCopy" class="readonly-input-class">
            <h3 class="mt-0 mb-2">{{ linkedField.field.name }}</h3>
            <p *ngIf="linkedField.field_value">{{ linkedField.field_value[CONSTANTS.name] }}</p>
          </div>
        </div>
        <div class="new-value">
          <p class="body2">
            {{ 'directory.requests.newValue' | translate }} :
          </p>
          <div *ngFor="let linkedField of linkedChoiceBindingObject">
            <app-linked-choice
              [field]="linkedField.field"
              [value]="linkedField.field_value"
              [parentValue]="linkedField.parent_value"
              [list]="elementsList"
              (valueToShare)="crossOffValue($event)"></app-linked-choice>
          </div>
        </div>
      </div>
    </ng-template>

    <!-- Default -->
    <ng-template #text>
      <div class="values-comparison">
        <div class="initial-value">
          <p class="body2">
            {{ 'directory.requests.currentValue' | translate }} :
          </p>
          <mat-form-field appearance="outline" class="readonly">
            <input matInput
              *ngIf="field.id == '6cb44afb-56e5-4b8b-9b1c-4df8c155d545_hasNationalIdentifier'"
              formControlName="fieldValue" readonly disabled>
            <textarea matInput
              *ngIf="field.id != '6cb44afb-56e5-4b8b-9b1c-4df8c155d545_hasNationalIdentifier'"
              formControlName="fieldValue"
              rows="6"
              readonly disabled></textarea>
          </mat-form-field>
        </div>
        <div class="new-value">
          <p class="body2">
            {{ 'directory.requests.newValue' | translate }} :
          </p>
          <mat-form-field appearance="outline">
            <input matInput
              *ngIf="field.id == '6cb44afb-56e5-4b8b-9b1c-4df8c155d545_hasNationalIdentifier'"
              formControlName="newFieldValue"
              type="text">
            <textarea matInput
              *ngIf="field.id != '6cb44afb-56e5-4b8b-9b1c-4df8c155d545_hasNationalIdentifier'"
              formControlName="newFieldValue"
              rows="6"></textarea>
          </mat-form-field>

          <div *ngIf="this.existSiret && field.id == '6cb44afb-56e5-4b8b-9b1c-4df8c155d545_hasNationalIdentifier'">
            <p class="body2" style="color:red;">
              {{ 'directory.requests.identifierAlreadyUsed' | translate }}: <strong>{{ this.existSiretName }}</strong>
            </p>
            <!-- Only if existing entity is on widget's list -->
            <button mat-stroked-button
                *ngIf="this.directoryAddRequestService.entityDetailsButton"
                color="primary"
                class="mb-3"
                (click)="openShowEntityModal()">
              {{ 'directory.requests.viewEntityDetails' | translate }}
            </button>
            <!-- Only if existing entity is not on widget's list -->
            <button mat-stroked-button
                *ngIf="this.directoryAddRequestService.entityVisibilityRequestButton"
                color="primary"
                class="mb-3"
                (click)="openVisibilityRequestModal()">
              {{ 'directory.requests.notificationVisibility' | translate }}
            </button>
          </div>
        </div>
      </div>
    </ng-template>

    <p class="body2 mt-4">{{ 'directory.requests.comment' | translate }} :</p>
    <mat-form-field appearance="outline">
      <textarea matInput
        formControlName="commentary"
        cdkAutosizeMinRows="4"></textarea>
    </mat-form-field>
  </form>
  <mat-dialog-actions class="d-flex flex-row-reverse">
    <button mat-raised-button
        color="primary"
        class="mb-2"
        (click)="tryUploadThenReturnForm()"
        [disabled]="modificationRequestForm.invalid || this.existSiret">
      {{ 'directory.requests.valid' | translate }}
    </button>
  </mat-dialog-actions>
</div>
