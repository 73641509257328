<div class="directory-filters-wrapper util-el-background">
  <div class="directory-filters-searchbar">
    <form [formGroup]="keywordForm">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'directory.filters.keywords_search' | translate }}</mat-label>
        <input matInput
          type="text" 
          class="form-control" 
          formControlName="keywords" 
          aria-label="Search">
        <mat-icon matPrefix>search</mat-icon>
        <!-- <button mat-button *ngIf="keywordForm.value.keywords" matSuffix mat-icon-button aria-label="Clear" (click)="resetKeywords()">
          <mat-icon>cancel</mat-icon>
        </button> -->
      </mat-form-field>
    </form>
  </div>
  <div class="directory-filters-mainfilters" *ngIf="!isSmallScreen" #mainFilters [appHandleMainFilters]="mainFiltersList.length">
    <ng-container *ngFor="let filter of mainFiltersList | slice:0:nbAvailableSpots">
      <app-directory-filter [filter]="filter" [criteria]="criterias[filter.id]" class="app-directory-filter"></app-directory-filter>
    </ng-container>
  </div>
  <div class="flex-spacer" *ngIf="isSmallScreen"></div>
  <ng-container *ngIf="asideFiltersList.length > 0 || isSmallWithMain">
    <app-directory-all-filters [criteria]="criterias" [isSmall]="isSmallWithMain"></app-directory-all-filters>
  </ng-container>
  <app-directory-legend-dropdown></app-directory-legend-dropdown>
  <div class="directory-filters-fullscreen">
    <button mat-icon-button (click)="toggleFullscreen()">
      <mat-icon>{{isFullscreen ? 'close_fullscreen' : 'open_in_full'}}</mat-icon>
    </button>
  </div>
</div>
