<div class="directory-add-request-wrapper page-wrapper">

  <ng-container *ngIf="widgetConfig">
    <div class="page-header">
      <button mat-button class="mr-3" *ngIf="!widgetConfig.addRequests?.forceAddRequests" (click)="askGoBack()">{{ 'directory.requests.header.back' | translate }}</button>
      <img class="partner-logo" *ngIf="widgetConfig?.header?.logo" [src]="widgetConfig?.header?.logo" height="70" [alt]="widgetConfig?.header?.title[currentLang] + ' logo'">
      <img *ngIf="!widgetConfig?.header?.logo" src="../../../../assets/medias/img/craft-logo.png" alt="Logo Craft" title="Logo Craft">
      <div class="page-title">
        <h1>{{widgetConfig?.header?.title[currentLang]}}</h1>
      </div>
      <button
        *ngIf="directoryAddRequestService.addRequestForm && directoryAddRequestService.communityForm"
        mat-raised-button
        color="primary"
        class="validation-button firstValidButton"
        (click)="openValidationDialog()"
        [disabled]="directoryAddRequestService.addRequestForm.invalid || directoryAddRequestService.communityForm.invalid"
      >
        {{ 'directory.requests.valid' | translate }}
      </button>
    </div>
    <div class="page-core">

      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-4">
            <div>
              <h2 class="body1 bold">{{ 'directory.requests.add_request' | translate }}</h2>
              <p class="admin-comment">{{ widgetConfig?.addRequests?.comment[currentLang] }}</p>
              <p class="required-indicator">* {{ 'directory.requests.requiredFields' | translate }}</p>
            </div>
          </div>
          <div class="col-12 col-lg-8">
            <app-directory-community-select
              [widgetConfig]="widgetConfig"></app-directory-community-select>
            <app-directory-add-request-form
              [widgetConfig]="widgetConfig"></app-directory-add-request-form>
          </div>
        </div>
        <mat-dialog-actions class="justify-content-end">
          <button mat-raised-button
              *ngIf="directoryAddRequestService.addRequestForm && directoryAddRequestService.communityForm"
              color="primary"
              class="validation-button"
              (click)="openValidationDialog()"
              [disabled]="directoryAddRequestService.addRequestForm.invalid || directoryAddRequestService.communityForm.invalid || directoryAddRequestService.existSiret">
            {{ 'directory.requests.valid' | translate }}
          </button>
        </mat-dialog-actions>

      </div>

    </div>
  </ng-container>


</div>
