import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { EllipsisModule } from 'ngx-ellipsis';
import { MaterialModule } from './material.module';
import { IframerComponent } from './components/iframer/iframer.component';

export * from './validation/email-validation';
export * from './validation/phone-validation';

@NgModule({
  declarations: [IframerComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule,
    EllipsisModule,
    MaterialModule,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule,
    EllipsisModule,
    MaterialModule,
  ],
})
export class SharedModule {}
