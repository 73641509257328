import { DirectoryEntitySummary } from './../models/directory-entity-summary.model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DirectoryConfig } from '../models/directory-config.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DirectorySharedDataService {
  public httpHeaders: HttpHeaders;

  public concernedSubject: string;

  public concernedAssociatedDB: string;

  public widgetId: string;

  public widgetLang: string;

  private widgetConfig = new BehaviorSubject(null);

  private hasListDisplayed = new BehaviorSubject(null);

  private hasMapDisplayed = new BehaviorSubject(null);

  private hasLegendDisplayed = new BehaviorSubject(true);

  private resultsList = new BehaviorSubject(null);

  private hasBeenInitialized: boolean = false;

  // private entityId = new BehaviorSubject(null);

  // private focusedElementData = new BehaviorSubject(null);

  // private focusedLinkedElementData = new BehaviorSubject(null);

  // private openedLinkedElementId = new BehaviorSubject(null);

  // private openedEntityId = new BehaviorSubject(null);

  private availableFields = new BehaviorSubject(null);

  constructor(private http: HttpClient) {}

  public getDirectoryConfig(): Observable<DirectoryConfig> {
    return this.widgetConfig.asObservable();
  }

  public getResultsList(): Observable<DirectoryEntitySummary[]> {
    return this.resultsList.asObservable();
  }
  // public getEntityId(): Observable<string> {
  //   return this.entityId.asObservable();
  // }
  // public getFocusedElementData(): Observable<any> {
  //   return this.focusedElementData.asObservable();
  // }
  // public getFocusedLinkedElementData(): Observable<any> {
  //   return this.focusedLinkedElementData.asObservable();
  // }
  // public getOpenedEntityId(): Observable<string> {
  //   return this.openedEntityId.asObservable();
  // }
  // public getOpenedLinkedElement(): Observable<string> {
  //   return this.openedLinkedElementId.asObservable();
  // }
  public getHasListDisplayed(): Observable<boolean> {
    return this.hasListDisplayed.asObservable();
  }

  public getHasMapDisplayed(): Observable<boolean> {
    return this.hasMapDisplayed.asObservable();
  }

  public getHasLegendDisplayed(): Observable<boolean> {
    return this.hasLegendDisplayed.asObservable();
  }

  public getAvailableFields(): Observable<any[]> {
    return this.availableFields.asObservable();
  }

  public isInitialized(): boolean {
    return this.hasBeenInitialized;
  }

  changeWidgetConfig(state: DirectoryConfig) {
    //console.log("changeWidgetConfig")
    this.widgetConfig.next(state);
    this.hasBeenInitialized = true;
  }

  changeListState(state: boolean) {
    this.hasListDisplayed.next(state);
  }

  changeMapState(state: boolean) {
    this.hasMapDisplayed.next(state);
  }

  changeLegendState(state: boolean) {
    this.hasLegendDisplayed.next(state);
  }

  changeResultsList(resultsList: DirectoryEntitySummary[]) {
    this.resultsList.next(resultsList);
  }

  changeAvailableFields(fields: any) {
    //console.log("changeAvailableFields")
    this.availableFields.next(fields);
  }

  public findDirectoryConfig(widgetId: string): Observable<any> {
    return this.http.get(`${environment.apiUri}/widgets/${widgetId}`);
  }

  public setHeaders(key: string) {
    this.httpHeaders = new HttpHeaders().set('Authorization', 'API ' + key);
  }

  public findFields() {
    const headers = this.httpHeaders;
    return this.http.get(`${environment.apiUri}/v5/${this.widgetLang}/fields`, {
      headers,
    });
  }

  public findList(ADBId: string) {
    const headers = this.httpHeaders;
    return this.http.get(`${environment.apiUri}/v5/${this.widgetLang}/associated-data/${ADBId}/elements`, {
      headers,
    });
  }

  public uploadImg(formData: FormData) {
    const headers = this.httpHeaders;
    return this.http.post(`${environment.apiUri}/v5/${this.widgetLang}/image`, formData, {
      headers,
    });
  }

  public findFieldValue(elementId: string, fieldId: string, adb?: string) {
    const headers = this.httpHeaders;
    if(!adb) {
      return this.http.get(
        `${environment.apiUri}/v5/${this.widgetLang}/entities/${elementId}/${fieldId}`,
        {
          headers,
        }
      );
    }
    else {
      return this.http.get(
        // `${environment.apiUri}/v5/${this.widgetLang}/associated-data/${adb}/elements/${elementId}/${fieldId}`, // doesnt exist :'(
        `${environment.apiUri}/v5/${this.widgetLang}/associated-data/${adb}/elements/${elementId}`,
        {
          headers,
        }
      )
      .pipe(
        map(adbElement => {
          return adbElement[fieldId];
        })
      )
    }

  }

  public setAvailableFields() {
    const headers = this.httpHeaders;
    this.http
      .get(`${environment.apiUri}/v5/${this.widgetLang}/fields`, {
        headers,
      })
      .pipe(
        take(1),
        tap((fields) => this.changeAvailableFields(fields))
      )
      .subscribe();
  }

  public contactEntity(contactRequest: any): Observable<any> {
    const headers = this.httpHeaders;
    return this.http.post(`${environment.apiUri}/v5/${this.widgetLang}/entities/contact`,
      contactRequest,
    { headers }
    )
  }

  public createEntity(createRequest: any): Observable<any> {
    const headers = this.httpHeaders;
    return this.http.post(`${environment.apiUri}/v5/${this.widgetLang}/entities/create`,
      createRequest,
    { headers }
    )
  }

  public testSiret(siretId:string, entityId:string) {
    let params = new HttpParams().set('siret',siretId).set('entityId',entityId);
    return this.http.get(`${environment.apiUri}/v5/${this.widgetLang}/entities/existSiret`, 
      { params: params, 
        headers: this.httpHeaders, 
        observe: 'response' });
  }

  // We want the status response (either 200 -> exists or 404 -> doesn't exist)
  public testEntityVisibility(entityId:string) {
    return this.http.get(`${environment.apiUri}/v5/${this.widgetLang}/entities/${entityId}`, 
      { headers: this.httpHeaders, 
        observe: 'response' });
  }

}
