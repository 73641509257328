import { Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DirectoryFieldForAddRequests } from 'src/app/directory/models/directory-config.model';
import { DirectoryAddRequestService } from 'src/app/directory/services/directory-add-request/directory-add-request.service';

export abstract class AddField implements OnInit {
  @Input() formGroup: FormGroup;
  @Input() field?: DirectoryFieldForAddRequests;

  constructor(
    protected directoryAddRequestService: DirectoryAddRequestService
  ) {}

  ngOnInit(): void {}

}
