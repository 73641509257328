<form [formGroup]="filterForm" class="filters-form">
  <ng-container [ngSwitch]="filter.type">
    <ng-container *ngSwitchCase="'UniqueChoiceList'">
      <ng-container *ngTemplateOutlet="list"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'MultipleChoiceList'">
      <ng-container *ngTemplateOutlet="list"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'MultipleChoiceListWithComment'">
      <ng-container *ngTemplateOutlet="list"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'OrderedMultipleChoiceList'">
      <ng-container *ngTemplateOutlet="list"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'LinkedChoice'">
      <ng-container *ngTemplateOutlet="list"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'TripleState'">
      <ng-container *ngTemplateOutlet="triple"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'Boolean'">
      <ng-container *ngTemplateOutlet="boolean"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'Numeric'">
      <ng-container *ngTemplateOutlet="numeric"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'Float'">
      <ng-container *ngTemplateOutlet="float"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'Date'">
      <ng-container *ngTemplateOutlet="date"></ng-container>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <ng-container *ngTemplateOutlet="text"></ng-container>
    </ng-container>
  </ng-container>

  <ng-template #list>
    <mat-form-field appearance="outline">
      <mat-label>{{ translationCompatibilityForOlderWidget(filter.title, currentLang) }}</mat-label>
      <mat-select
          formControlName="filter"
          [multiple]="isMultiple()"
          panelClass="widget-list-filter-mat-select-panel">
        <mat-select-trigger *ngIf="isMultiple() && filterForm?.value.filter?.length > 1">
          <span class="example-additional-selection">
            {{ translationCompatibilityForOlderWidget(filter.title, currentLang) }} ({{filterForm?.value.filter?.length}})
          </span>
        </mat-select-trigger>
        <mat-option
            *ngFor="let listPossibility of listPossibilities"
            [value]="listPossibility.element_id"
            [ngClass]="'depth-'+listPossibility.depth"
            [disabled]="listPossibility.isDisabled">
          <ng-container *ngIf="listPossibility.count">
            <span class="aggregation-count">({{listPossibility.count}}) </span>
          </ng-container>
          {{ listPossibility[CONSTANTS.name] }}
        </mat-option>
      </mat-select>

      <button mat-button
          type="button"
          *ngIf="hasValue()"
          matSuffix mat-icon-button aria-label="Clear"
          (click)="resetField($event)">
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-form-field>
  </ng-template>

  <ng-template #triple>
    <mat-form-field appearance="outline">
      <mat-label>{{ translationCompatibilityForOlderWidget(filter.title, currentLang) }}</mat-label>
      <mat-select
          formControlName="filter"
          panelClass="widget-list-filter-mat-select-panel">
        <mat-option value="Yes">
          {{ 'fields.triplestate.Yes' | translate }}
        </mat-option>
        <mat-option value="No">
          {{ 'fields.triplestate.No' | translate }}
        </mat-option>
        <mat-option value="None">
          {{ 'fields.triplestate.None' | translate }}
        </mat-option>
      </mat-select>

      <button mat-button
          type="button"
          *ngIf="hasValue()"
          matSuffix mat-icon-button aria-label="Clear"
          (click)="resetField($event)">
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-form-field>
  </ng-template>

  <ng-template #boolean>
    <mat-slide-toggle
        formControlName="filter"
        appearance="vertical"
        class="mt-1" 
        [class.notActive]="booleanNot">
      {{ translationCompatibilityForOlderWidget(filter.title, currentLang) }}
    </mat-slide-toggle>
    <button mat-button
      type="button"
      *ngIf="hasValue()"
      matSuffix mat-icon-button aria-label="Clear"
      (click)="resetField($event)">
    <mat-icon>cancel</mat-icon>
  </button>
  </ng-template>

  <ng-template #numeric>
    <div [class.row]="filter.isMainFilter && !isSmall">
      <mat-form-field appearance="outline" [class.col]="filter.isMainFilter && !isSmall">
        <mat-label>{{ translationCompatibilityForOlderWidget(filter.title, currentLang) }}</mat-label>
        <input matInput
          formControlName="filter"
          type="text"
          pattern="^([1-9]+[0-9]*)$">
        <button mat-button
            type="button"
            *ngIf="hasValue()"
            matSuffix mat-icon-button aria-label="Clear"
            (click)="resetField($event)">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field
          [ngClass]="{'mt-negative': !filter.isMainFilter || isSmall,
                      'col-3 small-operators' : filter.isMainFilter && !isSmall}">
        <mat-label *ngIf="!filter.isMainFilter || isSmall">{{ 'filters.constraintOperatorLabel' | translate }}</mat-label>
        <mat-select
            formControlName="operator"
            (selectionChange)="operatorChanged($event)">
          <mat-option
              *ngFor="let constraintOperator of constraintOperators"
              [value]="constraintOperator"
              [class.text-center]="filter.isMainFilter && !isSmall">
            <span class="op-icon" *ngIf="constraintOperator === 'equalTo'">&#61;</span>
            <span class="op-icon" *ngIf="constraintOperator === 'greaterThan'">&#62;</span>
            <span class="op-icon" *ngIf="constraintOperator === 'greaterThanOrEqualTo'">&#8805;</span>
            <span class="op-icon" *ngIf="constraintOperator === 'lessThan'">&#60;</span>
            <span class="op-icon" *ngIf="constraintOperator === 'lessThanOrEqualTo'">&#8804;</span>
            <span class="op-icon" *ngIf="constraintOperator === 'notEqualTo'">&#8800;</span>
            <span *ngIf="!filter.isMainFilter || isSmall"> {{ 'filters.constraintOperators.' + constraintOperator | translate }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </ng-template>

  <ng-template #float>
    <div [class.row]="filter.isMainFilter && !isSmall">
      <mat-form-field appearance="outline" [class.col]="filter.isMainFilter && !isSmall">
        <mat-label>{{ translationCompatibilityForOlderWidget(filter.title, currentLang) }}</mat-label>
        <input matInput
          formControlName="filter"
          type="text"
          pattern="^[+-]?(([0-9]*[.]||[0-9]*[,])?[0-9]+)$">
        <button mat-button
            type="button"
            *ngIf="hasValue()"
            matSuffix mat-icon-button aria-label="Clear"
            (click)="resetField($event)">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field
          [ngClass]="{'mt-negative': !filter.isMainFilter || isSmall,
                      'col-3 small-operators' : filter.isMainFilter && !isSmall}">
        <mat-label *ngIf="!filter.isMainFilter || isSmall">{{ 'filters.constraintOperatorLabel' | translate }}</mat-label>
        <mat-select
            formControlName="operator"
            (selectionChange)="operatorChanged($event)">
          <mat-option
              *ngFor="let constraintOperator of constraintOperators"
              [value]="constraintOperator"
              [class.text-center]="filter.isMainFilter && !isSmall">
            <span class="op-icon" *ngIf="constraintOperator === 'equalTo'">&#61;</span>
            <span class="op-icon" *ngIf="constraintOperator === 'greaterThan'">&#62;</span>
            <span class="op-icon" *ngIf="constraintOperator === 'greaterThanOrEqualTo'">&#8805;</span>
            <span class="op-icon" *ngIf="constraintOperator === 'lessThan'">&#60;</span>
            <span class="op-icon" *ngIf="constraintOperator === 'lessThanOrEqualTo'">&#8804;</span>
            <span class="op-icon" *ngIf="constraintOperator === 'notEqualTo'">&#8800;</span>
            <span *ngIf="!filter.isMainFilter || isSmall"> {{ 'filters.constraintOperators.' + constraintOperator | translate }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </ng-template>

  <ng-template #date>
    <div [class.row]="filter.isMainFilter && !isSmall">
      <mat-form-field appearance="outline" class="datepicker" [class.col]="filter.isMainFilter && !isSmall">
        <mat-label>{{ translationCompatibilityForOlderWidget(filter.title, currentLang) }}</mat-label>
        <input matInput
          formControlName="filter"
          [matDatepicker]="picker"
          (click)="picker.open()">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <button mat-button
            type="button"
            *ngIf="hasValue()"
            matSuffix mat-icon-button aria-label="Clear"
            (click)="resetField($event)">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field
          [ngClass]="{'mt-negative': !filter.isMainFilter || isSmall,
                      'col-3 small-operators' : filter.isMainFilter && !isSmall}">
        <mat-label *ngIf="!filter.isMainFilter || isSmall">{{ 'filters.constraintOperatorLabel' | translate }}</mat-label>
        <mat-select
            formControlName="operator"
            (selectionChange)="operatorChanged($event)">
          <mat-option
              *ngFor="let constraintOperator of constraintOperators"
              [value]="constraintOperator"
              [class.text-center]="filter.isMainFilter && !isSmall">
            <span class="op-icon" *ngIf="constraintOperator === 'equalTo'">&#61;</span>
            <span class="op-icon" *ngIf="constraintOperator === 'greaterThan'">&#62;</span>
            <span class="op-icon" *ngIf="constraintOperator === 'greaterThanOrEqualTo'">&#8805;</span>
            <span class="op-icon" *ngIf="constraintOperator === 'lessThan'">&#60;</span>
            <span class="op-icon" *ngIf="constraintOperator === 'lessThanOrEqualTo'">&#8804;</span>
            <span class="op-icon" *ngIf="constraintOperator === 'notEqualTo'">&#8800;</span>
            <span *ngIf="!filter.isMainFilter || isSmall"> {{ 'filters.constraintOperators.' + constraintOperator | translate }}</span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </ng-template>

  <ng-template #text>
    <mat-form-field appearance="outline">
      <mat-label>{{ translationCompatibilityForOlderWidget(filter.title, currentLang) }}</mat-label>
      <input matInput type="text" formControlName="filter">

      <button mat-button
          type="button"
          *ngIf="hasValue()"
          matSuffix mat-icon-button aria-label="Clear"
          (click)="resetField($event)">
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-form-field>
  </ng-template>
</form>
