import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { DirectorySharedDataService } from '../../services/directory-shared-data.service';
import { DirectoryConfig } from '../../models/directory-config.model';

@Directive({
  selector: '[appCustomizedFontColor]'
})
export class CustomizedFontColorDirective {
  private directoryConfig: DirectoryConfig;
  private widgetConfigSubscribe = this.directoryData.getDirectoryConfig().subscribe(
    (config: DirectoryConfig) => {
      this.directoryConfig = config;
    }
  );
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private directoryData: DirectorySharedDataService
  ) {
    this.overrideWithCustomColor();
  }

  private overrideWithCustomColor(): void {
    let customColor: string;
    if (this.directoryConfig.style.secondColor) {
      customColor = this.directoryConfig.style.secondColor;
    } else {
      customColor = 'black';
    }
    this.renderer.setStyle(this.el.nativeElement, 'color', customColor);
  }

}
