<div [class.row]="hasSubrequestAdb(field)">
  <mat-form-field appearance="outline" [class.col]="hasSubrequestAdb(field)">
    <mat-select
        *ngIf="listPossibilities"
        [required]="field.required"
        [multiple]="multiple"
        [value]="multiple ? valuesIdList : selectedValue"
        (selectionChange)="changeElement($event)">
      <!-- Adds selected elements' name in multiple & ordered choice lists -->
      <mat-select-trigger *ngIf="multiple">
        <ng-container *ngIf="valuesList.length > 0">
          <span *ngFor="let val of valuesList; let isLast=last">
            {{ val[CONSTANTS.name] }}{{isLast ? '' : ', '}}
          </span>
        </ng-container>
      </mat-select-trigger>

      <!-- Search functionality -->
      <mat-form-field appearance="fill" class="search-in-list">
        <mat-label>{{ 'directory.requests.searchInList' | translate }}</mat-label>
        <input matInput
          (keyup)="onSearch($event.target.value)">
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>

      <!-- Unique choice lists -->
      <ng-container *ngIf="!multiple">
        <mat-option
            *ngIf="!field.required"
            class="custom-opt depth-0"
            [value]="'none'"
            (click)="patchOptionValue('none')">
          <mat-radio-button
              [checked]="isChecked('none')">
            {{ 'directory.requests.noneValue' | translate }}
          </mat-radio-button>
        </mat-option>
        <mat-option
            *ngFor="let possibility of filteredListPossibilities"
            [ngClass]="'depth-'+possibility.depth"
            [value]="possibility.element_id"
            (click)="patchOptionValue(possibility)">
          <mat-radio-button
              [checked]="isChecked(possibility)">
            {{ possibility[CONSTANTS.name] }}
          </mat-radio-button>
        </mat-option>
      </ng-container>

      <!-- Multiple & ordered choice lists -->
      <ng-container *ngIf="multiple">
        <mat-option
            *ngFor="let possibility of filteredListPossibilities"
            [value]="possibility.element_id"
            [ngClass]="'depth-'+possibility.depth"
            (click)="toggleAdbElement(possibility)">
          {{ possibility[CONSTANTS.name] }}
        </mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>

  <app-add-subrequest-button 
    *ngIf="hasSubrequestAdb(field)"
    class="col-sd-3 my-1 mr-3"
    [config]="config" 
    [adbId]="field.range"
    [field]="field"
    (subrequestElement)="getNewElement($event)"></app-add-subrequest-button>
</div>