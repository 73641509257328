<form [formGroup]="formGroup" [class.add-request-textarea]="textarea">
  <mat-form-field appearance="outline">
    <textarea matInput
      *ngIf="textarea"
      [formControlName]="field.code"
      [required]="field.required"></textarea>

    <input matInput
      *ngIf="!textarea"
      [formControlName]="field.code"
      [required]="field.required"
      [type]="type"
      [placeholder]="placeholder"
      [pattern]="pattern">
  </mat-form-field>

  <div *ngIf="this.directoryAddRequestService.existSiret && this.field.id=='6cb44afb-56e5-4b8b-9b1c-4df8c155d545_hasNationalIdentifier'">
    <p class="body2">
      <!-- Test on field.id for avoid to show error messages on all textFields -->
      {{ 'directory.requests.identifierAlreadyUsed' | translate }}: <strong>{{ this.directoryAddRequestService.existSiretName }}</strong>
    </p>
    <!-- Only if existing entity is on widget's list -->
    <button mat-stroked-button
        *ngIf="this.directoryAddRequestService.entityDetailsButton"
        color="primary"
        class="mb-3"
        (click)="openShowEntityModal()">
      {{ 'directory.requests.viewEntityDetails' | translate }}
    </button>
    <!-- Only if existing entity is not on widget's list -->
    <button mat-stroked-button
        *ngIf="this.directoryAddRequestService.entityVisibilityRequestButton"
        color="primary"
        class="mb-3"
        (click)="openVisibilityRequestModal()">
      {{ 'directory.requests.notificationVisibility' | translate }}
    </button>
  </div>
</form>
