import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, Input, OnInit } from '@angular/core';
import { matSelectAnimations } from '@angular/material/select';
import { OrderByPipe, TailPipe } from 'ngx-pipes';
import { concatMap, debounceTime } from 'rxjs/operators';

import { DirectoryConfig, DirectoryFieldForFilter } from 'src/app/directory/models/directory-config.model';
import { DirectoryAppliedFilter } from './../../../models/directory-applied-filter.model';

import { DirectorySharedDataService } from 'src/app/directory/services/directory-shared-data.service';
import { DirectoryFiltersStoreService } from 'src/app/directory/services/directory-filters-store/directory-filters-store.service';
import { pipe } from 'rxjs';

@Component({
  selector: 'app-directory-all-filters',
  templateUrl: './directory-all-filters.component.html',
  styleUrls: ['./directory-all-filters.component.scss'],
  animations: [
    matSelectAnimations.transformPanelWrap,
    matSelectAnimations.transformPanel,
  ],
})
export class DirectoryAllFiltersComponent implements OnInit {
  public isSmallScreen: boolean;
  public widgetConfig: DirectoryConfig;
  public hasListDisplayed: boolean;

  public criterias: any;
  public isOpen: boolean = false;

  @Input() criteria: any;
  @Input() isSmall: boolean = false;

  public filtersList: DirectoryFieldForFilter[] = [];
  public appliedFiltersList: DirectoryAppliedFilter[];
  public nbFiltersAlreadyDisplayed: number;
  private nbSpots: number;

  constructor(
    public breakpointObserver: BreakpointObserver,
    private directoryData: DirectorySharedDataService,
    private filtersStore: DirectoryFiltersStoreService,
    public tailPipe: TailPipe,
    public orderByPipe: OrderByPipe
  ) {
    if (window.innerWidth <= 830) {
      this.isSmallScreen = true;
    }
    // detect screen size changes
    this.breakpointObserver.observe([
      "(max-width: 830px)", "(max-width: 699px)", "(max-width: 599px)", "(max-width: 499px)", "(max-width: 399px)"
    ]).subscribe((result: BreakpointState) => {
      if (result.matches) {
        this.isSmallScreen = true;    
      } else {
        this.isSmallScreen = false;
      }
    });
  }

  ngOnInit(): void {
    this.criterias = this.criteria;
    this.directoryData
      .getDirectoryConfig()
      .subscribe((config: DirectoryConfig) => {
        this.widgetConfig = config;
        this.filtersList = config.filters.list;
        this.setNbFiltersAlreadyDisplayed();
    });
    this.filtersStore
      .getAppliedFilters()
      .subscribe((appliedFilters: DirectoryAppliedFilter[]) => {
        this.appliedFiltersList = appliedFilters;
    });
    this.filtersStore
      .getAvailableMainSpots()
      .pipe(debounceTime(0))
      .subscribe((nbSpots: number) => {
        this.nbSpots = nbSpots;
        this.setNbFiltersAlreadyDisplayed();
    });
  }

  public openAllFiltersDropdown(): void {
    this.isOpen = !this.isOpen;
  }

  public closeAllFiltersDropdown(): void {
    this.isOpen = false;
  }

  private setNbFiltersAlreadyDisplayed() {
    if (this.filtersList && this.nbSpots) {
      const nbMainFilter = this.filtersList.filter(x => x.isMainFilter).length;
      if(nbMainFilter >= this.nbSpots) {
        this.nbFiltersAlreadyDisplayed = this.nbSpots;
      }
      else {
        this.nbFiltersAlreadyDisplayed = nbMainFilter;
      }
    }
  }

  public getNbAppliedFiltersInDropdown() {
    const orderedList = this.orderByPipe.transform(this.filtersList, '-isMainFilter');
    const tailedList = this.tailPipe.transform(orderedList, this.nbFiltersAlreadyDisplayed);
    const filteredList = [];
    tailedList.forEach(currFilter => {
      this.appliedFiltersList.forEach(appliedFilters => {
        if (appliedFilters.concernedField === currFilter.id) {
          filteredList.push(currFilter);
        }
      });
    });
    return tailedList.length;
  }
}
