<section class="directory-entity-sheet-wrapper">
  <header>
    <nav class="directory-entity-sheet-navigation util-el-surface">
      <button mat-button appearance="with-icon" (click)="closeEntitySheet()" class="directory-entity-sheet-back" *ngIf="isSheet">
        <mat-icon>close</mat-icon>
        <span class="button1">{{'directory.sheet.close' | translate}}</span>
      </button>
      <button mat-button appearance="with-icon" (click)="closeEntityDialog()" class="directory-entity-sheet-back" *ngIf="!isSheet">
        <mat-icon>close</mat-icon>
        <span class="button1">{{'directory.sheet.close' | translate}}</span>
      </button>
      <div class="flex-spacer"></div>
      <button mat-icon-button
          *ngIf="widgetConfig?.requests?.hasModificationsRequests"
          (click)="closeEntityDialog()"
          [routerLink]="'/'+widgetLang+'/annuaire/'+widgetId+'/entity/'+currentOpenedEntityId+'/modifications'">
        <mat-icon>edit</mat-icon>
      </button>
      <div>
        <button mat-icon-button 
            [disabled]="!canGoToPreviousEntity()" 
            (click)="switchToNeighbourEntity(getCurrentEntityPositionInResults(),-1)">
          <mat-icon>chevron_left</mat-icon>
        </button>
        <span>
          <ng-container>{{1 + getCurrentEntityPositionInResults()}} </ng-container>
          <ng-container>/ </ng-container>
          <ng-container>{{nbResults}}</ng-container>
        </span>
        <button mat-icon-button 
            [disabled]="!canGoToNextEntity()" 
            (click)="switchToNeighbourEntity(getCurrentEntityPositionInResults(),+1)">
          <mat-icon>chevron_right</mat-icon>
        </button>
      </div>
      <button mat-icon-button 
          class="mr-2" 
          (click)="openEntityInDialog()" 
          *ngIf="isSheet">
        <mat-icon>fullscreen</mat-icon>
      </button>
    </nav>
  </header>

  <div class="directory-entity-sheet-core" [class.entity-fullwidth]="!isSheet" [class.fullsize-layout]="widgetConfig.layout.isFullSize">
    <app-directory-entity 
      [previewMode]="previewMode"
      [entityId]="currentOpenedEntityId" 
      [range]="openedElementRange" 
      [widgetConfig]="widgetConfig"></app-directory-entity>
  </div>
</section>
