<div class="dialog-header" [style.backgroundColor]="widgetConfig?.style.mainColor + '14'">
  <h2 *ngIf="elementValues">{{ elementValues[CONSTANTS.name] }}</h2>
  <div class="flex-spacer"></div>
  <!-- <button mat-icon-button (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </button> -->
</div>
<button mat-stroked-button
  *ngIf="canContact()"
  class="directory-entity-contact-button mt-2"
  appearance="with-icon"
  color="primary"
  (click)="openContactDialog()">
<mat-icon>mail</mat-icon>
<span>{{ 'directory.contact.entity_contact'|translate }}</span>
</button>
<ng-container *ngFor="let field of currentAdbFields | orderByImpure: 'order'">
  <ng-container *ngIf="field">
    <app-directory-entity-field
      [field]="field"
      [fieldValue]="elementValues[field.id]"
      [class]="'field--'+field.id"></app-directory-entity-field>
  </ng-container>
</ng-container>
