import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DirectoryIconGeneratorService {
  public availableIcons: {} = {};
  public currentCanvas: any;
  public currentColors: any;
  constructor() {}

  getIcon(colors: string[]) {
    this.currentColors = colors.sort().join('_');
    if (this.availableIcons[this.currentColors]) {
      return this.availableIcons[this.currentColors];
    }
    return this.generateCanvasAndIcon(colors);
  }

  generateCanvasAndIcon(colors) {
    // we create a canvas element
    this.currentCanvas = document.createElement('canvas');
    let height = 24;
    let width = 24;
    if (colors.length === 5) {
      height = 25;
      width = 25;
    }
    if (colors.length === 7 || colors.length === 9) {
      height = 28;
      width = 28;
    }


    this.currentCanvas.height = height;
    this.currentCanvas.width = width;
    // getting the context will allow to manipulate the image
    const context = this.currentCanvas.getContext('2d');

    // We create a new imageData.
    const imageData = context.createImageData(width, height);
    // The property data will contain an array of int8
    const data = imageData.data;

    this.generateIcon(this.currentCanvas.height, this.currentCanvas.width, data, colors);

    // we put this random image in the context
    context.putImageData(imageData, 0, 0); // at coords 0,0
    return this.image64('png', 'image/png');
  }

  generateIcon(height: any, width: any, data: any, colors: string[]) {
    const nbPixel = height * width;
    const nbColors = colors.length;
    const factor = nbPixel / nbColors;

    for (let i = 0; i < nbPixel; i++) {
      const x = i / factor;
      const rgbColor = this.hexToRgb(colors[Math.trunc(x)]);
      data[i * 4 + 0] = rgbColor.r; // Red
      data[i * 4 + 1] = rgbColor.g; // Green
      data[i * 4 + 2] = rgbColor.b; // Blue
      data[i * 4 + 3] = 256; // alpha (transparency)
    }
  }

  hexToRgb(hex: string) {
    // check if color is already rgb(a)
    if (hex.includes("rgb")) {
      const match = hex.toLowerCase().match(/rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.\d*)?)\))?/);
      return match ? {
        r: parseInt(match[1],10),
        g: parseInt(match[2],10),
        b: parseInt(match[3],10)
      } : null;
    }


    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => {
      return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }


  image64(type, mimetype) {
    const value = this.currentCanvas.toDataURL(mimetype);
    if (value.indexOf(mimetype) > 0) {
      // we check if the format is supported
      this.stockGeneratedIcon(value);
      return value;
    } else {
      return false;
    }
  }

  stockGeneratedIcon(icon) {
    this.availableIcons[this.currentColors] = icon;
  }
}
