<form [formGroup]="formGroup">
  <mat-radio-group [formControlName]="field.code" [required]="field.required" class="radio">
    <ng-container *ngIf="!tripleState">
      <mat-radio-button class="mr-1" [value]="'true'">{{ 'fields.boolean.true' | translate }}</mat-radio-button>
      <mat-radio-button class="ml-1 mr-1" [value]="'false'">{{ 'fields.boolean.false' | translate }}</mat-radio-button>
    </ng-container>
    <ng-container *ngIf="tripleState">
      <mat-radio-button class="mr-1" value="Yes">{{ 'fields.triplestate.Yes' | translate }}</mat-radio-button>
      <mat-radio-button class="ml-1 mr-1" value="No">{{ 'fields.triplestate.No' | translate }}</mat-radio-button>
      <mat-radio-button class="ml-1" value="None">{{ 'fields.triplestate.None' | translate }}</mat-radio-button>
    </ng-container>
  </mat-radio-group>
</form>
