<div class="directory-legend-wrapper">

  <nav class="directory-legend-header">
    <div class="util-border-bottom-1 util-el-background">
      <!-- <p class="directory-legend-title">{{ widgetConfig?.legend?.title[currentLang] }}</p> -->
      <p class="directory-legend-title">{{ translationCompatibilityForOlderWidget(widgetConfig?.legend?.title, currentLang) }}</p>
      <div class="flex-spacer"></div>

      <button mat-mini-fab *ngIf="canLegendBeHidden()" (click)="hideLegend()"
        class="directory-legend-close-button" [class.list-on-the-left]="isLegendOnTheLeft()" appCustomizedHoverColor>
        <mat-icon appCustomizedFontColor>{{isLegendOnTheLeft() ? 'chevron_left' : 'chevron_right' }}</mat-icon>
      </button>
    </div>
  </nav>

  <form class="legend-form">
    <ul class="directory-legend-core">
      <li class="directory-legend-line">
        <mat-checkbox 
            (change)="onAllCheckboxChange($event)" 
            color="primary" 
            [checked]="isAllChecked()" 
            [indeterminate]="isAllIndeterminate()">
          {{ 'directory.legend.all' | translate }}
        </mat-checkbox>
      </li>
      <ng-container>
        <li class="directory-legend-line" *ngFor="let line of legend?.groups; index as i">
          <!-- <mat-checkbox (change)="onCheckboxChange($event, line.title)" color="primary" [checked]="isChecked(line.title)">{{ line.title[currentLang] }}</mat-checkbox> -->
          <mat-checkbox (change)="onCheckboxChange($event, line.title)" color="primary" [checked]="isChecked(line.title)">{{ translationCompatibilityForOlderWidget(line.title, currentLang) }}</mat-checkbox>
          <div class="flex-spacer"></div>
          <div class="directory-legend-icon">            
            <img *ngIf="(!line.markerChoice || line.markerChoice == 'custom')" [src]="line.icon" alt="Icone de légende manquante" height="24" width="24">
            <div *ngIf="line.markerChoice == 'predefined'"
              [ngClass]="line.iconBoxShape? 'icon-box' : 'icon-circle'"
              [ngStyle]="{ 'height': '24px', 'width': '24px', 'border-color': (line.iconBorder && !line.iconInvertMode) || (!line.iconBorder && line.iconInvertMode)  ? line.iconMarkerColor : '#fff', 'background-color': line.iconInvertMode ? line.iconMarkerColor : '#FFF' }"
            >
              <div 
                  class="{{line.iconMarker}}">
                <div
                  class="icon-background"
                  [ngStyle]="{ 'background-color': line.iconInvertMode ? '#FFF' : line.iconMarkerColor }"
                  ></div>
              </div>
            </div>
          </div>
        </li>
      </ng-container>
    </ul>
  </form>
</div>
