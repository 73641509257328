<div class="row justify-content-end">
    <button mat-icon-button (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
    <button mat-icon-button
        *ngIf="widgetConfig?.requests?.hasModificationsRequests"
        class="mr-2"
        (click)="closeAndEditDialog()">
      <mat-icon>edit</mat-icon>
    </button>
</div>

<app-directory-entity 
    [isEditable]="true"
    [entityId]="entityId" 
    [widgetConfig]="widgetConfig"></app-directory-entity>