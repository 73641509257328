import { CONSTANTS } from './../../../../environments/constants';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { concatMap, debounceTime, map, mergeMap, tap } from 'rxjs/operators';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DateAdapter } from '@angular/material/core';
import { Router } from '@angular/router';

import { DirectoryFiltersStoreService } from './../../services/directory-filters-store/directory-filters-store.service';
import { DirectoryUtilsService } from './../../services/directory-utils/directory-utils.service';
import { DirectorySharedDataService } from '../../services/directory-shared-data.service';
import { DirectorySearchService } from '../../services/directory-search/directory-search.service';
import { DirectoryAddRequestService } from '../../services/directory-add-request/directory-add-request.service';
import { DirectoryModificationsRequestService } from '../../services/directory-modifications-request/directory-modifications-request.service';
import { DirectoryEmbedRequestService } from '../../services/directory-embed-request/directory-embed-request.service';

import { FieldType } from '../../models/fields/field.model';
import { ModificationsValue } from '../../models/directory-modifications-request.model';
import { DirectoryConfig, DirectoryField } from './../../models/directory-config.model';
import { Requester } from 'src/app/directory/models/directory-modifications-request.model';

import { EntityDetailsCardComponent } from '../fields/entity-details-card/entity-details-card.component';
import { DirectoryVisibilityRequestValidationDialogComponent } from '../dialog/directory-visibility-request-validation-dialog/directory-visibility-request-validation-dialog.component';


export interface adbElement {
  element_id: string;
  score: number;
  core_hasParentValue: string;
  values?: any[]; // Values from subrequestForm
}

@Component({
  selector: 'app-directory-field-for-request-dialog',
  templateUrl: './directory-field-for-request-dialog.component.html',
  styleUrls: ['./directory-field-for-request-dialog.component.scss'],
})
export class DirectoryFieldForRequestDialogComponent implements OnInit, OnDestroy {
  public unsubscribe: Subscription[] = [];
  public CONSTANTS = CONSTANTS;
  public FieldType = FieldType;
  public field: DirectoryField;
  public modificationRequestForm: FormGroup;

  public fieldValue: any;
  public imgFieldValue: SafeResourceUrl;
  public hasNewImage: boolean = false;
  public entitiesIdToAddToRequest: string[];
  public observableEntities: Observable<string>[];
  public preparedEntitiesLinkReadableValue: string[];
  public fieldTitle: any;
  public listPossibilities: any[] = [];
  public entityId: string;
  public entity: any;

  public config: DirectoryConfig;
  public existSiret: boolean = false;
  public existSiretName: string;
  public existSiretId: string;

  public parentValue: any;
  public linkedChoiceValue: any;
  public linkedFields: any[] = [];
  public linkedChoiceBindingObject: LinkedChoiceValues[] = [];
  public linkedChoiceCopy: LinkedChoiceValues[] = [];
  public elementsList: any[] = [];
  public checkNewElement: boolean = false;

  public preparedLinkedChoiceFieldId: string;
  public preparedLinkedChoiceField: any;
  public preparedLinkedChoiceFieldTitle: any;
  public preparedLinkedChoiceFieldValue: string;
  public preparedLinkedChoiceValue: string;
  public preparedLinkedChoiceReadableValue: string;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<DirectoryFieldForRequestDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private domSanitizer: DomSanitizer,
    private directoryStore: DirectorySharedDataService,
    private directoryFiltersStore: DirectoryFiltersStoreService,
    private utils: DirectoryUtilsService,
    private translate: TranslateService,
    public directoryAddRequestService: DirectoryAddRequestService,
    public directoryModificationsRequestService: DirectoryModificationsRequestService,
    private directoryEmbedRequestService: DirectoryEmbedRequestService,
    private snackbar: MatSnackBar,
    private directorySearch: DirectorySearchService,
    private translateService: TranslateService,
    private dateAdapter: DateAdapter<Date>,
    public dialog: MatDialog,
    private router: Router
  ) {
    this.dateAdapter.setLocale(this.translateService.currentLang);
  }

  ngOnInit(): void {
    this.field = this.data.field;
    this.fieldValue = this.data.fieldValue;
    this.entityId = this.data.entityId;
    this.fieldTitle = this.data.fieldTitle != undefined ? this.data.fieldTitle : this.tryToFindName(this.data.field.id);
    this.entity = this.data.entity;

    this.directoryStore.getDirectoryConfig().subscribe((config) => {
      this.config = config;
    });

    this.modificationRequestForm = this.fb.group({
      fieldValue: new FormControl(this.initFormValue()),
      newFieldValue: new FormControl(this.initFormValue()),
      commentary: new FormControl(),
    });

    // Init list element form
    if (this.fieldHasPossibilities()) {
      this.loadFieldPossibilities(); // Contains init new elements
    }

    // Init Img file form
    if (this.field.type === FieldType.Image) {
      this.imgFieldValue = this.data.fieldValue;
      this.modificationRequestForm.addControl(`file-${this.field.id}`, new FormControl(''));
    }

    // Init Linked Choice lists and values
    if (this.field.type === FieldType.LinkedChoice) {
      this.directoryStore.findList(this.field.associated_data_id).pipe(
        concatMap((elements) => {
          // List of elements used by our field (to send to child component)
          this.elementsList = elements['elements'];

          // List of parent/children LinkedChoice fields with their fieldValues
          return this.directoryStore.getAvailableFields();
        }),
        map((fields) =>
          fields.filter((field) => field.type === FieldType.LinkedChoice)
        )
      ).subscribe((fields) => {
        let currentRootId: string = '';

        // Current selected field. Is it a root field?
        if ((this.field.ancestry != null) && (this.field.ancestry.length === 0)) {
          currentRootId = this.field.id ? this.field.id : this.field.field_id;
        } else {
          // last ancestry is the root
          currentRootId = this.field.ancestry.slice(-1)[0];
        }

        // get the proper sheet and preserve order

        fields.forEach((field) => {
          // Fill the list with all the concerned fields in the tree
          if ((field.ancestry.some((id) => id === currentRootId)) ||              // Add children
              (field.field_id === currentRootId)) {                               // Add root field
            this.linkedFields.push(field);
          }
        });

        this.linkedFields.forEach((field) => {
          let localLinkedChoiceValue;
          if (this.entity && this.entity[field.field_id]) {
            localLinkedChoiceValue = this.entity[field.field_id];
          } else {
            localLinkedChoiceValue = null;
          }
          this.giveParentsValuesToLinkedChildren(field, localLinkedChoiceValue);


          this.linkedChoiceBindingObject.push({
            field: field,
            field_value: localLinkedChoiceValue,
            parent_value: this.parentValue ? this.parentValue : null
          });
        });

        this.linkedChoiceBindingObject.sort( (a, b) => a.field.ancestry.length - b.field.ancestry.length)

        this.linkedChoiceBindingObject.forEach((val) => this.linkedChoiceCopy.push(Object.assign({}, val))); // Used for static list of field values (front-end)
      });
    }

    // Siret validation
    if (this.field.type === FieldType.Textarea && this.field.id === '6cb44afb-56e5-4b8b-9b1c-4df8c155d545_hasNationalIdentifier') {
      this.modificationRequestForm.get('newFieldValue').valueChanges.pipe(
        debounceTime(500)
      ).subscribe(newSiret => {
        this.directoryStore.testSiret(newSiret, null).subscribe((response) => {
          if (Boolean(response.body['id']) && (newSiret != this.modificationRequestForm.get('fieldValue').value)) {
            this.existSiret = Boolean(response.body['id']);

            if (this.existSiret) {
              this.existSiretName = response.body['name'];
              this.existSiretId = response.body['id'];
              // Reinitialize booleans
              this.directoryAddRequestService.entityDetailsButton = false;
              this.directoryAddRequestService.entityVisibilityRequestButton = false;
              // Entities listed in widget's list (without taking into account any filter)
              this.directoryAddRequestService.detailsOrNotificationTests(this.existSiretId, this.config.layout.hasFilters);
            }
          } else this.existSiret = false;
        });
      })
    }
  }

  /** FORM */
  private initFormValue(): string {
    return this.fieldValue;
  }

  public tryToFindName(id: string) {
    this.directoryStore.getAvailableFields().subscribe((fields) => {
      if (fields.find(x => x.field_id === id)) {
        this.fieldTitle = {
          "fr" : fields.find( x => x.field_id === id).name,
          "en" : fields.find( x => x.field_id === id).name
        };
      }
    });
    return null;
  }

  public tryUploadThenReturnForm() {
    // Save and prepare new entities/elements
    if (this.fieldHasPossibilities() ||
        this.field.type === FieldType.UniqueEntityLink || this.field.type === FieldType.MultipleEntityLink)
      this.directoryEmbedRequestService.prepareNewObjectsForValidationDialog();

    if (this.field.type === FieldType.Image) {
      // deals with sending image to backend to get Url before close the dialog
      this.imageTypes();
    } else if (this.field.type === FieldType.UniqueEntityLink || this.field.type === FieldType.MultipleEntityLink) {
      // deals with entities to get names before closing dialog
      this.entityTypes();
    } else if (this.field.type === FieldType.LinkedChoice) {
      this.linkedChoiceTypes();
    } else {
      this.dialogRef.close([this.returnForm()]);
    }
  }

  imageTypes() {
    if (!this.hasNewImage) {
      this.dialogRef.close();
    } else {
      const formData = new FormData();
      formData.append('image', this.modificationRequestForm.get("file-"+this.field.id).value);
      const result =  this.directoryStore.uploadImg(formData).subscribe(
        // good day
        body => {
          this.modificationRequestForm.get('newFieldValue').patchValue(body);
          this.modificationRequestForm.get('newFieldValue').markAsTouched();
          this.dialogRef.close([this.returnForm()]);
        }, body => { // bad day
          this.snackbar.open(this.translate.instant("fields.image.notGoodFormat"), "KO");
        }
      );
    }
  }

  entityTypes() {
    if (!this.entitiesIdToAddToRequest || this.entitiesIdToAddToRequest.length == 0) {
      // Cancel the modification through "validate" button without any selected value
      this.dialogRef.close();
    } else {
      // FieldType.UniqueEntityLink must have a unique value type string in order to be modified
      if (this.field.type === FieldType.UniqueEntityLink)
        this.modificationRequestForm.get('newFieldValue').patchValue(this.entitiesIdToAddToRequest.toString());

      // FieldType.MultipleEntityLink must be an array of objects in order to be modified
      if (this.field.type === FieldType.MultipleEntityLink) {
        let entityIdObject: MultipleEntitiesValues[] = [];
        this.entitiesIdToAddToRequest.forEach((value) => {
          entityIdObject.push({
            entity_id : value
          })
        });
        this.modificationRequestForm.get('newFieldValue').patchValue(entityIdObject);
      }

      this.modificationRequestForm.get('newFieldValue').markAsTouched();
      this.observableEntities = [];
      let newEntitiesNames: string[] = [];

      this.entitiesIdToAddToRequest.forEach((entityId) => {
        if (entityId.includes("_:")) {
          let newEntity: any = this.directoryEmbedRequestService.newEntitiesList.find(entity => entity.id === entityId);
          if (newEntity) {
            let newEntityName = newEntity.values.find(val => val.field_id === "6cb44afb-56e5-4b8b-9b1c-4df8c155d545_hasName").value;
            newEntitiesNames.push(newEntityName);
          }
        } else {
          this.observableEntities.push(
            this.directorySearch.findEntityData(entityId, true).pipe(
              concatMap((ent) => of(ent[CONSTANTS.mainName]))
            )
          );
        }
      });

      if (this.observableEntities.length > 0) {
        forkJoin(this.observableEntities).subscribe((entitiesName) => {
          this.preparedEntitiesLinkReadableValue = entitiesName;
          if (newEntitiesNames.length > 0)
            this.preparedEntitiesLinkReadableValue = this.preparedEntitiesLinkReadableValue.concat(newEntitiesNames);

          // Once we have the array observableEntities with all the values, we close the dialog and send the data to our Form
          this.dialogRef.close([this.returnForm()]);
        });
      } else {
        this.preparedEntitiesLinkReadableValue = newEntitiesNames;
        this.dialogRef.close([this.returnForm()]);
      }
    }
    // no need to close, async action lets direct close dialog
  }

  linkedChoiceTypes() {
    // Get the difference between lists using the static (init values) and dynamic (modified values) lists
    let differenceBetweenLists = this.linkedChoiceBindingObject.filter((obj1) =>
      !this.linkedChoiceCopy.find((obj2) =>
        obj1.field.field_id === obj2.field.field_id &&
        obj1.field_value === obj2.field_value
    ));

    if (differenceBetweenLists.length > 0) { // There are modifications to be sent
      let currentField: any;
      let arrayToSend: any[] = [];
      differenceBetweenLists.forEach((field) => {
        currentField = this.linkedChoiceBindingObject.find((val) => val.field.field_id === field.field.field_id);

        this.preparedLinkedChoiceFieldId = currentField.field.field_id;                 //-> field_id
        this.preparedLinkedChoiceField = currentField.field;                            //-> field
        this.preparedLinkedChoiceFieldTitle = field.field.name;                         //-> fieldTitle
        this.preparedLinkedChoiceFieldValue = currentField.field_value?.element_id ?
          currentField.field_value.element_id : null;                                   //-> fieldValue (old)
        this.preparedLinkedChoiceValue = field.field_value?.element_id ?
          field.field_value.element_id : null;                                          //-> value (new)
        this.preparedLinkedChoiceReadableValue = field.field_value?.core_hasListName ?
          currentField.field_value.core_hasListName : "null";                           //-> readableValue (new)

        this.modificationRequestForm.get('newFieldValue').patchValue(currentField);
        this.modificationRequestForm.get('newFieldValue').markAsTouched();
        arrayToSend.push(this.returnForm());
      });
      this.dialogRef.close(arrayToSend);
    } else {
      this.dialogRef.close();
    }
  }

  public returnForm(): ModificationsValue {
    /** Values to send through our form:
     * entity --> entity object used mainly by Linked Choice fields (any)
     * field --> selected field (any)
     * field_id --> selected field id whose value have been modified (string)
     * fieldTitle --> opt. Name of our selected field (string, string[] for LinkedChoice)
     * fieldValue --> opt. Old value of our selected field (string)
     * value --> new value id (string, string[] for EntityLink)
     * readableValue --> name of our new value (string)
     * comment --> opt. Written comment to send (string)
     */

    if (this.modificationRequestForm.get('newFieldValue').value != this.fieldValue) {
      return {
        entity:
          this.entity,
        field:
          this.field.type === FieldType.LinkedChoice ?
            this.preparedLinkedChoiceField :
            this.field,
        field_id:
          this.field.type === FieldType.LinkedChoice ?
            this.preparedLinkedChoiceFieldId :
            this.field.id,
        fieldTitle:
          this.field.type === FieldType.LinkedChoice ?
            this.preparedLinkedChoiceFieldTitle :
            this.fieldTitle,
        fieldValue:
          this.field.type === FieldType.LinkedChoice ?
            this.preparedLinkedChoiceFieldValue :
            this.fieldValue,
        value:
          this.field.type === FieldType.LinkedChoice ?
            this.preparedLinkedChoiceValue :
            this.modificationRequestForm.get('newFieldValue').value,
        readableValue:
          this.field.type === FieldType.LinkedChoice ?
            this.preparedLinkedChoiceReadableValue :
            this.formatValue(this.modificationRequestForm.get('newFieldValue').value),
        comment:
          this.modificationRequestForm.get('commentary').value
      };
    } else {
      return null;
    }
  }

  /** Format values */
  public formatValue(currentValue: any): string {
    let formattedValue;
    if (Array.isArray(currentValue) &&
        (!(this.field.type === FieldType.UniqueEntityLink || this.field.type === FieldType.MultipleEntityLink))) {
      formattedValue = [];
      if (currentValue[0] && currentValue[0][CONSTANTS.name]) {
        currentValue.forEach(element => {
          formattedValue.push(element[CONSTANTS.name]);
        });
      }
      return formattedValue.length > 0 ? formattedValue.join("; ") : "Aucune valeur";
    }
    if (this.listPossibilities && this.fieldHasPossibilities()) {
      if (currentValue instanceof Object) { // previous value
        return (currentValue) && CONSTANTS.name in currentValue ?
          currentValue[CONSTANTS.name] :
          currentValue;
      } else { // new value
        return this.listPossibilities.find(x => x.element_id === currentValue) ?
          this.listPossibilities.find(x => x.element_id === currentValue)[CONSTANTS.name] :
          currentValue;
      }
    }

    if (currentValue == 'true' || currentValue == 'false')
      currentValue = this.translate.instant("fields.boolean."+currentValue);
    if (currentValue == 'Yes' || currentValue == 'No' || currentValue == 'None' )
      currentValue = this.translate.instant("fields.triplestate."+currentValue);

    if (this.field.type === FieldType.UniqueEntityLink || this.field.type === FieldType.MultipleEntityLink)
      currentValue = this.preparedEntitiesLinkReadableValue.join(', ');

    return currentValue;
  }

  /** Entity link */
  // Event to get selected entities ids
  getEntities(entities) {
    this.entitiesIdToAddToRequest = entities;
  }

  /** Linked choice fields */
  giveParentsValuesToLinkedChildren(field, value) {
    // We want to give the next parent's value to all children except for root parents
    if (value === null || value === undefined) {
      this.parentValue = value;
      let counter = 0;

      while ((this.parentValue === null || this.parentValue === undefined) &&
            (field.parent_field_id != null) &&    // Only root parents have null values
            (counter < this.linkedFields.length)) {
        this.parentValue = this.entity[field.ancestry[counter]];
        counter++;
      }
    } else if ((field.ancestry.length > 0) &&
              (value != null || value != undefined)) {
      this.parentValue = this.entity[field.parent_field_id]; // Parent's value is already defined, so copy it
    }

    return this.parentValue;
  }

  // Cross binding for linked choice parent values
  crossOffValue(newValue) {
    // Find selected field index
    let fieldIndex: number = this.linkedChoiceBindingObject.findIndex((x) => x.field.field_id === newValue.selectedField.field_id);

    if (newValue.selectedValue != null) {
      // A value has been selected.
      // "selectedValue" is the element id. We need to search for the element object
      let localLinkedChoiceValue = this.elementsList.find((x) => x.element_id === newValue.selectedValue);
      // Update current field value and parent value params
      if (fieldIndex === localLinkedChoiceValue.depth) {
        this.linkedChoiceBindingObject[fieldIndex].field_value = localLinkedChoiceValue;
        if (localLinkedChoiceValue.core_hasParentValue) {
          this.linkedChoiceBindingObject[fieldIndex].parent_value = this.elementsList.find((x) =>
            x.element_id === localLinkedChoiceValue.core_hasParentValue.element_id);
        }
      }
      // Parents only (from child to parent)
      if (localLinkedChoiceValue.core_hasParentValue) {
        this.updateParentFieldValue(fieldIndex, localLinkedChoiceValue.core_hasParentValue.element_id);
      }
      // Children only (from parent to child)
      this.updateChildFieldValue(fieldIndex, localLinkedChoiceValue);
    } else {
      // NULL value has been selected
      this.linkedChoiceBindingObject[fieldIndex].field_value = null;
      // Give null values to all children except for parentValue
      this.updateChildFieldValue(fieldIndex, null);
    }
  }

  updateParentFieldValue(index, parentId) {
    let currentParent = this.elementsList.find((x) => x.element_id === parentId);
    let currentField = this.linkedChoiceBindingObject[currentParent.depth];

    if (currentField.field_value === null) {
      currentField.field_value = currentParent;
      // Unless we are at the root level, parent will always have a grandparent
      if (currentParent.core_hasParentValue) {
        let elementGrandParent = this.elementsList.find((x) => x.element_id === currentParent.core_hasParentValue.element_id);
        currentField.parent_value = elementGrandParent;

        this.updateParentFieldValue(index-1, elementGrandParent.element_id);
      }
    }
  }

  updateChildFieldValue(index, current) {
    let counter = index + 1;
    let currentParentValue = this.linkedChoiceBindingObject[index].parent_value;

    while (counter < this.linkedChoiceBindingObject.length) {
      let currentChild = this.linkedChoiceBindingObject[counter];

      if (current != null) {
        // If parent tree is different, give null values to children
        if ((currentChild.field_value != null) &&
            (currentChild.parent_value.element_id != current.element_id)) {
          currentChild.field_value = null;
        }
        // Give selectedValue to all children
        currentChild.parent_value = current;
      } else {
        currentChild.field_value = null;
        currentChild.parent_value = currentParentValue;
      }

      counter++;
    }
  }

  /** Unique, multiple, ordered lists */
  fieldHasPossibilities(): boolean {
    if(this.field.type === FieldType.UniqueChoiceList) return true;
    if(this.field.type === FieldType.LinkedChoice) return true;
    if(this.field.type === FieldType.MultipleChoiceList) return true;
    if(this.field.type === FieldType.MultipleChoiceListWithComment) return true;
    if(this.field.type === FieldType.OrderedMultipleChoiceList) return true;
    if(this.field.type === FieldType.ExternalSourceAssociatedDb) return true;

    return false;
  }

  loadFieldPossibilities() {
    this.unsubscribe.push(this.directoryStore.getAvailableFields().pipe(
      map((fields) => {
        return this.directoryFiltersStore.getListIdFromFieldId(fields, this.field.id);
      }),
      concatMap((listId) => {
        if (listId) {
          return this.directoryStore.findList(listId);
        } else
          return of(null);
      }),
      concatMap((list: any) => {
        if (list) {
          // if field is not required add none to list for lu ?
          this.listPossibilities = this.utils.orderList(list.elements)[0];
          return this.directoryEmbedRequestService.getNewElement();
        } else
          return of(null);
      })
    ).subscribe((newElement) => {
      // If new element points to the same list as field's range, add it to listPossibilities
      if (newElement && newElement.listId === this.field.range) {
        // Reconstruct element format
        this.listPossibilities.unshift({
          element_id: newElement.id,
          core_hasListName: newElement.listName,
          core_hasOrder: 0,
          depth: 0,
          values: newElement.values
        });
      }
    }));
  }

  public isLmChecked(adbElement: adbElement): boolean {
    // Checked elements by default (old values)
    if (this.fieldValue) {
      if (this.fieldValue.find((x) => x.element_id === adbElement.element_id)) {
        return true;
      }
    }
    // New checked elements on field for request dialog (new values)
    if ((this.modificationRequestForm.get('newFieldValue').value != null) && (this.modificationRequestForm.get('newFieldValue').value.length > 0)) {
      if (this.modificationRequestForm.get('newFieldValue').value.find((x) => x.element_id === adbElement.element_id)) {
        return true;
      }
    }
    return false;
  }

  public isLuChecked(adbElement: adbElement): boolean {
    if ((this.modificationRequestForm.get('newFieldValue').value) && (this.modificationRequestForm.get('newFieldValue').value.element_id === adbElement.element_id ||
        this.modificationRequestForm.get('newFieldValue').value === adbElement.element_id))
      return true;
    return false;
  }

  /** Hierarchical multiple list  */
  public addElementAndParents(adbElement: adbElement, newValue, state: MatCheckboxChange) {
    if (adbElement) {
      newValue = newValue.filter((x) => x.element_id !== adbElement.element_id);
      newValue.push(adbElement);
      this.modificationRequestForm.get('newFieldValue').patchValue(newValue);

      this.hasLmBeenEdited(adbElement);

      if (adbElement.core_hasParentValue != undefined && adbElement.core_hasParentValue['element_id']) {
        let parentAdbElement = this.listPossibilities.find((x) => x.element_id === adbElement.core_hasParentValue['element_id']);
        newValue = this.addElementAndParents(parentAdbElement, newValue, state);
      }
    }
    return newValue;
  }

  public removeElementAndChildren(adbElement: adbElement, newValue, state: MatCheckboxChange) {
    if (adbElement) {
      newValue = newValue.filter((x) => x.element_id !== adbElement.element_id);
      this.modificationRequestForm.get('newFieldValue').patchValue(newValue);
      this.hasLmBeenEdited(adbElement);
      this.listPossibilities.forEach((child) => {
        if (child.core_hasParentValue && child.core_hasParentValue['element_id'] === adbElement.element_id) {
          newValue = this.removeElementAndChildren(child, newValue, state);
        }
      }) ;
    }
    return newValue;
  }

  public toggleAdbElement(adbElement: adbElement, evnt?: MatCheckboxChange): void {
    let newValue = this.modificationRequestForm.get('newFieldValue').value;
    if (newValue == null) {
      newValue = [];
    }
    newValue = newValue.filter((x) => x.element_id !== adbElement.element_id);
    if ((evnt && evnt.checked === true) || adbElement.values) {
      newValue.push(adbElement);
      newValue = this.addElementAndParents(adbElement, newValue, evnt);
    } else {
      newValue = this.removeElementAndChildren(adbElement, newValue, evnt);
    }
    this.modificationRequestForm.get('newFieldValue').patchValue(newValue);
  }

  public hasLmBeenEdited(adbElement: adbElement): boolean {
    let wasInOldValue = false;
    if (this.fieldValue) { // only give a try if there was a value in the first place
      if (this.fieldValue.find((x) => x.element_id === adbElement.element_id))
        wasInOldValue = true;
    }

    let isInNewValue = false;
    if (this.modificationRequestForm.get('newFieldValue').value) { // only give a try if there is a current value
      if (this.modificationRequestForm.get('newFieldValue').value.find((x) => x.element_id === adbElement.element_id))
        isInNewValue = true;
    }

    if (wasInOldValue && isInNewValue) return false;
    if (!wasInOldValue && !isInNewValue) return false;

    return true;
  }

  public hasLuBeenEdited(adbElement: adbElement): boolean {
    let wasInOldValue = false;
    if (this.fieldValue) { // only give a try if there was a value in the first place
      wasInOldValue = this.fieldValue.element_id === adbElement.element_id;
    }

    let isInNewValue = (this.modificationRequestForm.get('newFieldValue').value) && (this.modificationRequestForm.get('newFieldValue').value.element_id === adbElement.element_id ||
                        this.modificationRequestForm.get('newFieldValue').value === adbElement.element_id);

    if (wasInOldValue && isInNewValue) return false;
    if (!wasInOldValue && !isInNewValue) return false;

    return true;
  }

  /** Image field */
  public processFile(imageInput: any) {
    let image: File = imageInput.files[0];
    if (image) {
      if (  Math.round(( image.size/ 1024)) < 5242 ) {
        this.modificationRequestForm.patchValue({[`file-${this.field.id}`]: image });
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = () => {
          this.hasNewImage = true;
          this.imgFieldValue = this.domSanitizer.bypassSecurityTrustResourceUrl(reader.result as string);
        };
      } else {
        this.snackbar.open(this.translate.instant("fields.image.tooBig"), "OK");
      }
    }
  }

  public removeImage() {
    this.modificationRequestForm.patchValue({ [`file-${this.field.id}`]: null });
    this.imgFieldValue = '';
    this.hasNewImage = false;
  }

  /** Visibility requests */
  openShowEntityModal() {
    const dialogRef = this.dialog.open(EntityDetailsCardComponent, {
      width: '1000px',
      maxWidth: '100vw',
      maxHeight: '90vh',
      panelClass: 'entity-sheet-dialog',
      data: {
        entityId: this.existSiretId
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === "edit") {
        this.router.navigateByUrl(
          "/"+this.directoryStore.widgetLang+"/annuaire/"+this.directoryStore.widgetId+"/entity/"+this.existSiretId
        );
      }
      this.dialogRef.close();
    });
  }

  openVisibilityRequestModal() {
    const dialogRef = this.dialog.open(DirectoryVisibilityRequestValidationDialogComponent, {
      width: '600px',
      maxWidth: '100vw',
      maxHeight: '90vh',
      data: {
        entityId: this.existSiretId,
        widgetId: this.directoryStore.widgetId
      },
      autoFocus: false,
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((requester: Requester) => {});
  }

  /** Add button - UCL/MCL */
  hasSubrequestAdb(field): boolean {
    // If field has an ADB & addsubrequests has been activated & is not an add request exception
    if (this.isNotAddRequestException(field.range)) {
        // Check if there are any configured ADB in subrequests with field ADB
        //    OR
        // If add requests has been activated AND field range points to the concerned ADB
        if (this.config.addSubRequests?.subResourceList.some((resource) => resource.id === field.range) ||
            (this.config.addRequests?.hasAddRequestsPerEmbeddedForm && this.config.concernedAssociatedDB === field.range))
            return true;
        return false;
    }
    return false;
  }

  isNotAddRequestException(fieldRange): boolean {
    let widgetEntityType = this.config.concernedAssociatedDB === "";
    let widgetADBType = this.config.concernedAssociatedDB != "";

    // Context 1 -> widget with add request activated
      // If add request AND subrequests are activated
      //    AND
      // If field has an ADB
    if (this.config.addRequests?.hasAddRequestsPerEmbeddedForm &&
        (this.config.addSubRequests?.hasAddSubRequestsPerEmbeddedForm &&
        (fieldRange != null || fieldRange != "")))
      return true;

    // Context 2 -> widget without add request activated AND subrequest activated
      // If entity type, button will appear only if ADB has been configurated in subrequests
      // If adb type, as add request contains widget's form configuration, we don't have any configured fieldsgroup for subrequests
    // IF add request is not activated AND subrequests is activated
    //    AND
    //    IS an entity type
    //        OR
    //    IS an adb type & field range is not equal to concernedAssociatedDB
    else if (!this.config.addRequests?.hasAddRequestsPerEmbeddedForm &&
        this.config?.addSubRequests?.hasAddSubRequestsPerEmbeddedForm &&
        (widgetEntityType ||
          (widgetADBType && this.field.range != this.config.concernedAssociatedDB)))
      return true;

    else
      return false;
  }

  /** Cross binding for subrequests */
  getNewElement(event) {
    if (this.field.type === FieldType.UniqueChoiceList) {
      this.modificationRequestForm.get('newFieldValue').patchValue(event.element_id);
      this.isLuChecked(event);
    } else if (this.field.type === FieldType.MultipleChoiceList)
      this.toggleAdbElement(event);

    this.directoryEmbedRequestService.formatNewElement(event, this.field);
  }

  ngOnDestroy(): void {
    this.unsubscribe.forEach((subs) => {
      if (subs) subs.unsubscribe()
    });
  }
}

interface MultipleEntitiesValues {
  [key: string]: string;
  entity_id: string;
}

interface LinkedChoiceValues {
  field: any;
  field_value: any;
  parent_value: any;
}
