import { OpenedElement } from './../../models/directory-opened-element.model';
import { DirectorySearchService } from './../../services/directory-search/directory-search.service';
import { DirectoryAppliedFilter } from './../../models/directory-applied-filter.model';
import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DirectorySharedDataService } from 'src/app/directory/services/directory-shared-data.service';
import { DirectoryConfig } from 'src/app/directory/models/directory-config.model';
import { DirectoryEntitySummary } from 'src/app/directory/models/directory-entity-summary.model';
import { MatDialog } from '@angular/material/dialog';
import { DirectoryDataSourceComponent } from '../directory-data-source/directory-data-source.component';
import { DirectoryEntityCreationDialogComponent } from '../dialog/directory-entity-creation-dialog/directory-entity-creation-dialog.component';
import { interval, Subscription } from 'rxjs';
import { DirectoryResultsStoreService } from '../../services/directory-results-store/directory-results-store.service';

@Component({
  selector: 'app-directory-list',
  templateUrl: './directory-list.component.html',
  styleUrls: ['./directory-list.component.scss'],
})
export class DirectoryListComponent implements OnInit, OnDestroy{
  public widgetId: string;
  public widgetLang: string;
  public widgetConfig: DirectoryConfig;
  public hasListDisplayed: boolean;
  public hasMapDisplayed: boolean;
  public itemSize: number;
  public virtualFactor: number = 1;

  public resultsList: DirectoryEntitySummary[];
  private currentOpenedEntityId: DirectoryEntitySummary['entity_id'];
  public filtersApplied: DirectoryAppliedFilter[];
  public nbResults: number;
  public allFields: boolean = true;
  public maxSize: number = 60;
  public resultSubscription: Subscription = null;

  public resultsInterval$ = interval(3);
  public subscriptionListOpenedElement: Subscription;

  @Output() emitForUpdateMap = new EventEmitter<boolean>();

  constructor(
    private resultsStore: DirectoryResultsStoreService,
    private directoryData: DirectorySharedDataService,
    private searchService: DirectorySearchService,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.widgetId = this.directoryData.widgetId;
    this.widgetLang = this.directoryData.widgetLang;
    this.directoryData.getHasListDisplayed()
      .subscribe((state) => {
        this.hasListDisplayed = state;      
    });
    this.directoryData.getHasMapDisplayed()
      .subscribe((state) => {
        this.hasMapDisplayed = state;
    });
    this.directoryData.getDirectoryConfig().subscribe((config) => {
        this.widgetConfig = config;
    });

    if (this.subscriptionListOpenedElement == null) {
      this.subscriptionListOpenedElement = this.resultsStore.getOpenedElement().subscribe((openedElement: OpenedElement) => {
        this.currentOpenedEntityId = openedElement ? openedElement.id : null;
      });
    }

    if (this.resultSubscription == null) {
      // console.log("noresultSubscriptionDirectoryList")
      this.resultSubscription = this.searchService.getSearchResults()
      // .pipe(
      //   filter((results) => {
      //     if(results) return true;
      //     return false;
      //   })
      // )
      .subscribe(x => {
        this.resultsList = [];
        this.nbResults = x.length;

        let tmpItemSize = 63;
        // init virtualscroll size
       // this.itemSize = 63;
        if (this.widgetConfig.summary.description.id != '') {
          tmpItemSize = tmpItemSize + 60;
          // this.computeSize(x);
          this.allFields = true; // has to fix the min height in px to makes virtual scroll morks
        } else if (this.widgetConfig.summary.logo.id != '') {
          tmpItemSize = 83;
        }
        if(this.widgetConfig.layout.hasLegend) tmpItemSize = tmpItemSize + 17;


        if(this.widgetConfig.layout.hasThumbnail) {
          this.allFields = false; // hasThumbnail allready has fixed size
          tmpItemSize = 86; // 2x ligne in list
          this.virtualFactor = 16;
          if (!this.hasMapDisplayed) {
            tmpItemSize = 43; // 4x ligne in list
            this.virtualFactor = 32;
          }
        }

        this.itemSize = tmpItemSize;

        this.resultsList = x ;
      });
    }
  }

  public hideList(): void {
    this.emitForUpdateMap.emit(true)
    this.directoryData.changeListState(false);
  }

  public computeSize(elements) {
    // basic logic : average
    let size = elements.length;
    if (size > 0) {
      return elements.map( element => element[this.widgetConfig.summary.description.id] ? element[this.widgetConfig.summary.description.id].length : 0).map( charSize => {
        if (charSize > 104) return 60
        if (charSize > 52) return 40
        if (charSize > 0) return 20
        return 0
      })
      .reduce( (vHeight, otherVHeight) => vHeight + otherVHeight) / size
      // 157 with ' ...' equals 60
      // 104 -> 40
      // 52 equals 20
      // 0 0
    }

    return 0;

  }

  public openDataSourceDialog(): void {
    let dialogRef = this.dialog.open(DirectoryDataSourceComponent, {
      minHeight: '200px',
      minWidth: '200px'
    });
  }

  public canListbeHidden(): boolean {
    if (this.hasMapDisplayed && this.hasListDisplayed) {
      return true;
    }
    return false;
  }

  public isListOnTheLeft(): boolean {
    if (this.widgetConfig?.layout.isListOnLeft) {
      return true;
    }
    return false;
  }

  public isSheetOpen(): boolean {
    if (this.currentOpenedEntityId) {
      return true;
    }
    return false;
  }

  public areListAndMapAvailable(): boolean {
    if (
      !this.widgetConfig?.layout.core.hasList ||
      !this.widgetConfig?.layout.core.hasMap
    ) {
      return false;
    }
    return true;
  }

  public toggleOnMap(): void {
    this.directoryData.changeMapState(true);
  }
  public toggleOffMap(): void {
    this.directoryData.changeMapState(false);
    this.directoryData.changeListState(true);
  }
  public openCreationRequestDialog() {
    let contactEmail;
    if (this.widgetConfig.addRequests && this.widgetConfig.addRequests.emailForCreationRequests) {
      contactEmail = this.widgetConfig.addRequests.emailForCreationRequests
    } else {
      contactEmail = this.widgetConfig.responsibleEmail;
    }
    if (contactEmail) {
      const dialogRef = this.dialog.open(DirectoryEntityCreationDialogComponent, {
        width: '600px',
        maxWidth: '100vw',
        maxHeight: '90vh',
        data: {
          contact_email: contactEmail
        },
        autoFocus: false,
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe((success: string) => {});
    }
  }
  public itemsTrackedBy(index, item) {
    return item.entity_id;
  }

  ngOnDestroy():void {
    // console.log("destroyListComp")
    this.resultSubscription.unsubscribe();
    this.subscriptionListOpenedElement.unsubscribe();
  }
}

