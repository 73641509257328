<div class="directory-marker-preview-wrapper" *ngIf="currentEntitySummary">
  <div class="directory-marker-preview-logo">
    <img *ngIf="currentEntitySummary[widgetConfig.summary.logo.id]" [src]="currentEntitySummary[widgetConfig.summary.logo.id]" [alt]="currentEntitySummary[widgetConfig.summary.title.id] + ' logo'">
  </div>
  <!-- <p class="directory-marker-preview-title" ellipsis #ell="ellipsis">{{ currentEntitySummary[widgetConfig.summary.title.id] }}</p> -->
  <p class="directory-marker-preview-title" *ngIf="currentEntitySummary[widgetConfig.summary.title.id]; else linkeditem">{{ currentEntitySummary[widgetConfig.summary.title.id] }}</p>

  <ng-template #linkeditem>
    <p class="directory-marker-preview-title" *ngIf="currentEntitySummary[CONSTANTS.name]">{{ currentEntitySummary[CONSTANTS.name] }}</p>
    <p class="directory-marker-preview-title" *ngIf="currentEntitySummary[CONSTANTS.mainName]">{{ currentEntitySummary[CONSTANTS.mainName] }}</p>
    <ng-container *ngIf="currentEntitySummary['links']">
      <span *ngFor="let link of objectValues(currentEntitySummary['links'])" class="linked-name"><span class="material-icons" [style.color]="link.color">minimize</span>{{link.name}} </span>
    </ng-container>
  </ng-template>

  <!-- <p class="directory-marker-preview-address">{{ currentEntitySummary[CONSTANTS.address] }}</p> -->
</div>
