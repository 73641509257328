import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-directory-ask-leave-dialog',
  templateUrl: './directory-ask-leave-dialog.component.html',
  styleUrls: ['./directory-ask-leave-dialog.component.scss'],
})
export class DirectoryAskLeaveDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DirectoryAskLeaveDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public translateService: TranslateService,
  ) {}

  ngOnInit(): void {
  }

  public stay(): void {
    this.dialogRef.close(false);
  }

  public confirmLeave(): void {
    this.dialogRef.close(true);
  }

}
