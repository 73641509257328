
import { Component, Input, OnInit } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, filter, map, skip, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

import { DirectoryConfig, DirectoryFieldForFilter } from 'src/app/directory/models/directory-config.model';

import { DirectorySharedDataService } from 'src/app/directory/services/directory-shared-data.service';
import { DirectorySearchService } from './../../services/directory-search/directory-search.service';
import { DirectoryFiltersStoreService } from 'src/app/directory/services/directory-filters-store/directory-filters-store.service';

@Component({
  selector: 'app-directory-filters',
  templateUrl: './directory-filters.component.html',
  styleUrls: ['./directory-filters.component.scss'],
})
export class DirectoryFiltersComponent implements OnInit {
  public isSmallScreen: boolean;
  public isFullscreen: boolean = false;
  public widgetConfig: DirectoryConfig;

  public hasListDisplayed: boolean;
  keywordForm: FormGroup;

  public mainFiltersList: DirectoryFieldForFilter[] = [];
  public asideFiltersList: DirectoryFieldForFilter[] = [];
  public nbAvailableSpots: number;
  public isSmallWithMain: boolean = false;

  private appliedFilters: string;
  private appliedLegends: any;

  public criterias: any = {};
  public criteriaFilter: string = null;
  public criteriaValue: string = null;
  public criteriaFiltersArray: string[] = []; // We'll use this array to check if id has already been used
  public criteriaValuesArray: string[] = [];

  @Input() criteriaFilters: string;

  constructor(
    public breakpointObserver: BreakpointObserver,
    private directoryData: DirectorySharedDataService,
    private filtersStore: DirectoryFiltersStoreService,
    private directorySearch: DirectorySearchService,
    private fb: FormBuilder,
    public dialog: MatDialog
  ) {
    if (window.innerWidth <= 830) {
      this.isSmallScreen = true;
    }
    this.breakpointObserver.observe([
      "(max-width: 830px)", "(max-width: 699px)", "(max-width: 599px)", "(max-width: 499px)", "(max-width: 399px)"
    ]).subscribe((result: BreakpointState) => {
      if (result.matches) {
        this.isSmallScreen = true;    
      } else {
        this.isSmallScreen = false;
      }
    });
  }

  ngOnInit(): void {
    this.isSmallWithMain = false;

    this.directoryData
      .getHasListDisplayed()
      .subscribe((state) => (this.hasListDisplayed = state));
    this.directoryData
      .getDirectoryConfig()
      .pipe(
        filter((config: DirectoryConfig) => {if (config) {return true; }}),
        tap((config: DirectoryConfig) => (this.widgetConfig = config)),
        filter((config: DirectoryConfig) => {if (config.filters && config.filters.list) {return true; }}),
        map((config: DirectoryConfig) => config.filters.list),
        map((filterList: DirectoryFieldForFilter[]) => {
          this.asideFiltersList = filterList.filter((currentFilter: DirectoryFieldForFilter) => currentFilter.isMainFilter === false);
          return filterList.filter((currentFilter: DirectoryFieldForFilter) => currentFilter.isMainFilter === true);
        })
      )
      .subscribe((filterList: DirectoryFieldForFilter[]) => {
        this.mainFiltersList = filterList;
      });
    this.filtersStore.getAvailableMainSpots().pipe(
        skip(0)
      ).subscribe(x => {
        this.nbAvailableSpots = x;
        
        if (this.mainFiltersList.length > 0 &&
          (this.mainFiltersList.length > this.nbAvailableSpots)) this.isSmallWithMain = true;
        else this.isSmallWithMain = false;
      });

    this.keywordForm = this.fb.group({
      keywords: new FormControl(''),
    });
    this.keywordForm
      .get('keywords')
      .valueChanges.pipe(
        debounceTime(800),
        tap((val: string) => {
          this.searchNewKeywords(val);
        })
      )
      .subscribe();
    this.filtersStore.getAppliedFilters()
      .pipe(
        tap(x => {
          this.appliedFilters = this.filtersStore.filtersAsCriteria(x);
          this.appliedLegends = this.filtersStore.findActivatedLegends(x);
        })
      )
      .subscribe();

    // If there is any criteria in our URL, we must extract it and send it to any active filters
    if (this.criteriaFilters != null) {
      this.convertCriteriaToValues(this.criteriaFilters);
    }
  }

  private updateScreenBreakpoints() {
    this.isSmallScreen = this.breakpointObserver.isMatched(
      '(max-width: 599px)'
    );
  }

  public searchNewKeywords(keywords: string) {
    this.directorySearch.setKeywords(keywords);
    this.directorySearch.findResults(this.appliedFilters, this.appliedLegends);
  }

  public resetKeywords() {
    this.keywordForm.reset();
  }

  public toggleFullscreen(): void {
    if (
      document.documentElement.requestFullscreen &&
      !document.fullscreenElement
    ) {
      document.documentElement.requestFullscreen();
      this.isFullscreen = true;
    } else if (document.exitFullscreen && document.fullscreenElement) {
      document.exitFullscreen();
      this.isFullscreen = false;
    }
  }

  // Revert criteria string so we can give the selected criteria values to filter inputs
  convertCriteriaToValues(rawString: string) {
    // Cut the criteria string using the logic operators AND/OR
    let rawArray = rawString.split(" AND ");

    // If AND -> add next substring
    let andArray = rawArray.filter(array => !array.includes(" OR "));
    andArray.forEach((array) => {
      this.extractCriterias(array, false);
    });

    // If OR -> skip next substring except for those with OML/MCL types
    if (rawArray.some(array => array.includes(" OR "))) {
      let orRawArray = rawArray.filter(array => array.includes(" OR "));
      let orArray = [];
      orRawArray.forEach(item => { 
        if (item.includes(" OR ") && !orArray.find(x => x === item)) {
          orArray = item.split(" OR ");
        }

        orArray.forEach((array) => {
          this.extractCriterias(array, true, orArray);
        });
      });

      // Object to bind and send to filter component (OR criteria), list types only
      if (this.criteriaValuesArray.length > 0) {
        this.criterias[this.criteriaFilter] = this.criteriaValuesArray;
      }
    }
  }

  extractCriterias(array: string, or: boolean, orArray?: string[]) {
    const idRegex = /([0-9a-z]{8}\-[0-9a-z]{4}\-[0-9a-z]{4}\-[0-9a-z]{4}\-[0-9a-z]{12}(\_)([^ %]*))/;
    const idCoreRegex = /(core)(\_)([^ %]*)/;
    // For numeric/float/date types, we'll use their specific constrains
    let checkOperators = Object.values(ConstraintOperators).some(x => array.includes(x));
    let criteriaArray = [];

    // Use only those with specific operators
    if (checkOperators || array.includes("contains")) {
      criteriaArray = array.split(/\s(.*)/).filter(x => x);
    }

    if (criteriaArray.length > 0) {
      for (const criteria of criteriaArray) {
        if (idRegex.test(criteria) || idCoreRegex.test(criteria)) {
          // Extract filter ID
          this.extractFilterId(criteria);
        } else {
          // Extract value
          this.extractFilterValue(criteria, or, orArray);
        }
      }
    }
  }

  extractFilterId(criteria: string) {
    let criteriaAdbIds = [];

    if (!this.criteriaFiltersArray.find(x => x === criteria)) {
      this.criteriaFiltersArray.push(criteria);
    }
    this.criteriaFilter = criteria;

    // When ADB, we must select the ID differently
    if (criteria.includes(".")) {
      criteriaAdbIds = criteria.split(".");
      // Second part is the associated db filter ID
      this.criteriaFilter = criteriaAdbIds[1];
      this.criteriaFiltersArray.push(criteriaAdbIds[1]);
    }
  }

  extractFilterValue(criteria: string, or: boolean, orArray?: string[]) {
    const elementRegex = /^([0-9a-z]{8}\-[0-9a-z]{4}\-[0-9a-z]{4}\-[0-9a-z]{4}\-[0-9a-z]{12})$/;
    let hasIdListed = this.criteriaFiltersArray.some(x => x === this.criteriaFilter);
    this.criteriaValue = criteria;

    // Tmp value (without operator) used for testing value types
    let criteriaValuesWithoutOperator = 
      criteria
        .split(" ")
        .filter((item) => !Object.values(ConstraintOperators).find(element => item.includes(element)))
        .toString();
    criteriaValuesWithoutOperator = criteriaValuesWithoutOperator.substring(1, criteriaValuesWithoutOperator.length - 1);

    // Object to bind and send to filter component (AND criteria)
    if (hasIdListed) {
      if (!or) this.criterias[this.criteriaFilter] = this.criteriaValue;

      // If value is not an element_id but:
        // - its filter ID is unique
        // - it is the first of the OR array (that means that it's after an AND, so we must save it)
      if (or && orArray[0].includes(this.criteriaFilter) && 
          !elementRegex.test(criteriaValuesWithoutOperator)) {
        this.criterias[this.criteriaFilter] = this.criteriaValue;
      // If value is an element_id, save it
      } else if (or && orArray[0].includes(this.criteriaFilter) &&
          elementRegex.test(criteriaValuesWithoutOperator)
          && !this.criteriaValuesArray.find(x => x.includes(criteriaValuesWithoutOperator))) {
        this.criteriaValuesArray.push(this.criteriaValue);
      }
    }
  }
}

export enum ConstraintOperators {
  EQUAL = 'equalTo',
  GREATER_THAN = 'greaterThan',
  GREATER_THAN_OR_EQUAL = 'greaterThanOrEqualTo',
  LESS_THAN = 'lessThan',
  LESS_THAN_OR_EQUAL = 'lessThanOrEqualTo',
  NOT_EQUAL = 'notEqualTo',
  CONTAINS = 'contains'
}