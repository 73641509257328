import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DirectoryUtilsService {
  public urlSource: string = null;

  constructor() { }

  setSheet(addedLink: string): void {
    // Create link
    let link = document.createElement('link');
    link.href = addedLink;
    link.href += "?cachebust="+ Math.trunc(Math.random() * 1000000);
    link.rel = 'stylesheet';
    link.type = 'text/css';

    let head = document.getElementsByTagName('head')[0];
    let links = head.getElementsByTagName('link');
    let style = head.getElementsByTagName('style')[0];

    // Check if the same style sheet has been loaded already.
    let isLoaded = false;
    for (let i = 0; i < links.length; i++) {
      let node = links[i];
      if (node.href.indexOf(link.href) > -1) {
        isLoaded = true;
      }
    }
    if (isLoaded) return;
    head.insertBefore(link, style);
  }

  isHostAuthorized(hosts: string[]): boolean | void {
    const whitelist = [
      'http://www.craft.local:5555',
      'http://www.craft.local:4200',
      'https://front.craft.conjecto.dev',
      'https://preprod-front.craftv5.bdi.fr',
      'https://front.craftv5.bdi.fr',
      'https://sandbox-front.craftv5.bdi.fr',
      'https://preprod-front.craft.bdi.fr',
      'https://front.craft.bdi.fr',
      'https://sandbox-front.craft.bdi.fr',
    ];
    // console.log('self', self.location.href);
    // console.log('referer', document.referrer); // Chrome differs with firefox here
    // console.log('formatted', this.formatHostname(document.referrer));

    this.urlSource = document.referrer;

    let formattedHostname = this.formatHostname(document.referrer);
    if (
      self.location.href !== document.referrer &&
      hosts.indexOf(formattedHostname) < 0 &&
      whitelist.indexOf(formattedHostname) < 0
    ) {
      window.location.replace('https://www.platform-craft.eu/');
      return false;
    } else {
      return true;
    }
  }

  private formatHostname(url) {
    const matches = url.match(/^(https?\:\/\/[^\/?#]+)(?:[\/?#]|$)/i);
    return matches && matches[1];
  }

  getElementId(el: {[key:string]: any}): string {
    if(el.entity_id) return el.entity_id;
    else if(el.element_id) return el.element_id;
    else return null;
  }

  findPropertyInElement(constantsNameForProperty: string, el: {[key:string]: any}, possibleSubProperties: {[key:string]: string[]}): string {
    if(!possibleSubProperties) { // retro-compat
      if(constantsNameForProperty === "core_hasLatitude") return "6cb44afb-56e5-4b8b-9b1c-4df8c155d545_hasLatitude";
      if(constantsNameForProperty === "core_hasLongitude") return "6cb44afb-56e5-4b8b-9b1c-4df8c155d545_hasLongitude";
    }

    if(!el) return null;

    // 1. Test remaining subproperties on item to find the right one
    let expectedKey;
    possibleSubProperties[constantsNameForProperty].forEach(possibleKey => {
      if(el.hasOwnProperty(possibleKey)) expectedKey = possibleKey;
    });

    return expectedKey;

  }

  public orderList(elements) {
    let hashArr = {};
    let isHierarchical = false;
    hashArr['undefined'] = [];
    for (let i=0; i < elements.length; i++) {
      if ('core_hasParentValue' in elements[i]) {
         if (!(elements[i].core_hasParentValue.element_id in hashArr)) hashArr[elements[i].core_hasParentValue.element_id] = [];
         hashArr[elements[i].core_hasParentValue.element_id].push(elements[i]);
         isHierarchical = true;
      } else {
         hashArr['undefined'].push(elements[i]);
      }
    }
    var result = this.hierarchySort(hashArr, 'undefined', [], 0);

    elements = result;
    return [elements, isHierarchical];
  }

  public  hierarchySortFunc(a,b ) {
    return a.core_hasOrder - b.core_hasOrder;
  }

  public  hierarchySort(hashArr, key, result, depth) {
    if (!(key in hashArr)) return;
    let arr = hashArr[key].sort(this.hierarchySortFunc);
    for (let i=0; i < arr.length; i++) {
      arr[i].depth = depth;
      result.push(arr[i]);
      this.hierarchySort(hashArr, arr[i].element_id, result, depth+1);
    }
    return result;
  }

}
