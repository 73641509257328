import { CONSTANTS } from './../../../../environments/constants';
import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { DirectorySharedDataService } from 'src/app/directory/services/directory-shared-data.service';
import { DirectoryEntitySummary } from 'src/app/directory/models/directory-entity-summary.model';
import { DirectoryConfig } from '../../models/directory-config.model';
import { DirectorySearchService } from '../../services/directory-search/directory-search.service';
import { DirectoryResultsStoreService } from '../../services/directory-results-store/directory-results-store.service';
import { combineLatest } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-directory-marker-preview',
  templateUrl: './directory-marker-preview.component.html',
  styleUrls: ['./directory-marker-preview.component.scss'],
})
export class DirectoryMarkerPreviewComponent implements OnInit {
  public CONSTANTS = CONSTANTS;
  public currentEntityId: DirectoryEntitySummary['entity_id'];
  public results: DirectoryEntitySummary[];
  public currentEntitySummary: DirectoryEntitySummary;
  public widgetConfig: DirectoryConfig;
  public objectValues = Object.values;

  @ViewChild('ell') ell: any;
  constructor(
    private resultsStore: DirectoryResultsStoreService,
    private directoryData: DirectorySharedDataService,
    private directorySearchService: DirectorySearchService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.directorySearchService
      .getSearchResults()
      .subscribe((res: any) => {
        this.results = res;
        this.changeDetectorRef.detectChanges();
      });
    this.directoryData
      .getDirectoryConfig()
      .subscribe((config) => (this.widgetConfig = config));

    this.resultsStore
      .getFocusedElementData()
      .subscribe((data: any) => {
        this.currentEntitySummary = data;
        this.changeDetectorRef.detectChanges();
      });
    this.resultsStore
      .getOpenedElementData()
      .subscribe((data: any) => {
        this.currentEntitySummary = data;
        this.changeDetectorRef.detectChanges();
      });


    // combineLatest([
    //   this.resultsStore.getFocusedElementData(),
    //   this.resultsStore.getOpenedElementData()])
    //   .pipe(
    //     tap(([hoveredElement, clickedElement]) => {
    //       if(clickedElement) this.currentEntitySummary = clickedElement;
    //       else this.currentEntitySummary = hoveredElement;

    //       this.changeDetectorRef.detectChanges();
    //     })
    //   ).subscribe();
  }
}
