import { DirectoryConfig } from './../../../models/directory-config.model';
import { Component, Input, } from '@angular/core';
import { concatMap } from 'rxjs/operators';
import { DirectoryResultsStoreService } from 'src/app/directory/services/directory-results-store/directory-results-store.service';
import { DirectorySearchService } from './../../../services/directory-search/directory-search.service';
import { DirectorySharedDataService } from './../../../services/directory-shared-data.service';
import { DirectoryUtilsService } from 'src/app/directory/services/directory-utils/directory-utils.service';

@Component({
  selector: 'app-entity-link',
  templateUrl: './entity-link.component.html',
  styleUrls: ['./entity-link.component.scss']
})
export class EntityLinkComponent {
  @Input() id: string;
  @Input() label: string;
  @Input() trueLink: boolean = false;
  public widgetConfig: DirectoryConfig;

  constructor(
    private directorySearch: DirectorySearchService,
    private directoryStore: DirectorySharedDataService,
    private resultsStore: DirectoryResultsStoreService,
    private utils: DirectoryUtilsService
  ) { }

  showEntityPanel() {
    // need to call a secondary panel
    this.directoryStore.getDirectoryConfig().pipe(
      concatMap((config: DirectoryConfig) => {
        this.widgetConfig = config;
        return this.directorySearch.findEntityData(this.id, true);
      }),
    ).subscribe((entity) => {
      this.resultsStore.setFocusedElement({
        id: this.utils.getElementId(entity),
        range: null,
        isMain: false
      });
      this.resultsStore.setFocusedElementData(
        entity
      );
      this.resultsStore.setOpenedElement({
        id: this.utils.getElementId(entity),
        range: null,
        isMain: false
      });
      this.resultsStore.setOpenedElementData(
        entity
      );
    });
  }

}
