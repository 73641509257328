import { DirectoryAggregation, DirectoryAggregations } from './../../models/directory-aggregation.model';
import { DirectoryAggregationsStoreService } from './../directory-aggregations-store/directory-aggregations-store.service';
import { DirectoryConfig, DirectoryLegendDataGroup } from './../../models/directory-config.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { DirectoryEntitySummary } from '../../models/directory-entity-summary.model';
import { DirectorySharedDataService } from '../directory-shared-data.service';
import { OrderByPipe } from 'ngx-pipes';

interface BackResponse {
  elements?: DirectoryEntitySummary[];
  entities?: DirectoryEntitySummary[];
  aggregations?: DirectoryAggregations;
}

@Injectable({
  providedIn: 'root'
})
export class DirectorySearchService {
  private concernedSubject: string = 'entities';
  private concernedAssociatedDB: string;
  public keywordsQuery: string;
  public widgetConfig: DirectoryConfig;
  public widgetLang: string;
  private httpHeaders: any;
  public setParamCriteriaString: string = null;
  public lastFindResult: Date;

  private searchResults = new BehaviorSubject<DirectoryEntitySummary[]>([]);

  constructor(
    private http: HttpClient,
    private aggregationsStore: DirectoryAggregationsStoreService,
    private directoryData: DirectorySharedDataService,
    private orderByPipe: OrderByPipe,
  ) {}

  public getSearchResults(): Observable<DirectoryEntitySummary[]> {
    return this.searchResults.asObservable();
  }
  public setSearchResults(newResults): void {
    this.searchResults.next(this.preformatResults(newResults));
  }

  public preformatResults (newResults){
    // latitude longitude ? prepare results for html optimization 
    newResults.forEach(item => {
      if('element_id' in item) item.entity_id = item.element_id;
      if('legendList' in item && item.legendList.length > 0) {
        item['legendIcons'] = []; // add legend icons

        item.legendList.forEach(legendTitle => {
          item['legendIcons'].push(this.widgetConfig.legend.groups.find((x) => x.title === legendTitle))
        });
      }
    })

    let titleFieldInList = this.widgetConfig.summary.title.code;

    if(this.keywordsQuery) {
      newResults = this.orderByPipe.transform(newResults, ['-score', titleFieldInList]);
    }
    else {
      newResults = this.orderByPipe.transform(newResults, titleFieldInList);
    }

    return newResults;
  }

  public findAllEntities() {
    const headers = this.httpHeaders;
    let url = `${environment.apiUri}/v5/${this.widgetLang}/entities/`;
    return this.http.get(
      url,
      { headers }
    );
  }

  public findEntitiesByIds(ids: string[], fields?: string[]): Observable<BackResponse> {
    const headers = this.httpHeaders;
    let url: string = `${environment.apiUri}/v5/${this.widgetLang}/entities/`;

    // Add ids to query:
    const idsSuffix: string = "?group=" + ids.join() + "";
    url = url + idsSuffix;

    // Add fields to query
    if (fields && fields.length > 0) {
      const fieldsSuffix: string = "&fields=" + fields.join() + "";
      url = url + fieldsSuffix;
    }

    return this.http.get(url, {headers});
  }

  public setHeaders(key: string) {
    this.httpHeaders = new HttpHeaders().set('Authorization', 'API ' + key);
  }

  public setConcernedSubject(isFocusOnAssociatedDB: boolean, concernedAssociatedDB?: string) {
    if (isFocusOnAssociatedDB) {
      this.concernedSubject = 'associated-data';
      this.concernedAssociatedDB = concernedAssociatedDB;
    } else {
      this.concernedSubject = 'entities';
    }
  }

  public setKeywords(keywords) {
    this.keywordsQuery = keywords;
  }

  public findResults(criteriaForFilters?: string, legends?: any[]) {
    // legends -> user's input
    // lastFindResult is used to prevent setSearchResults if older request was slower than the last findReseults emited
    this.lastFindResult = new Date();
    this.directoryData.getDirectoryConfig()
    .pipe(
      switchMap(widgetConfig => {
        const thisAsing = new Date(this.lastFindResult);
        this.widgetConfig = widgetConfig;

        if (widgetConfig.layout.hasLegend) {
          return combineLatest([this.mergeRequestsForLegend(widgetConfig, criteriaForFilters, legends),of(thisAsing)]);
        }
        return combineLatest([this.simpleRequest(criteriaForFilters),of(thisAsing)]);
      }),
      take(1),
      tap(([results,thisAsing]) => { 
        // prevent setSearchResults if request was slower than the last findReseults emited
        if (!(thisAsing < this.lastFindResult)) {
          this.setSearchResults(results);
        }
      })
    ).subscribe();
  }

  public findResultsForVisibility() {
    return this.directoryData.getDirectoryConfig()
    .pipe(
      switchMap(widgetConfig => {
        let legends = [];
        widgetConfig.legend.groups.every(title => legends.push(title.title));

        if (widgetConfig.layout.hasLegend) {
          return this.mergeRequestsForLegend(widgetConfig, undefined, legends);
        }
        return this.simpleRequest();
      }),
      take(1),
      map((results) => {
        return this.preformatResults(results);
      })
    );
  }

  public findEntityData(entityId: string, forceEntity = false) {
    return this.unitRequest(entityId, forceEntity);
  }

  private mergeRequestsForLegend(config: DirectoryConfig, criteriaForFilters: string, legends: any[]): Observable<any> {
    const legendList: DirectoryLegendDataGroup[] = [];
    const observableList = [];

    let filteredLegends = config.legend.groups;
    if(legends) filteredLegends = filteredLegends.filter(x => legends.includes(x.title)); // remove requests for legends that are not checked (if some are checked)
    // build mega request for aggregations
    let legendsCriteria = "(";
    filteredLegends.forEach((group, index) => {
      if (group.association && group.association.criteria) {
        if(index > 0) legendsCriteria += " OR ";
        legendsCriteria += group.association.criteria;
      }
    });
    legendsCriteria += ")";
    observableList.push(
      this.findAggregations(
        this.mergeLegendCriteriaAndFiltersCriteria(legendsCriteria, criteriaForFilters)
      )
    );
    // add separate legend request to requests array
    filteredLegends.forEach(group => {
      if (group.association && group.association.criteria) {
        legendList.push(group);
        observableList.push(
          this.findBasicResults(
            this.mergeLegendCriteriaAndFiltersCriteria(group.association.criteria, criteriaForFilters)
          )
        );
      }
    });
    return forkJoin(observableList).pipe(
      map((responses: [BackResponse]) => {
        //first response in the superRequest for aggregations only
        this.aggregationsStore.changeAppliedAggregations(responses[0].aggregations);

        // Bind legend data to each entity in each response
        const newresponses = responses;
        newresponses.shift(); // remove superRequest for the work on results
        newresponses.forEach((res: BackResponse, index) => {
          const objects = res.entities || res.elements;
          // all code use the entities entry
          if (!res.hasOwnProperty('entities')) {
            res.entities = res.elements;
            delete res.elements;
          }
          res.entities.forEach(result => {
            if (result.element_id) {
              result.entity_id = result.element_id;
            }
            result.legend = {
              icon: ''
            };
            result.legendList = [legendList[index].title];
            result.legend.colors = [legendList[index].color];
            result.legend.icon = legendList[index].icon;            
            // add new icons
            result.legend.markerChoice = legendList[index].markerChoice;
            result.legend.iconMarker = legendList[index].iconMarker;
            result.legend.iconMarkerColor = legendList[index].iconMarkerColor;
            result.legend.iconInvertMode = legendList[index].iconInvertMode;
            result.legend.iconBorder = legendList[index].iconBorder;
            result.legend.iconBoxShape = legendList[index].iconBoxShape;
          });
        });
        return newresponses;
      }),
      map((responses: [BackResponse]) => {
        let mergedResultsList: DirectoryEntitySummary[] = [];
        responses.forEach(res => {
          mergedResultsList = [...mergedResultsList, ...res.entities];
        });
        return mergedResultsList;
      }),
      map((mergedResultsList: DirectoryEntitySummary[]) => {
        // Remove duplicate in the mergedArray and merge the legendData for each duplicate results
        const newMergedResultsList = [];
        const doneList = [];
        mergedResultsList.forEach((result: DirectoryEntitySummary) => {
          if (!doneList.includes(result.entity_id)) {
            const duplicatesResult = mergedResultsList.filter(x => x.entity_id === result.entity_id);
            if (duplicatesResult.length > 1) {
              const currentResult = duplicatesResult[0];
              duplicatesResult.forEach((duplicate, index) => {
                if (index !== 0) {
                  currentResult.legendList = [...currentResult.legendList, ...duplicate.legendList];
                  currentResult.legend.colors = [...currentResult.legend.colors, ...duplicate.legend.colors];
                }
              });
              newMergedResultsList.push(currentResult);
              doneList.push(currentResult.entity_id);
            }
            else {
              newMergedResultsList.push(result);
            }
          }
        });
        return newMergedResultsList;
      }),
    );
  }

  private findBasicResults(criteria: string): Observable<any> {
    const headers = this.httpHeaders;
    return this.http.get(
      this.buildRequestUrl(criteria),
      { headers }
    );
  }

  private findAggregations(criteria: string): Observable<any> {
    const headers = this.httpHeaders;

    const param = criteria || this.keywordsQuery || this.setParamCriteriaString ? "&onlyAgg=true " : "?onlyAgg=true ";
    return this.http.get(
      this.buildRequestUrl(criteria)+param,
      { headers }
    );
  }

  private simpleRequest(criteria?: string) {
    const headers = this.httpHeaders;
    const observableList = [];
    observableList.push(this.findAggregations(criteria));
    observableList.push(this.findBasicResults(criteria));
    return forkJoin(observableList).pipe(
      map((responses: [BackResponse]) => {
        if(responses[0].aggregations) this.aggregationsStore.changeAppliedAggregations(responses[0].aggregations);
        responses.shift(); // remove agg results
        if (responses[0].elements) return responses[0].elements;
        return responses[0].entities;
      }),
    );
  }

  private unitRequest(id: string, forceEntity = false) {
    const headers = this.httpHeaders;
    return this.http.get(
      this.buildUnitRequestUrl(id, forceEntity),
      { headers }
    );
  }

  public buildRequestUrl(criteria?: string) {
    let url = `${environment.apiUri}/v5/${this.widgetLang}/${this.concernedSubject}`;
    if (this.concernedAssociatedDB) {
      url += `/${this.concernedAssociatedDB}/elements`;
    }
    if (this.keywordsQuery) {
      url += `?`;
      url += `keywords=${this.keywordsQuery}`;
    }
    if (this.keywordsQuery && criteria) {
      url += `&`;
    }
    if (criteria) {
      if (!this.keywordsQuery) url += `?`;
      url += `criteria=${criteria}`;
      if (this.setParamCriteriaString) url += ` AND ${this.setParamCriteriaString}`;
    } else {
      if (this.setParamCriteriaString) {
        if (!this.keywordsQuery) url += `?`;
        url += `criteria=${this.setParamCriteriaString}`;
      }
    }

    return url;
  }

  private buildUnitRequestUrl(id, forceEntity = false) {
    let subject = this.concernedSubject;
    if(forceEntity) subject = "entities";

    let url = `${environment.apiUri}/v5/${this.widgetLang}/${subject}/`;

    if (this.concernedAssociatedDB && !forceEntity) {
      url += `${this.concernedAssociatedDB}/elements/`;
    }
    url += id;
    return url;
  }

  public getAdbElement(adbId: string, elementId: string) {
    if(!adbId || !elementId) return of(null);
    const headers = this.httpHeaders;
    let url = `${environment.apiUri}/v5/${this.widgetLang}/associated-data/${adbId}/elements/${elementId}`;

    return this.http.get(
      url,
      { headers }
    );
  }


  private mergeAggregations(baseObj: DirectoryAggregations, newObj: DirectoryAggregations): DirectoryAggregations {
    let mergedObj = {...baseObj, ...newObj};
    for (const property in mergedObj) {
      if(baseObj.hasOwnProperty(property) && newObj.hasOwnProperty(property)) {
        // conflict
        mergedObj[property] = this.mergeBuckets(baseObj[property], newObj[property])
      }
    }
    return mergedObj;
  }

  private mergeBuckets(aggr1: DirectoryAggregation, aggr2: DirectoryAggregation): DirectoryAggregation {
    let mergedAggr = aggr1;
    mergedAggr.buckets = [...aggr1.buckets, ...aggr2.buckets];
    let uniqueArr = [];
    mergedAggr.buckets.forEach(element => {
      if(!uniqueArr.map(x => x.key).includes(element.key)) {
        uniqueArr.push(element);
      }
    });
    mergedAggr.buckets = uniqueArr;
    return mergedAggr;
  }

  private mergeLegendCriteriaAndFiltersCriteria(legendCriteria: string, filtersCriteria: string): string {
    if(!filtersCriteria) return legendCriteria;
    if(!legendCriteria) return filtersCriteria;

    let mergedCriteria = `((${legendCriteria}) AND ${filtersCriteria})`

    return  mergedCriteria;

  }
}
