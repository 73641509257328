<mat-form-field appearance="outline">
    <mat-label>{{ field[CONSTANTS.name] }}</mat-label>
    <mat-select 
            [value]="selectedValue" 
            (selectionChange)="onFieldChange($event, field)"
            required="{{ field.required }}">
        <mat-form-field appearance="fill" class="search-in-list">
            <mat-label>{{ 'fields.searchInList' | translate }}</mat-label>
            <input matInput (keyup)="onSearch($event.target.value)">
            <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
        
        <mat-option [value]="null">
            {{ 'fields.uniquechoicelist.novalue' | translate }}
        </mat-option>
        <ng-container *ngFor="let elem of filteredList">
            <mat-option [value]="elem.element_id" [disabled]="field.ancestry.length != elem.depth">
                <span [ngClass]="'depth-'+elem.depth">{{ elem[CONSTANTS.name] }}</span>
            </mat-option>
        </ng-container>
    </mat-select>
</mat-form-field>