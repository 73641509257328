import { FocusedElement, OpenedElement } from './../../models/directory-opened-element.model';
import { DirectoryResultsStoreService } from './../../services/directory-results-store/directory-results-store.service';
import { DirectoryFieldsGroupForSheet } from './../../models/directory-config.model';
import { FieldType, isList } from '../../models/fields/field.model';
import { DirectoryUtilsService } from './../../services/directory-utils/directory-utils.service';
import { Component, OnInit, Injector, ComponentFactoryResolver, NgZone, OnDestroy, Output, EventEmitter } from '@angular/core';

import { tileLayer, marker, polyline } from 'leaflet';

import * as L from 'leaflet';
import 'leaflet.markercluster';
import { DirectoryEntitySummary } from 'src/app/directory/models/directory-entity-summary.model';
import { DirectoryMarkerPreviewComponent } from '../directory-marker-preview/directory-marker-preview.component';
import { MatDialog } from '@angular/material/dialog';
import { DirectoryConfig, DirectoryFieldForSheet } from '../../models/directory-config.model';
import { DirectorySharedDataService } from '../../services/directory-shared-data.service';
import { DirectoryIconGeneratorService } from '../../services/directory-icon-generator/directory-icon-generator.service';
import { DirectoryEntityCreationDialogComponent } from '../dialog/directory-entity-creation-dialog/directory-entity-creation-dialog.component';
import { CONSTANTS } from 'src/environments/constants';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { DirectorySearchService } from './../../services/directory-search/directory-search.service';
import { DirectoryDataSourceComponent } from './../directory-data-source/directory-data-source.component';
import { filter, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-directory-map',
  templateUrl: './directory-map.component.html',
  styleUrls: ['./directory-map.component.scss'],
})
export class DirectoryMapComponent implements OnInit, OnDestroy {
  public widgetId: string;
  public widgetLang: string;
  public widgetConfig: DirectoryConfig;
  public hasListDisplayed: boolean;
  public hasLegendDisplayed: boolean;
  public resultsList: DirectoryEntitySummary[];
  public isMapReady: boolean = false;
  public subscriptions: Subscription[] = [];
  public map: any;
  public markerClusterGroup: L.MarkerClusterGroup;
  public markerClusterData: L.Marker[] = [];
  public markerClusterOptions: L.MarkerClusterGroupOptions;
  public markersAvailable: any;
  public options: {
    layers: any[];
    attributionControl: boolean;
  };
  public markers: {};
  public currentEntityId: DirectoryEntitySummary['entity_id'];
  private previewComponent = this.resolver
    .resolveComponentFactory(DirectoryMarkerPreviewComponent)
    .create(this.injector);
  public currentOpenedEntityId: DirectoryEntitySummary['entity_id'];
  public nbResults: number;
  public markersData: any;
  public openedElement: OpenedElement;

  private availableEntities: DirectoryEntitySummary[] = [];

  private FieldType = FieldType;

  @Output() monEmit = new EventEmitter<boolean>();

  constructor(
    private directoryData: DirectorySharedDataService,
    private directorySearchService: DirectorySearchService,
    private resultsStore: DirectoryResultsStoreService,
    private injector: Injector,
    private resolver: ComponentFactoryResolver,
    private zone: NgZone,
    public dialog: MatDialog,
    private imageGen: DirectoryIconGeneratorService,
    private utils: DirectoryUtilsService
  ) {}

  ngOnInit(): void {
    this.widgetId = this.directoryData.widgetId;
    this.widgetLang = this.directoryData.widgetLang;
    this.subscriptions.push(this.directoryData
      .getDirectoryConfig()
      .subscribe((config: DirectoryConfig) => (this.widgetConfig = config))
    );
    this.subscriptions.push(this.directoryData
      .getHasListDisplayed()
      .subscribe((state: boolean) => (this.hasListDisplayed = state))
    );
    this.subscriptions.push(this.directoryData
      .getHasLegendDisplayed()
      .subscribe((state: boolean) => (this.hasLegendDisplayed = state))
    );

    this.subscriptions.push(this.initResults().subscribe()
    );

    this.subscriptions.push(this.resultsStore
      .getFocusedElement()
      .subscribe((focusedElement: FocusedElement) => {
        // console.log("sendmeLots")
        if (focusedElement && focusedElement.id) {
          this.currentEntityId = focusedElement.id;
          this.openPreview(focusedElement.id);
        } else {
          this.currentEntityId = null;
          this.closePreview();
        }
      })
    );

    this.subscriptions.push(this.resultsStore
      .getOpenedElement()
      .pipe(
        // ENTITIES & RESET
        filter((openedElement: OpenedElement) => openedElement === null || !openedElement.range),
        tap((openedElement: OpenedElement) => {
          // console.log("openmeLots")
          if (openedElement && openedElement.id) {
            this.currentOpenedEntityId = openedElement.id;
            this.currentEntityId = openedElement.id;
            this.openedElement = openedElement;
            if(openedElement.isMain) {
              this.initMap([this.resultsList.find(x => x.entity_id === openedElement.id)], true);
            }
            else {
              this.initMap(
                this.findParentsToLinkedElement(openedElement), true, this.openedElement.id
              )
            }

          } else if (this.map && !openedElement) {
            this.currentOpenedEntityId = null;
            this.currentEntityId = null;
            this.openedElement = null;
            this.initMap(this.resultsList);
            this.closePreview();
          }
        })
      )
      .subscribe()
    );

    this.subscriptions.push(this.resultsStore
      .getOpenedElement()
      .pipe(
        // ADB ELEMENTS
        filter((openedElement: OpenedElement) => openedElement !== null),
        filter((openedElement: OpenedElement) => openedElement.range !== null && openedElement.range !== ""),
        tap((adbElement: OpenedElement) => {
          // console.log("openmeLots2")
          this.currentOpenedEntityId = adbElement.id;
          this.currentEntityId = adbElement.id;
          this.openedElement = adbElement;
          if(adbElement.isMain) {
            this.initMap([this.resultsList.find(x => x.entity_id === adbElement.id)], true);
          }
          else {
            this.initMap(
              this.findParentsToLinkedElement(adbElement), true, this.openedElement.id
            )
          }
        })
      )
      .subscribe()
    );
  }

  ngOnDestroy(): void {
      // console.log("destroyInitMap")
      this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  public markerClusterReady(group: L.MarkerClusterGroup) {
    this.markerClusterGroup = group;
  }

  public onMapReady(map: any) {
    if (map && this.markersAvailable) {
      this.map = map;

      const bounds = new L.LatLngBounds(this.markersAvailable);
      this.map.fitBounds(bounds.pad(0.1));
    }
  }

  public initResults(): Observable<any> {
    if(this.widgetConfig && this.widgetConfig.map && this.widgetConfig.map.hasLinkedObjectOnMap) {
      // Need to load main results (either (legended) entities or ADB elements) + all entities for linked items
      return combineLatest([
        this.directorySearchService.getSearchResults(),
        this.directorySearchService.findAllEntities()
      ])
      .pipe(
        tap(([searchResults, allEntities]: [any, any]) => {
          // console.log('mapInitResults-linked')
          if(searchResults && allEntities) {
            this.availableEntities = allEntities.entities;
            if(searchResults && searchResults.length > 0) {
              this.resultsList = searchResults;
              this.nbResults = this.resultsList.length;
              this.initMap(this.resultsList);
            }
          }
        })
      )
    }
    else {
      // No linked elements -> Only load main elements
      return this.directorySearchService.getSearchResults().pipe(
        tap(searchResults => {
          // console.log('mapInitResults-nolinked')
          this.isMapReady = false;
          if(searchResults && searchResults.length > 0) {
            this.resultsList = searchResults;
            this.nbResults = this.resultsList.length;
            this.initMap(this.resultsList);
          }
        })
      );
    }
  }

  public initMap(resultsList: DirectoryEntitySummary[], forceLinksDisplay: boolean = false, openedElement: string = null) {
    const cluster: any = [];
    this.markersAvailable = [];
    this.markers = {};


    // console.log("trueInitMap")
    this.previewComponent = this.resolver
      .resolveComponentFactory(DirectoryMarkerPreviewComponent)
      .create(this.injector);

    let linkedItemProperties = this.identifyLinkedItemsProperties(this.widgetConfig);

    resultsList.forEach((result) => {
      let lat = this.utils.findPropertyInElement(CONSTANTS.latitude, result, this.widgetConfig.subProperties);
      let lng = this.utils.findPropertyInElement(CONSTANTS.longitude, result, this.widgetConfig.subProperties);
      let noGeoCoordForLinkedItem = 0;
      if (lat && lng) {
        // Generating the main marker
        this.markers[this.utils.getElementId(result)] = this.generateMarker(result, lat, lng, true, this.widgetConfig.concernedAssociatedDB, null, null);
        cluster.push(this.markers[this.utils.getElementId(result)]);
        this.markersAvailable.push([result[lat], result[lng]]);

        if(linkedItemProperties && linkedItemProperties.length > 0) {
          linkedItemProperties.forEach(linkedItemProperty => {
            if(linkedItemProperty.code && result) {
              let linkedItemsForThisProperty = result[linkedItemProperty.code];
              if(linkedItemsForThisProperty) {
                if (!Array.isArray(linkedItemsForThisProperty)) linkedItemsForThisProperty = [linkedItemsForThisProperty];
                linkedItemsForThisProperty.forEach(linkedItem => {
                  if(linkedItem) {
                    if(linkedItemProperty.type === FieldType.MultipleEntityLink || linkedItemProperty.type === FieldType.UniqueEntityLink) {
                        let concernedEntity = this.availableEntities.find(x => x.entity_id === linkedItem.element_id);
                        if(concernedEntity) linkedItem = concernedEntity;
                        if(concernedEntity) linkedItem.element_id = linkedItem.entity_id;
                    }
                    let linkedItemLat = this.utils.findPropertyInElement(CONSTANTS.latitude, linkedItem, this.widgetConfig.subProperties);
                    let linkedItemLng = this.utils.findPropertyInElement(CONSTANTS.longitude, linkedItem, this.widgetConfig.subProperties);

                    if (!linkedItem[linkedItemLat] || !linkedItem[linkedItemLng]) {
                     // console.log("no geo",linkedItem, result)

                      let spiralePoint = this.computeSpiralePoint(parseFloat(result[lat]),parseFloat(result[lng]),0.05,noGeoCoordForLinkedItem)
                      this.widgetConfig.subProperties[CONSTANTS.latitude].forEach( latProperty => {
                        linkedItem[latProperty] = spiralePoint.lat;
                      });
                      this.widgetConfig.subProperties[CONSTANTS.longitude].forEach( longProperty => {
                        linkedItem[longProperty] = spiralePoint.long;
                      });
                      linkedItemLat = this.utils.findPropertyInElement(CONSTANTS.latitude, linkedItem, this.widgetConfig.subProperties);
                      linkedItemLng = this.utils.findPropertyInElement(CONSTANTS.longitude, linkedItem, this.widgetConfig.subProperties);
                      noGeoCoordForLinkedItem++;
                    }
                    // console.log("check",linkedItemLat, linkedItem[linkedItemLat] && linkedItem[linkedItemLng], (openedElement == null || linkedItem.element_id == openedElement))
                    if(linkedItem[linkedItemLat] && linkedItem[linkedItemLng] && (openedElement == null || linkedItem.element_id == openedElement)) { // is linked item geoloc ?
                      // add link name to the linkedItem
                      if ("links" in linkedItem) {
                        if (!(linkedItemProperty.code in linkedItem.links)) {
                          linkedItem.links[linkedItemProperty.code] = {'name':linkedItemProperty.overrideTitle[this.widgetLang], "color":linkedItemProperty.linksColor ? linkedItemProperty.linksColor : "#000000",};
                        }
                      } else {
                        linkedItem["links"] = {}
                        linkedItem.links[linkedItemProperty.code] = {'name':linkedItemProperty.overrideTitle[this.widgetLang], "color":linkedItemProperty.linksColor ? linkedItemProperty.linksColor : "#000000",};
                      }

                      // Generating sub objects markers
                      this.markers[linkedItem.element_id] = this.generateMarker(linkedItem, linkedItemLat, linkedItemLng, false, linkedItemProperty.range, linkedItemProperty.linksIcon, linkedItemProperty);
                      if (this.widgetConfig.map.showItemsObjectOnMap || this.widgetConfig.map.showAllLinkedObjectOnMap || forceLinksDisplay) {
                        cluster.push(this.markers[linkedItem.element_id]);
                      }
                     // console.log("forceDisplay",this.widgetConfig.map.showAllLinkedObjectOnMap)
                      if (forceLinksDisplay || (!forceLinksDisplay && this.widgetConfig.map.showAllLinkedObjectOnMap)) {
                        // only display when on opened element (force) or when config force it

                        cluster.push(
                          polyline([
                            [ linkedItem[linkedItemLat], linkedItem[linkedItemLng] ],
                            [ result[lat], result[lng] ]
                          ],
                          {
                            color: linkedItemProperty.linksColor ? linkedItemProperty.linksColor : "#000000",
                            opacity: 0.8,
                            weight: 4
                          }).bindTooltip(linkedItemProperty.overrideTitle[this.widgetLang],{
                            sticky: true // If true, the tooltip will follow the mouse instead of being fixed at the feature center.
                          })
                        );
                      }

                      this.markersAvailable.push([linkedItem[linkedItemLat], linkedItem[linkedItemLng]]);
                    }
                  }
                });

              }
            }
          })
        }
      }
    });

    if(this.widgetConfig.map.hasClusters) this.markerClusterData = cluster;
    else this.markersData = cluster;

    let mapProvider = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
    if (this.widgetConfig.map.provider) {
      mapProvider = this.widgetConfig.map.provider;
      // mapProvider = "https://api.maptiler.com/maps/voyager/{z}/{x}/{y}.png?key=yYIdcvpPGz8nSfkE0WqI"
    }
    if (this.widgetConfig.map.advancedProvider) {
      mapProvider = this.widgetConfig.map.advancedProvider;
    }

    this.options = {
      layers: [
        tileLayer(mapProvider, {
          attribution: '&copy; OpenStreetMap contributors',
        }),
      ],
      attributionControl: false,
    };
    this.isMapReady = true;

    if (this.map && this.markersAvailable) {
      const bounds = new L.LatLngBounds(this.markersAvailable);
      this.map.fitBounds(bounds.pad(0.1));
    }

  }

  private setIcon(entity: DirectoryEntitySummary, isMain: boolean = true, iconLink: string, fieldProperty: DirectoryFieldForSheet): any {

    ///// TREATING SUB OBJECTS FIRST ///// (ie: objects visible via a field/relation)
    if(!isMain) {

      // new way to deals with icons
      if(fieldProperty && fieldProperty['markerChoice']) {

        // Note: field value should already have been passed as an iconlink so we can just use that as path to icon
        if(fieldProperty['markerChoice'] == "fieldBased" && fieldProperty['fieldMarker'] &&
           entity[fieldProperty['fieldMarker']]
          ) {
        //  console.debug("Widget's sub object uses an image field as marker");
        //  console.debug(entity);
        //  console.debug(entity[fieldProperty['fieldMarker']]);
        const crtIconUrl = this.createIconUrlFromImageUrl(entity[fieldProperty['fieldMarker']]);
         return {
          icon: L.icon({
            iconSize: [40, 40],
            iconUrl: crtIconUrl,
          }),
        };
       }
        
        if (fieldProperty['markerChoice'] == "custom") { // Marker is an image that's been uploaded to the widget config
          return {
            icon: L.icon({
              iconSize: [24, 24],
              iconUrl: iconLink,
            }),
          };
        }
        if (fieldProperty['markerChoice'] == "predefined") { // Marker has been selected among the available icons
          return {
            icon: L.divIcon({
              iconSize: [30, 30],
              className:"no-bg-no-border",
              html: this.produceHtmlIcon(fieldProperty['iconMarker'], fieldProperty['iconMarkerColor'], fieldProperty['iconInvertMode'], fieldProperty['iconBorder'], fieldProperty['iconBoxShape'])
            }),
          };
        }

        if (fieldProperty['markerChoice'] == "none") { // 'None' was selected, fallback to default marker
          return {
            icon: L.icon({
              iconSize: [24, 24],
              iconUrl: 'assets/marker-linked-item.png',
            }),
          };
        }

      } else { // markerchoice field is not set but there is an iconlink, use it
        if(iconLink) {
          return {
            icon: L.icon({
              iconSize: [24, 24],
              iconUrl: iconLink,
            }),
          };
        }
        else { // No info at all, fallback to default marker
          return {
            icon: L.icon({
              iconSize: [24, 24],
              iconUrl: 'assets/marker-linked-item.png',
            }),
          };
        }
      }
    }

    ///// BACK TO MAIN OBJECT /////

    // LEGEND //
    if (entity.legend) { // If there's a legend, it supercedes any other setting
      if (entity.legend.colors.length > 1 || (!entity.legend.icon && !entity.legend.markerChoice) || (entity.legend.markerChoice && entity.legend.markerChoice == "none") ) {
        return {
          icon: L.icon({
            iconSize: [24, 24],
            iconUrl: this.imageGen.getIcon(entity.legend.colors)
          }),
        };
      }
      if (entity.legend.icon && !entity.legend.markerChoice) {
        return {
          icon: L.icon({
            iconSize: [30, 30],
            iconUrl: entity.legend.icon
          }),
        };
      }
      // new way with markerChoice
      if (entity.legend.icon && entity.legend.markerChoice=="custom") {
        return {
          icon: L.icon({
            iconSize: [30, 30],
            iconUrl: entity.legend.icon
          }),
        };
      }
      if (entity.legend.markerChoice == "predefined") {
        return {
          icon: L.divIcon({
            iconSize: [30, 30],
            className:"no-bg-no-border",
            html: this.produceHtmlIcon(entity.legend.iconMarker, entity.legend.iconMarkerColor, entity.legend.iconInvertMode, entity.legend.iconBorder, entity.legend.iconBoxShape)
          }),
        };
      }
    }

    // NO LEGEND //
    
    if(this.widgetConfig.map.markerChoice && this.widgetConfig.map.markerChoice == 'fieldBased' &&
       this.widgetConfig.map.fieldMarker && this.widgetConfig.map.fieldMarker.length > 0 &&
       entity[this.widgetConfig.map.fieldMarker]) {
      // console.debug("Widget's main object uses an image field as marker");
      // console.debug(entity)
      // console.debug(entity[this.widgetConfig.map.fieldMarker]);
      const crtIconUrl = this.createIconUrlFromImageUrl(entity[this.widgetConfig.map.fieldMarker]);
      return {
        icon: L.icon({
          iconSize: [40, 40],
          iconUrl: crtIconUrl,
        }),
      };
    }

    if(this.widgetConfig.map.markerChoice && this.widgetConfig.map.markerChoice == 'predefined') { // marker is selected from the preset list
      return {
        icon: L.divIcon({
          iconSize: [30, 30],
          className:"no-bg-no-border",
          html: this.produceHtmlIcon(this.widgetConfig.map.iconMarker, this.widgetConfig.map.iconMarkerColor, this.widgetConfig.map.iconInvertMode, this.widgetConfig.map.iconBorder, this.widgetConfig.map.iconBoxShape)
        }),
      };
    }

    if(this.widgetConfig.map.defaultMarker) { // this value being set means a custom marker has been set & uploaded
        return {
          icon: L.icon({
            iconSize: [30, 30],
            iconUrl: this.widgetConfig.map.defaultMarker
          }),
        };
    }    
    
    return { // Either 'none' was selected or there's no settings at all, fallback to default marker
      icon: L.icon({
        iconSize: [25, 41],
        iconUrl: 'assets/marker-icon.png',
        iconRetinaUrl: 'assets/marker-icon-2x.png',
      }),
    };
  }

  /**
   * 
   * @param fullSizeImageUrl the stored image URL
   * @returns An alternate URL that is sure to give the right size image
   */
  private createIconUrlFromImageUrl(fullSizeImageUrl: string): string {
    const prefixToRemove = environment.publicUrl + "/shared";
    const replacementPrefix = environment.baseUrl + "/assets"
    
    // console.debug("Creating Icon URL");
    // console.debug(prefixToRemove);
    // console.debug(replacementPrefix);
    // console.debug(fullSizeImageUrl.replace(prefixToRemove, replacementPrefix));

    return fullSizeImageUrl.replace(prefixToRemove, replacementPrefix);
  }

  produceHtmlIcon (iconMarker: string, iconMarkerColor: string, iconInvertMode: boolean, iconBorder: boolean,  iconBoxShape: boolean) : string {
    let wrapperStyle = "";
    if (iconInvertMode) {
      wrapperStyle += 'style="background-color:'+iconMarkerColor;
      if (iconBorder) {
        wrapperStyle += ';border-color:#fff';
      } else {
        wrapperStyle += ';border-color:'+iconMarkerColor+'"';
      }
      wrapperStyle += '"';
    } else if (iconBorder){
      wrapperStyle += 'style="border-color:'+iconMarkerColor+'"';
    }
    let wrapper = '<div class="'+(iconBoxShape ? 'icon-box' : 'icon-circle')+'" '+wrapperStyle+'>';

    return wrapper+'<div class="'+iconMarker+'"><div class="icon-background" style="background-color:'+(iconInvertMode? '#fff' : iconMarkerColor)+'"></div></div></div>'
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  private async openPreview(id: DirectoryEntitySummary['entity_id']): Promise<void> {
    let popupPosition: any;
    let popupClasses: string;

    // If the element is on the map (GPS available)
    if(this.markers[id]) {
      // If the marker is on the map, otherwise it means its in a cluster
      if (this.markers[id]._map) {
        // console.log("openPreview",id,this.markers[id])
        popupPosition = this.markers[id]?._latlng;
        popupClasses = 'customLeafletPopup';
      } else {
        popupPosition = this.markers[id]?.__parent?._cLatLng;
        popupClasses = 'customLeafletPopup offsetForCluster';
      }

      if (this.map) {
        this.previewComponent.changeDetectorRef.detectChanges();
        this.map.closePopup();
        await this.sleep(50);
        this.previewComponent.changeDetectorRef.detectChanges();
        await this.sleep(50);
        this.map.openPopup(
          (layer) => {
            return this.previewComponent.location.nativeElement;
          },
          popupPosition,
          {
            maxWidth: 500,
            minWidth: 50,
            maxHeight: 160,
            autoPan: false,
            closeButton: false,
            className: popupClasses,
          }
        );
      }
    }
  }

  private identifyLinkedItemsProperties(widgetConfig: DirectoryConfig) {
    if(widgetConfig && widgetConfig.map.hasLinkedObjectOnMap) {
      let fieldInSheetsFromConfig: any = (widgetConfig.sheet.fieldsgroup as DirectoryFieldsGroupForSheet[]).map(x => x.fields); // keep only fields in fieldsgroups
      fieldInSheetsFromConfig = Array.prototype.concat.apply([], fieldInSheetsFromConfig); // merge all fields together
      let linkedItemProperties = [];
      (fieldInSheetsFromConfig as DirectoryFieldForSheet[]).forEach((field: DirectoryFieldForSheet) => {
        if(field.canBeDisplayedOnMap) linkedItemProperties.push(field);
      })
      return linkedItemProperties;
    }
    return [];
  }

  private generateMarker(resultSummary : DirectoryEntitySummary, lat, lng, isMain: boolean, range?: string, iconLink?: string, fieldProperty?: DirectoryFieldForSheet) {
    // console.log("generateMarker",resultSummary,lat, lng)
    return marker(
      [resultSummary[lat], resultSummary[lng]],
      this.setIcon(resultSummary, isMain, iconLink, fieldProperty)
    )
      .on('mouseover', (event) => {
        this.zone.run(() => {
          // console.log("runMouseoverPreview")
          this.resultsStore.setFocusedElement({
            id: this.utils.getElementId(resultSummary),
            range: range,
            isMain: isMain
          });
          this.resultsStore.setFocusedElementData(
            resultSummary
          );
        });
      })
      .on('mouseout', (event) => {
        this.zone.run(() => {
          this.resultsStore.setFocusedElement(null);
          this.resultsStore.setFocusedElementData(
            null
          );
        });
      })
      .on('click', (event) => {
        // console.debug('DirectoryMap::generateMarker::onClick');
        // console.debug(range);
        // console.debug(resultSummary);
      this.zone.run(() => {
        this.resultsStore.setFocusedElement({
          id: this.utils.getElementId(resultSummary),
          range: range,
          isMain: isMain
        });
        this.resultsStore.setFocusedElementData(
          resultSummary
        );
        this.resultsStore.setOpenedElement({
          id: this.utils.getElementId(resultSummary),
          range: range,
          isMain: isMain
        });
        this.resultsStore.setOpenedElementData(
          resultSummary
        );
      });


      });
  }

  private closePreview(): void {
    if (this.map && !this.currentEntityId && !this.currentOpenedEntityId) {
      this.map.closePopup();
    }
  }

  public openList(): void {
    this.directoryData.changeListState(true);
    this.resizeMap()
  }

  public openLegend(): void {
    this.directoryData.changeLegendState(true);
    this.resizeMap()
  }

  private zoomToMarker(id): void {
    if (this.map) {
      this.map.flyTo(this.markers[id]._latlng, 15, { duration: 0.6 });
    }
  }

  private unzoom(id): void {
    if (this.map) {
      this.map.flyTo(this.markers[id]._latlng, 6, { duration: 0.6 });
    }
  }

  public openDataSourceDialog(): void {
    let dialogRef = this.dialog.open(DirectoryDataSourceComponent, {
      minWidth: '300px',
      minHeight: '200px'
    });
  }

  public isListOnTheLeft(): boolean {
    if (this.widgetConfig?.layout.isListOnLeft) {
      return true;
    }
    return false;
  }

  public isLegendOnTheLeft(): boolean {
    if (this.widgetConfig?.layout.isLegendOnLeft) {
      return true;
    }
    return false;
  }

  public canListBeDisplayed(): boolean {
    if (this.widgetConfig?.layout.core.hasList && !this.hasListDisplayed) {
      return true;
    }
    return false;
  }

  public canLegendBeDisplayed(): boolean {
    if (
      this.widgetConfig.layout.hasLegend &&
      !this.hasLegendDisplayed &&
      !this.widgetConfig.layout.core.hasList
    ) {
      return true;
    }
    return false;
  }

  public openCreationRequestDialog() {
    let contactEmail;
    if(this.widgetConfig.addRequests && this.widgetConfig.addRequests.emailForCreationRequests) {
      contactEmail = this.widgetConfig.addRequests.emailForCreationRequests
    }
    else {
      contactEmail = this.widgetConfig.responsibleEmail;
    }
    if(contactEmail) {
      const dialogRef = this.dialog.open(DirectoryEntityCreationDialogComponent, {
        width: '600px',
        maxWidth: '100vw',
        maxHeight: '90vh',
        data: {
          contact_email: contactEmail
        },
        autoFocus: false,
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe((success: string) => {});
    }
  }

  findParentsToLinkedElement(openedElement: OpenedElement): any[] {
    let parentsResults = [];

    let linkedItemProperties = this.identifyLinkedItemsProperties(this.widgetConfig);

    linkedItemProperties.forEach(linkedItemProperty => {
      this.resultsList.forEach(result => {
        if(result[linkedItemProperty.code]) {
          if(linkedItemProperty.type === "MultipleEntityLink") {
            if(!Array.isArray(result[linkedItemProperty.code])) result[linkedItemProperty.code] = [result[linkedItemProperty.code]];
            if(result[linkedItemProperty.code].map(x => x.element_id).includes(openedElement.id)) {
              parentsResults.push(result);
            }
          }
          else if(linkedItemProperty.type === "UniqueEntityLink") {
            if(result[linkedItemProperty.code] === openedElement.id) parentsResults.push(result);
          }
          else if(isList(linkedItemProperty.type)) {
            if(!Array.isArray(result[linkedItemProperty.code])) result[linkedItemProperty.code] = [result[linkedItemProperty.code]];
            if(result[linkedItemProperty.code].map(x => x.element_id).includes(openedElement.id)) {
              parentsResults.push(result);
            }
          }
          else {
            // console.log("ELSED")
          }
        }
      })
    });

    return parentsResults;

  }

  computeSpiralePoint(x,y,base,step) {

    let globalStep = Math.trunc(step/8) + 1;
    let distance = base * globalStep;
    let place = step % 8;

    switch(place) {
      case 0:
        return {'lat':x,'long':y + distance};
      case 1:
        return {'lat':x + Math.sqrt(distance*distance/2)*Math.cos(Math.trunc(x)),'long':y + Math.sqrt(distance*distance/2)};
      case 2:
        return {'lat':x + distance*Math.cos(Math.trunc(x)),'long':y};
      case 3:
        return {'lat':x + Math.sqrt(distance*distance/2)*Math.cos(Math.trunc(x)),'long':y - Math.sqrt(distance*distance/2)};
      case 4:
        return {'lat':x,'long':y - distance};
      case 5:
        return {'lat':x - Math.sqrt(distance*distance/2)*Math.cos(Math.trunc(x)),'long':y - Math.sqrt(distance*distance/2)};
      case 6:
        return {'lat':x - distance*Math.cos(Math.trunc(x)),'long':y};
      case 7:
        return {'lat':x - Math.sqrt(distance*distance/2)*Math.cos(Math.trunc(x)),'long':y + Math.sqrt(distance*distance/2)};
    }
  }

  resizeMap() {
    this.sleep(400).then(a => {  // 400 ms parce que la durée d'animation est de 340 ms
      if (this.map) {
        this.map.invalidateSize(true) // true : avec des animations
      }
    })
  }
}
