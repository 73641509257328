<div class="directory-entity-contact-wrapper">
  <div class="dialog-header">
      <h1>{{ 'directory.requests.validation.title' | translate }}</h1>
      <div class="flex-spacer"></div>
      <button mat-icon-button 
          (click)="cancelDialog()">
        <mat-icon>close</mat-icon>
      </button>
  </div>
  <form [formGroup]="dialogForm">
    <div class="sender-informations">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'directory.contact.message' | translate }}*</mat-label>
        <textarea matInput 
          placeholder="" 
          formControlName="message"></textarea>
      </mat-form-field>

      <h2>{{ 'directory.contact.your_infos' | translate }}</h2>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'directory.contact.your_name' | translate }}*</mat-label>
        <input matInput 
          placeholder="{{ 'directory.contact.your_name_placeholder' | translate }}" 
          formControlName="name">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'directory.contact.your_email' | translate }}*</mat-label>
        <input matInput 
          placeholder="adress@email.com" 
          formControlName="email">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'directory.contact.phone' | translate }}</mat-label>
        <input matInput 
          placeholder="" 
          formControlName="phone">
      </mat-form-field>
    </div>

    <mat-dialog-actions class="justify-content-end">
      <button mat-button 
          (click)="cancelDialog()">
        {{ 'directory.contact.cancel' | translate }}
      </button>
      <button mat-raised-button 
          color="primary" 
          [disabled]="dialogForm.invalid"
          (click)="returnRequester()">
        {{ 'directory.requests.validation.valid2' | translate }}
      </button>
    </mat-dialog-actions>
  </form>
</div>
