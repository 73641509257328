import { CONSTANTS } from 'src/environments/constants';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { DirectorySearchService } from '../../services/directory-search/directory-search.service';


@Component({
  selector: 'app-entity-card',
  templateUrl: './entity-card.component.html',
  styleUrls: ['./entity-card.component.scss']
})
export class EntityCardComponent implements OnChanges {
  public CONSTANTS = CONSTANTS;

  @Input() title: string;
  @Input() subtitleSubject?: string;
  @Input() subtitle?: string;
  @Input() caption?: string;

  @Input() entityId: string;
  @Input() entityData?: any;

  @Input() isSelected?: boolean = false;
  @Input() deletable?: boolean = false;
  @Input() selectable?: boolean = true;

  @Output() delete = new EventEmitter<boolean>();
  @Output() select = new EventEmitter<boolean>();


  constructor(private searchService: DirectorySearchService) { }

  ngOnChanges() {
    if (this.entityId && !this.entityId.includes("_:")) {
      this.searchService.findEntityData(this.entityId, true).subscribe((res: any) => {
        this.title = res[CONSTANTS.mainName];
      });
    } else if (this.entityData && this.entityData.entity_id && this.entityData.entity_id.includes("_:")) {
      this.title = this.entityData[CONSTANTS.mainName];
    }
  }

  public toggleSelection() {
    if (this.selectable) this.select.emit(!this.isSelected);
  }

  public triggerDelete() {
    this.delete.emit(true);
  }

}
