import { DirectoryConfig } from './../../../models/directory-config.model';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { concatMap } from 'rxjs/operators';
import { CONSTANTS } from 'src/environments/constants';
import { DirectorySearchService } from './../../../services/directory-search/directory-search.service';
import { DirectorySharedDataService } from './../../../services/directory-shared-data.service';
import { DirectoryUtilsService } from 'src/app/directory/services/directory-utils/directory-utils.service';

@Component({
  selector: 'app-entities-links',
  templateUrl: './entities-links.component.html',
  styleUrls: ['./entities-links.component.scss']
})
export class EntitiesLinksComponent implements OnChanges {
  @Input() fieldValue: string;
  @Input() trueLink: boolean = false;
  public widgetConfig: DirectoryConfig;
  public hasMultipleLinks: boolean = false;

  public visibleEntities: Object[] = [];

  constructor(
    private directorySearch: DirectorySearchService,
    private directoryStore: DirectorySharedDataService,
    private utils: DirectoryUtilsService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.fieldValue && changes['fieldValue']) {
      this.updateVisibleFields();
    }
  }

  private updateVisibleFields() {
    // console.debug(this.fieldValue);

    this.directoryStore.getDirectoryConfig().pipe(
      concatMap((config: DirectoryConfig) => {
        this.widgetConfig = config;
        let idsArray: string[] = [];
        
        if (Array.isArray(this.fieldValue)) {
          this.hasMultipleLinks = true;
          this.fieldValue.forEach(element => {
            idsArray.push(this.utils.getElementId(element));
          });
        } else {
          this.hasMultipleLinks = false;
          idsArray.push(this.fieldValue);
        }

        return this.directorySearch.findEntitiesByIds(idsArray, [CONSTANTS.mainName]);
      }),
    ).subscribe((searchResponse) => {
      let visibleEntities = [];
      searchResponse.entities.forEach(crtEntity => {
        if (crtEntity[CONSTANTS.mainName]) {
          let entityLabel = {};
          entityLabel['id'] = crtEntity.entity_id;
          entityLabel['label'] = crtEntity[CONSTANTS.mainName];
          visibleEntities.push(entityLabel);
        }
      });
      this.visibleEntities = visibleEntities;
      // console.debug("Updated list of visible entities:");
      // console.debug(this.visibleEntities);
    });
  }

}
