<button mat-raised-button (click)="openList()" *ngIf="canListBeDisplayed()" class="back-to-list result-button" [class.list-on-the-left]="isListOnTheLeft()">
  <mat-icon class="chevron_left" *ngIf="!isListOnTheLeft()">chevron_left</mat-icon>
  <span>{{'directory.results.back_to'|translate}} {{nbResults}}
    <ng-container *ngIf="widgetConfig.subject && widgetConfig.subject[widgetLang]; else basicresults">{{widgetConfig.subject[widgetLang]}}</ng-container>
    <ng-template #basicresults>{{ 'directory.results.nb' | translate }}</ng-template>
  </span>
  <mat-icon class="chevron_right" *ngIf="isListOnTheLeft()">chevron_right</mat-icon>
</button>
<button mat-raised-button (click)="openLegend()" *ngIf="canLegendBeDisplayed()" class="back-to-list" [class.list-on-the-left]="isLegendOnTheLeft()">{{'directory.results.back_to_legend'|translate}}</button>
<div *ngIf="isMapReady"
  class="directory-map"
  [class.list-on-the-left]="isListOnTheLeft()"
  [class.focus-on-element]="currentOpenedEntityId ? true : false"
  leaflet
  [leafletOptions]="options"
  (leafletMapReady)="onMapReady($event)"
  [leafletMarkerCluster]="markerClusterData"
  [leafletMarkerClusterOptions]="markerClusterOptions"
  (leafletMarkerClusterReady)="markerClusterReady($event)"
  [leafletLayers]="markersData"
  >
</div>

<div class="partners-logos" [class.list-on-the-left]="isListOnTheLeft()">
  <img src="../../../../assets/medias/img/craft-logo.png" alt="Logo Craft">
  <img [src]="widgetConfig.logo" alt="Logo Partenaire" *ngIf="widgetConfig.logo">
  <img src="../../../../assets/medias/img/bdi-logo.jpg" alt="Logo BDI" *ngIf="!widgetConfig.logo">
  <div class="osm-copyright" >© <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/" target="_blank">CC-BY-SA</a></div>
</div>

<div class="floating-buttons" [class.list-on-the-left]="isListOnTheLeft()">
  <button *ngIf="widgetConfig?.addRequests?.hasAddRequestsPerMessage"
    mat-raised-button
    appearance="with-icon"
    (click)="openCreationRequestDialog()"
  >
    <mat-icon>add_circle</mat-icon>
    {{ 'directory.requests.add_request' | translate }}
  </button>
  <a class class="mx-2" *ngIf="widgetConfig?.addRequests?.hasAddRequestsPerExternalLink"
    mat-raised-button appearance="with-icon" [href]="widgetConfig?.addRequests?.creationFormLink" target="_blank">
    <mat-icon>add_circle</mat-icon>
    {{ 'directory.requests.add_request' | translate }}
  </a>
  <a *ngIf="widgetConfig?.addRequests?.hasAddRequestsPerEmbeddedForm"
    mat-raised-button
    appearance="with-icon"
    [routerLink]="'/'+widgetLang+'/annuaire/'+widgetId+'/demande-ajout'"
  >
    <mat-icon>add_circle</mat-icon>
    {{ 'directory.results.add_request' | translate }}
  </a>
  <button mat-raised-button appearance="with-icon" (click)="openDataSourceDialog()">
    <mat-icon>info_outlined</mat-icon>
    {{ 'directory.source' | translate }}
  </button>
</div>


