<form [formGroup]="formGroup">
  <mat-form-field appearance="outline">
    <input
      matInput
      [formControlName]="field.code"
      [required]="field.required"
      [matDatepicker]="picker"
      (click)="picker.open()"
    >
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
</form>
